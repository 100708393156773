@import "/src/variables";
@import "/src/index.scss";

.package-purchase-main{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    height: 90vh;
    overflow-y: scroll;

    p {
        margin: 0;
    }
    hr {
        margin: 0;
    }
    .gray-bold {
        color: $--Dark-Gray;
        font-weight: 600;
        font-size: 16px;
        text-transform: capitalize;
    }
    .bold {
        color: $--Gray-2;
        font-size: 18px;
        font-weight: 600;
    }
    .black-bold {
        color: #373a3f;
        font-size: 16px;
        font-weight: 600;
    }
    .desc {
        color: $--Gray-2;
        font-size: 16px;
        font-weight: 500;
    }
    .info {
        color: var(--neutral-opacity-50, rgba(19, 26, 37, 0.5));
        font-size: 14px;
        font-weight: 400;
        .code {
            color: $--primary-color;
            font-weight: 500;
        }
    }
    .primary-btn-blue {
        width: calc(100% - 40px);
        border-radius: 8px;
        margin: 0 20px 20px 20px;
    }
}