@import "/src/variables";
@import "/src/index.scss";

.package-requirements-main{
    padding: 30px;
    background-color: #f8f8f8;
    min-height: 100vh;

    p{
        margin: 0;
    }
    .details-container{
        background-color: white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        min-height: calc(100vh - 30px);

        .thanks{
            color: #219653;
            display: flex;
            gap: 6px;
            padding: 16px;
            border: 1px solid #219653;
            border-radius: 8px;
            background: rgba(33, 150, 83, 0.08);
            
            .check-mark{
                display: grid;
                place-content: center;
                height: 24px;
                width: 24px;
                background-color: #219653;
                border-radius: 50%;
                svg{
                    height: 14px;
                    width: 14px;
                }
            }
        }
        h2{
            margin: 8px 0;
            color: #24262E;
            font-size: 24px;
            font-weight: 600;
        }
        .input-grp {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .bold {
                color: $--Gray-1;
                font-size: 14px;
                font-weight: 600;
            }
            textarea {
                border-radius: 8px;
                border: 1px solid
                    var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                background: white;
                padding: 12px;
                width: 100%;
                height: 120px;
                font-size: 14px;
                font-weight: 500;
            }
            textarea:focus {
                outline: none;
            }
            textarea:disabled{
                background-color: rgba(19, 26, 37, 0.05);
                cursor: not-allowed;
            }
        }
        .attachment{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            padding: 12px;
            border-radius: 4px;
            border: 1px dashed var(--Basic-600, #8F9BB3);
            width: 100%;
            input{
                display: none;
            }
            img{
                height: 24px;
                width: 24px;
            }
            span{
                display: flex;
                gap: 8px;
                align-items: center;
            }
            .delete-icon{
                height: 16px;
                width: 16px;
            }
        }
        .file-upload{
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 2px;
            width: min(400px, 100%);             
            .instructions{
                display: flex;
                justify-content: space-between;
                color: $--Gray-4;
                font-size: 10px;
                font-weight: 500;
            }
        }
        .files-container{
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: min(400px, 100%);

            .my-file{
                display: flex;
                align-items: center;
                gap: 12px;
                .plus{
                    border: 1px solid #D2D3D5;
                    padding: 8px;
                    cursor: pointer;
    
                    svg{
                        height: 28px;
                        width: 28px;
                        transform: rotate(45deg);
                        path{
                            fill: #1C1B1F;
                        }
                    }
                }
            }
        }
        .check{
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;

            .check-box{
                display: grid;
                place-content: center;
                border: 1px solid rgba(33, 70, 152, 0.50);
                border-radius: 4px;
                min-height: 20px;
                min-width: 20px;
            }
            .checked{
                background-color: $--primary-color;
            }
        }
        .buttons{
            align-self: flex-end;
            margin-top: auto;
            button{
                font-size: 18px;
                font-weight: 600;
                padding: 12px 40px;
                width: unset;
                border-radius: 8px;
            }
            .primary-btn-white{
                color: $--Gray-1;
            }
        }
    }
}

@media (max-width: 600px){
    .package-requirements-main{
        .details-container{
            padding: 12px;
            .buttons{
                button{
                    padding: 10px 20px;
                    font-size: 14px;
                }
            }
        }
    }
}
@media (max-width: 400px){
    .package-requirements-main{
        padding: 0;
        height: 100vh;
    }
}
