@import "/src/variables";
@import "/src/index.scss";

.origin-edit-main {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 14px;
    padding: 20px;
    width: min(500px, 95vw);

    p {
        margin: 0;
    }
    .container {
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
        padding: 0;
        .text {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .title {
                color: #000;
                font-size: 24px;
                font-weight: 600;
            }
            svg {
                min-height: 30px;
                min-width: 30px;
                path {
                    fill: #1c1b1f;
                }
            }
        }
        .input-grp {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .bold {
                color: $--Gray-1;
                font-size: 14px;
                font-weight: 600;
            }
            input,select {
                border-radius: 8px;
                border: 1px solid
                    var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                background: white;
                padding: 12px;
                width: 100%;
                height: 44px;
                font-size: 14px;
                font-weight: 500;
            }
            input:focus, select:focus {
                outline: none;
            }
        }
    }
    .btn-blue {
        border-radius: 60px;
        background: $--primary-color;
        padding: 8px 20px;
        outline: none;
        border: none;
        cursor: pointer;
        color: white;
    }
}
