@import "/src/variables";
@import "/src/index.scss";

.nav-offcanvas-main{
    height: calc(100vh - 30px);
    max-width: fit-content;
    margin-top: 30px;
    border-radius: 0px 16px 16px 0px;

    .offcanvas-header{
        justify-content: flex-end;
    }
    .offcanvas-body{
        .items {
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin-right: 80px;
            padding: 0 20px;

            .item {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 1px;
                cursor: pointer;
                p {
                    color: $--primary-color;
                    font-size: 20px;
                    font-weight: 500;
                    white-space: nowrap;
                    margin: 0;
                }
                .under-line {
                    display: inline-block;
                    height: 2px;
                    width: 50%;
                    background: $--primary-color;
                    border-radius: 2px;
                }
                .under-line::after {
                    position: absolute;
                    content: "";
                    height: 2px;
                    width: 2px;
                    background-color: black;
                    left: calc(50% + 2px);
                    border-radius: 50%;
                }
            }
            button{
                width: 100%;
                border-radius: 8px;
            }
        }
    }   
}