@import "/src/variables";
@import "/src/index.scss";

.bid-form-main{
    display: flex;
    flex-direction: column;
    gap: 18px;

    .bold {
        color: $--Gray-1;
        font-size: 14px;
        font-weight: 700;
    }
    .input-grp {
        width: 100%;
        display: flex;
        // justify-content: space-between;
        gap: 20px;

        .text {
            width: 220px;
            .desc {
                color: $--Gray-2;
                font-size: 14px;
                font-weight: 400;
            }
        }
        .input-container {
            display: flex;
            align-items: center;
            gap: 12px;
            max-width: 450px;
            select, input, textarea {
                border-radius: 8px;
                border: 1px solid
                    var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                background: white;
                padding: 12px;
                width: 100%;
                height: 44px;
                font-size: 14px;
                font-weight: 500;
            }
            textarea{
                height: 150px;
            }
            select:focus, input:focus, textarea:focus {
                outline: none;
            }
            select:disabled, input:disabled{
                background-color: rgba(19, 26, 37, 0.05);
                cursor: not-allowed;
            }
        }
    }
    .input-grp-2{
        flex-direction: column;
        gap: 4px;
    }
    hr{
        opacity: 0.05;
        margin: 0;
    }
    .button-container {
        display: flex;
        flex-direction: column;
        .btn-blue {
            width: fit-content;
            align-self: flex-end;
        }
    }
}

@media (max-width: 800px) {
    .bid-form-main {
        margin-bottom: 62px;
        .button-container {
            position: fixed;
            bottom: 0;
            left: 0;
            background-color: white;
            width: 100%;
            padding: 12px;
            border-top: 1px solid var(--Gray-5, #e0e0e0);
        }
    }
}

@media (max-width: 600px) {
    .bid-form-main {
        margin-bottom: 62px;
        .input-grp{
            flex-direction: column;
            gap: 4px;
            .text{
                width: 100%;
            }
            .input-container{
                width: 100%;
            }
        }
    }
}