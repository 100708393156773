@import "/src/variables";
@import "/src/index.scss";

.job-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 70px;

    .grp {
        display: flex;
        flex-direction: column;
        gap: 8px;

        h1 {
            margin: 0;
            color: #000;
            font-size: 20px;
            font-weight: 600;
        }
        .budget {
            color: $--Gray-1;
            font-size: 14px;
            font-weight: 600;
            display: none;
            span {
                color: $--Gray-4;
                font-size: 12px;
                font-weight: 400;
            }
        }
        .desc {
            color: $--Gray-3;
            font-size: 14px;
            font-weight: 400;
        }
        .title {
            color: $--Gray-1;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
        }
        .skills-container {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            .skill {
                display: flex;
                align-items: center;
                gap: 2px;
                border: 1px solid #5d01db;
                border-radius: 4px;
                background: rgba(239, 230, 251, 0.4);
                padding: 4px 6px 4px 4px;
                color: black;
                font-size: 12px;
                font-weight: 500;
            }
        }
        .attachment {
            border: 1px solid var(--Gray-5, #e0e0e0);
            border-radius: 4px;
            padding: 10px;
            display: flex;
            align-items: center;
            gap: 8px;
            width: fit-content;
            cursor: pointer;

            img {
                height: 20px;
            }
            p {
                color: var(--L1-ref-secondary-secondary20, #47484b);
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
    .button-container {
        display: flex;
        flex-direction: column;
        .btn-blue {
            width: fit-content;
            align-self: flex-end;
        }
    }
}
@media (max-width: 800px) {
    .job-details {
        position: relative;
        .grp {
            .budget {
                display: block;
            }
        }
        .button-container {
            position: fixed;
            bottom: 0;
            left: 0;
            background-color: white;
            width: 100%;
            padding: 12px;
            border-top: 1px solid var(--Gray-5, #e0e0e0);
        }
    }
}

@media (max-width: 400px) {
    .job-details {
        .grp {
            h1 {
                font-size: 16px;
            }
            .budget {
                font-size: 14px;
            }
            .desc {
                font-size: 12px;
            }
            .title {
                font-size: 14px;
            }
            .skills-container {
                .skill {
                    font-size: 12px;
                }
            }
            .attachment {
                padding: 6px;
                p {
                    font-size: 10px;
                }
            }
        }
    }
}
