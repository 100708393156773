@import "/src/variables";
@import "/src/index.scss";

.home-footer-main {
    display: flex;
    justify-content: space-around;
    gap: 50px;
    background: #2d2d2d;
    color: #adb7c1;
    padding: 70px 200px;
    p {
        margin: 0;
    }
    .bold {
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
    }

    .part-container {
        display: flex;
        gap: 20px;

        .footer-part {
            .bold {
                margin-bottom: 16px;
            }
            .items {
                p {
                    white-space: nowrap;
                    color: var(--Gray-5, #e0e0e0);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 150%;
                    margin: 6px 0;
                    cursor: default;
                }
            }
        }
    }
    .footer-part-1 {
        display: flex;
        flex-direction: column;
        gap: 30px;

        hr {
            margin: 0;
        }
        .rysovate {
            p {
                color: #adb7c1;
                font-size: 12px;
                font-weight: 400;
                margin-top: 20px;
                width: 250px;
            }
        }
        .socials {
            .social-medias {
                margin-top: 8px;
                display: flex;
                gap: 12px;

                svg {
                    cursor: pointer;
                }
            }
        }
    }
    .footer-part-3{
        .items{
            p{
                cursor: pointer !important;
            }
        }
    }
    .footer-part-4 {
        .input-container {
            margin-top: 10px;
            width: 100%;
            min-width: 260px;
            height: 46px;
            border-radius: 8px;
            background: #d9d9d91a;
            display: flex;
            padding: 4px;

            input {
                width: 100%;
                background: #d9d9d900;
                outline: none;
                border: none;
                padding-left: 12px;
                color: white;
            }

            button {
                padding: 10px 10px;
                border-radius: 4px;
                background: var(--Red-Colour, #fb5858);
                border: none;
                outline: none;
                font-family: "Inter", sans-serif;
                color: white;
                font-size: 12px;
                font-weight: 400;
            }
        }
        hr,
        .socials {
            display: none;
        }
    }
}

@media (max-width: 1000px) {
    .home-footer-main {
        flex-wrap: wrap;
        padding: 50px 50px;
        justify-content: flex-start;
    }
}
@media (max-width: 400px) {
    .home-footer-main {
        flex-wrap: wrap;
        padding: 30px 20px;
        justify-content: flex-start;
        gap: 30px;

        .part-container {
            width: 100%;
            flex-direction: column;
            align-items: center;
            text-align: center;

            hr {
                width: 50%;
            }
        }

        .footer-part-1 {
            align-items: center;
            width: 100%;

            .rysovate {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
            hr,
            .socials {
                display: none;
            }
        }
        .footer-part-4 {
            width: 100%;
            hr,
            .socials {
                display: block;
                .social-medias {
                    margin-top: 8px;
                    display: flex;
                    gap: 12px;

                    svg {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
