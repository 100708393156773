@import "/src/variables";
@import "/src/index.scss";

.payout-info-main{
    padding-top: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    p{
        margin: 0;
    }
    .details{
        padding: 20px;
        border-radius: 8px;
        border: 1px solid $--Gray-5;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .text{
            .bold{
                color: $--Dark-blue-2;
                font-size: 20px;
                font-weight: 600;
                display: flex;
                justify-content: space-between;

                span{
                    color: $--primary-color;
                    font-size: 16px;
                    font-weight: 500;
                    cursor: pointer;
                }
            }
            .desc{
                color: $--Gray-2;
                font-size: 16px;
                font-weight: 400;
            }
            .billing-info{
                margin-top: 20px;
                border-radius: 8px;
                border: 1px solid $--Gray-4;
                max-width: 600px;

                p{
                    color: #131A25;
                    font-size: 16px;
                    font-weight: 500;
                }
                .billing-head{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 12px;
                    border-bottom: 1px solid $--Gray-4;
                    .icons{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                .billing-body{
                    padding: 12px;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    p{
                        font-size: 12px;
                    }

                    .data-grp{
                        display: flex;
                        gap: 12px;
                        .billing-data-container{
                            display: flex;
                            flex-direction: column;
                            gap: 6px;
                            width: 100%;

                            div{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                border: 1px solid var(--neutral-opacity-20, rgba(19, 26, 37, 0.20));
                                border-radius: 8px;
                                height: 48px;
                                padding: 0 12px;
                                p{
                                    color: #B2B5B8;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
            .bank-info{
                background: #F8F8F8;
                padding: 16px;
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin-top: 8px;
                p{
                    color: $--Gray-2;
                    font-size: 16px;
                    font-weight: 600;
                    span{
                        color: $--Gray-3;
                        font-weight: 500;
                    }
                }
            }
        }
        .btn-white-outline{
            color: $--primary-color;
            width: fit-content;
        }
    }
}
@media (max-width: 600px){
    .billing-info-main{
        .details{
            padding: 10px;
            .text{
                .bold{
                    font-size: 16px;
                }
                .desc{
                    font-size: 12px;
                }
            }
            .btn-white-outline{
                font-size: 12px;
            }
        }
    }
}
@media (max-width: 400px){
    .billing-info-main{
        padding-top: 10px;
    }
}