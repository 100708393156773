@import "/src/variables";
@import "/src/index.scss";

.privacy-policy-main {
    padding: 20px;
    padding-top: 105px;
    display: flex;
    flex-direction: column;
    background: #f8f8f8;
    min-height: 100vh;

    p {
        margin: 0;
    }
    h2 {
        font-size: 28px;
        font-weight: 500;
    }
    .details-container {
        display: flex;
        border-radius: 8px;
        border: 1px solid var(--Basic-400, #e4e9f2);
        background: #fff;
        margin: 8px 20px;

        .side-bar {
            padding-right: 50px;
            padding-left: 0;
            text-wrap: nowrap;
            border-right: 1px solid $--Gray-5;
            display: flex;
            flex-direction: column;
            span {
                cursor: pointer;
                padding: 10px;
                border-left: 4px solid $--Gray-5;
                a {
                    color: #83848a;
                    font-size: 16px;
                    font-weight: 400;
                    text-decoration: none;
                    // text-wrap: balance;

                }
            }
            span.active {
                border-color: $--primary-color;
                a {
                    color: #212121;
                }
            }
        }
        .content {
            .head {
                display: flex;
                flex-direction: column;
                padding: 8px;
                border-bottom: 1px solid $--Gray-5;
                gap: 4px;
                h3 {
                    margin: 0;
                    color: #212121;
                    font-size: 20px;
                    font-weight: 600;
                }
                p {
                    color: var(--L1-ref-secondary-secondary40, #83848a);
                    font-size: 12px;
                    font-weight: 400;
                }
            }
            .text-container {
                display: flex;
                flex-direction: column;
                gap: 16px;
                padding: 16px;
                position: relative;
                .my-spn {
                    position: relative;
                    bottom: 100px;
                }
                p {
                    color: $--Gray-2;
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 10px;
                }
                h4 {
                    margin-bottom: 10px;
                    color: #212121;
                    font-size: 20px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .privacy-policy-main {
        padding-top: 95px;
    }
}
@media (max-width: 800px) {
    .privacy-policy-main {
        padding: 10px;
        padding-top: 95px;
        .details-container {
            margin: 8px 10px;
        }
    }
}
@media (max-width: 600px) {
    .privacy-policy-main {
        padding: 0;
        padding-top: 95px;
        background-color: white;
        .details-container {
            margin: 0;
            flex-direction: column;
            border: none;
            .side-bar {
                flex-direction: row;
                gap: 10px;
                justify-content: space-between;
                padding: 0;
                border-bottom: 1px solid white;
                width: 100%;
                overflow-x: scroll;
                span {
                    // width: 100%;
                    padding: 10px;
                    border: none;
                    border-bottom: 4px solid $--Gray-5;
                }
                span.active {
                    outline-color: $--primary-color;
                }
            }
            .side-bar::-webkit-scrollbar {
                width: 0px;
                height: 0px;
            }

            .content {
                .head {
                    padding-top: 20px;
                }
            }
        }
    }
}
@media (max-width: 400px) {
    .privacy-policy-main {
        padding-top: 75px;
    }
}
