@import "/src/variables";
@import "/src/index.scss";

.client-my-jobs-main {
    padding: 30px;
    padding-top: 100px;
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;

    p {
        margin: 0;
    }

    .content {
        h3 {
            color: #000;
            font-size: 28px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        .main-container {
            background-color: white;
            border-radius: 8px;
            border: 1px solid var(--Basic-400, #e4e9f2);
            .heading {
                display: flex;
                border-radius: 8px 8px 0px 0px;
                border: none;
                border-bottom: 1px solid var(--Basic-400, #e4e9f2);
                text-align: center;
                padding: 0 20px;
                span {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    cursor: pointer;
                    p {
                        padding: 0px 20px;
                        margin-top: 15px;
                        color: $--Gray-1;
                        font-size: 16px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        span {
                            color: #212121;
                            font-size: 11px;
                            font-weight: 500;
                            padding: 3px 3px 2px 3px;
                            border-radius: 4px;
                            background: #eef0f2;
                        }
                    }
                }
                .active {
                    p {
                        span {
                            background-color: #f1f7fd;
                            color: #1c43b9;
                        }
                    }
                    .underline {
                        height: 5px;
                        width: 100%;
                        border-radius: 8px 8px 0px 0px;
                        background: $--primary-color;
                    }
                }
            }
            .my-jobs {
                padding: 20px;
                .job-container {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    min-height: 350px;
                    position: relative;

                    .empty-message {
                        position: absolute;
                    }
                    .job {
                        display: flex;
                        border: 1px solid $--Gray-5;
                        border-radius: 12px;
                        .job-details-container {
                            position: relative;
                            .back-drop {
                                height: 100%;
                                width: 100%;
                                background-color: #00000018;
                                position: absolute;
                                border-radius: 12px 0 0 12px;
                                top: 0;
                                left: 0;
                                z-index: 1;
                            }

                            display: flex;
                            gap: 16px;
                            width: 65%;
                            padding: 20px;
                            border-right: 1px solid $--Gray-5;
                            max-height: 450px;
                            overflow-y: scroll;
                            overflow-x: hidden;
                            .no {
                                font-weight: 500;
                                font-size: 16px;
                            }
                            .details {
                                display: flex;
                                flex-direction: column;
                                gap: 16px;
                                width: 100%;

                                .job-title {
                                    display: flex;
                                    justify-content: space-between;
                                    .job-title-container {
                                        display: flex;
                                        align-items: center;
                                        gap: 6px;
                                        .title {
                                            font-size: 16px;
                                            font-weight: 600;
                                            color: $--Gray-1;
                                        }
                                        .seperator {
                                            display: inline-block;
                                            height: 4px;
                                            width: 4px;
                                            border-radius: 50%;
                                            background-color: $--Gray-4;
                                        }
                                        .budget {
                                            color: $--Gray-1;
                                            font-weight: 400;
                                            font-size: 14px;
                                        }
                                    }
                                    svg {
                                        cursor: pointer;
                                    }
                                    .options {
                                        position: absolute;
                                        display: flex;
                                        flex-direction: column;
                                        gap: 8px;
                                        top: 20px;
                                        right: 20px;
                                        border-radius: 8px;
                                        z-index: 2;
                                        box-shadow: 0px 4px 10px 0px
                                            rgba(0, 0, 0, 0.15);
                                        background-color: white;
                                        padding: 8px 4px;
                                        span {
                                            padding: 2px 8px;
                                            cursor: pointer;
                                            color: #131a25;
                                            font-size: 14px;
                                            font-weight: 500;
                                        }
                                        span:hover {
                                            background: linear-gradient(
                                                        to bottom right,
                                                        #5d01db 0%,
                                                        #3d008f 26%,
                                                        #2e0369 39%,
                                                        #210942 49%
                                                    )
                                                    bottom right / 50% 50%
                                                    no-repeat,
                                                linear-gradient(
                                                        to bottom left,
                                                        #5d01db 0%,
                                                        #3d008f 26%,
                                                        #2e0369 39%,
                                                        #210942 49%
                                                    )
                                                    bottom left / 50% 50%
                                                    no-repeat,
                                                linear-gradient(
                                                        to top left,
                                                        #5d01db 0%,
                                                        #3d008f 26%,
                                                        #2e0369 39%,
                                                        #210942 49%
                                                    )
                                                    top left / 50% 50% no-repeat,
                                                linear-gradient(
                                                        to top right,
                                                        #5d01db 0%,
                                                        #3d008f 26%,
                                                        #2e0369 39%,
                                                        #210942 49%
                                                    )
                                                    top right / 50% 50%
                                                    no-repeat;
                                            background-clip: text;
                                            -webkit-background-clip: text;
                                            -webkit-text-fill-color: transparent;
                                        }
                                    }
                                }
                                .about-job {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 12px;
                                    .grp {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 4px;
                                        width: fit-content;
                                        .bold {
                                            color: $--Gray-1;
                                            font-weight: 500;
                                            font-size: 14px;
                                        }
                                        .desc {
                                            color: $--Gray-3;
                                            font-size: 12px;
                                            font-weight: 500;
                                        }
                                        .attachment {
                                            border: 1px solid
                                                var(--Gray-5, #e0e0e0);
                                            border-radius: 4px;
                                            padding: 10px;
                                            display: flex;
                                            align-items: center;
                                            gap: 8px;
                                            width: 100%;
                                            cursor: pointer;

                                            img {
                                                height: 20px;
                                            }
                                            p {
                                                display: -webkit-box;
                                                -webkit-box-orient: vertical;
                                                -webkit-line-clamp: 2;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                color: var(
                                                    --L1-ref-secondary-secondary20,
                                                    #47484b
                                                );
                                                font-size: 14px;
                                                font-weight: 400;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .job-details-container::-webkit-scrollbar {
                            width: 4px;
                        }
                        .job-details-container::-webkit-scrollbar-thumb {
                            background-color: $--Gray-4;
                            border-radius: 20px;
                        }
                        .bids {
                            width: 35%;
                            display: flex;
                            flex-direction: column;
                            gap: 12px;
                            padding: 14px;
                            max-height: 450px;
                            overflow-y: scroll;
                            .bid-title {
                                font-weight: 600;
                                font-size: 16px;
                                color: $--Gray-1;
                            }
                            .bid-list {
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                .bid {
                                    width: 100%;
                                    border: 1px solid #f2f2f2;
                                    padding: 8px;
                                    border-radius: 8px;
                                    .about-freelancer {
                                        display: flex;
                                        justify-content: space-between;
                                        padding-bottom: 6px;
                                        border-bottom: 1px solid #f2f2f2;
                                        .about-container {
                                            display: flex;
                                            gap: 10px;
                                            .image-container {
                                                cursor: pointer;
                                                position: relative;
                                                height: fit-content;
                                                img {
                                                    height: 28px;
                                                    width: 28px;
                                                    border-radius: 50%;
                                                }
                                                .online-mark {
                                                    display: inline-block;
                                                    height: 10px;
                                                    width: 10px;
                                                    background: #219653;
                                                    position: absolute;
                                                    bottom: 2px;
                                                    right: 2px;
                                                    border-radius: 50%;
                                                }
                                            }
                                            .ratings-container {
                                                .name {
                                                    cursor: pointer;
                                                    color: #373a3f;
                                                    font-size: 14px;
                                                    font-weight: 600;
                                                    display: flex;
                                                    align-items: center;
                                                    gap: 6px;
                                                    .seperator {
                                                        display: inline-block;
                                                        height: 4px;
                                                        width: 4px;
                                                        border-radius: 50%;
                                                        background-color: $--Gray-4;
                                                    }
                                                    .account-verified {
                                                        display: flex;
                                                        gap: 2px;
                                                        background: $--Dark-Green-color;
                                                        border-radius: 4px;
                                                        padding: 2px 4px;
                                                        align-self: flex-start;
                                                        p {
                                                            color: #fff;
                                                            font-size: 9px;
                                                            font-weight: 500;
                                                        }
                                                    }
                                                }
                                                .ratings {
                                                    display: flex;
                                                    align-items: center;

                                                    svg {
                                                        height: 16px;
                                                        width: 16px;
                                                    }
                                                    .filled {
                                                        path {
                                                            fill: #f3c831;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .primary-btn-white {
                                            font-size: 12px;
                                            height: unset;
                                            padding: 0;
                                        }
                                    }
                                    .bid-details {
                                        margin-top: 4px;
                                        .dates {
                                            display: flex;
                                            flex-wrap: wrap;
                                            gap: 6px;
                                        }
                                        .desc {
                                            color: $--Gray-2;
                                            font-size: 10px;
                                            font-weight: 400;
                                        }
                                        .bold {
                                            font-size: 12px;
                                            font-weight: 500;
                                            color: #24262e;
                                        }
                                        .buttons {
                                            display: flex;
                                            gap: 8px;
                                            margin-top: 16px;
                                            .btn-white-outline {
                                                border-radius: 4px;
                                                width: fit-content;
                                                padding: 4px 20px;
                                                font-size: 12px;
                                                color: $--primary-color;
                                            }
                                            .view-proposal-btn {
                                                border-color: rgba(
                                                    19,
                                                    26,
                                                    37,
                                                    1
                                                );
                                                color: rgba(19, 26, 37, 1);
                                            }
                                        }
                                        .payment-completed{
                                            margin-top: 6px;
                                            svg{
                                                height: 14px;
                                                width: 14px;
                                                path{
                                                    fill: #126b38
                                                }
                                            }
                                            font-size: 12px;
                                            font-weight: 600;
                                            color: #126b38;
                                        }
                                        .payment-btn{
                                            border-radius: 6px;
                                            text-wrap: nowrap;
                                            width: fit-content;
                                            margin-top: 8px;
                                            font-size: 12px;
                                            padding: 4px 10px;
                                        }
                                        .revised_due_date {
                                            display: flex;
                                            align-items: center;
                                            gap: 4px;
                                            padding: 4px;
                                            min-width: 75%;
                                            background-color: #fdf1cb;
                                            margin-top: 16px;
                                            svg {
                                                height: 16px;
                                                width: 16px;
                                            }
                                            p {
                                                font-size: 12px;
                                                color: $--Dark-blue-2;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .bids::-webkit-scrollbar {
                            width: 4px;
                        }
                        .bids::-webkit-scrollbar-thumb {
                            background-color: $--Gray-4;
                            border-radius: 20px;
                        }
                    }
                }
            }
            .my-jobs.mobile {
                display: none;
            }
        }
    }
    .pagination {
        margin-top: 30px;
        margin-bottom: 30px;
        align-self: center;
        .MuiPaginationItem-previousNext {
            background-color: white;
            filter: drop-shadow(0px 1px 4px rgba(24, 26, 32, 0.07));
        }
        .Mui-selected {
            color: white;
            background-color: $--primary-color;
        }
    }
    .navigate-job {
        display: none;
    }
}
@media (max-width: 1000px) {
    .client-my-jobs-main {
        padding: 10px;
        padding-top: 100px;
        .content {
            .main-container {
                .heading {
                    span {
                        p {
                            padding: 0 10px;
                        }
                    }
                }
                .my-jobs {
                    padding: 14px;
                    .job-container {
                        .job {
                            .job-details-container {
                                width: 55%;
                            }
                            .bids {
                                width: 45%;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 800px) {
    .client-my-jobs-main {
        .content {
            h3 {
                display: none;
            }
            .main-container {
                border: none;
                .heading {
                    padding-left: 20px;
                    // flex-wrap: wrap;
                    overflow: auto;
                    span {
                        p {
                            padding: 0 10px;
                            font-size: 12px;

                            span {
                                font-size: 8px;
                            }
                        }
                    }
                }
                .my-jobs {
                    .job-container {
                        .job {
                            flex-direction: column;
                            .job-details-container {
                                width: 100%;
                                max-height: unset;
                                border: none;
                            }
                            .bids {
                                width: 100%;
                            }
                            .hide {
                                display: none;
                            }
                        }
                    }
                }
                .my-jobs.desktop {
                    display: none;
                }
                .my-jobs.mobile {
                    display: block;
                    padding-bottom: 60px;
                    width: 100vw;
                    .job-container{
                        .job{
                            .job-details-container{
                                .back-drop{
                                    border-radius: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .pagination {
            display: none;
        }
        .navigate-job {
            display: inline-block;
            position: fixed;
            height: 32px;
            width: 32px;
            background-color: #f8f8f8;
            display: grid;
            border-radius: 50%;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
            place-content: center;
            transform: translateY(-50%);
            bottom: 0%;
            cursor: pointer;
        }
        .navigate-job.prev {
            left: 35%;
            svg {
                transform: rotate(90deg);
            }
        }
        .navigate-job.next {
            right: 35%;
            svg {
                transform: rotate(-90deg);
            }
        }
    }
}
@media (max-width: 600px) {
    .client-my-jobs-main {
        padding: 0;
        padding-top: 80px;
        background-color: white;
        .content {
            .main-container {
                .heading {
                    padding-left: 20px;
                    // flex-wrap: wrap;
                    span {
                        p {
                            padding: 0 10px;
                            font-size: 12px;

                            span {
                                font-size: 8px;
                            }
                        }
                    }
                }
                .my-jobs {
                    // padding: 0;
                    .job-container {
                        .job {
                            border: none;
                            .job-details-container {
                                padding: 0;
                            }
                            .bids {
                                padding: 0;
                                border: 1px solid $--Gray-5;
                                border-radius: 8px;
                                padding: 8px;
                                margin-top: 16px;
                                .bid-list {
                                    .bid {
                                        border: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 400px) {
    .client-my-jobs-main {
        padding: 0;
        padding-top: 60px;
        background-color: white;
        .content {
            h3 {
                display: none;
            }
            .main-container {
                .heading {
                    padding-left: 20px;
                    // flex-wrap: wrap;
                    span {
                        p {
                            padding: 0 10px;
                            font-size: 12px;

                            span {
                                font-size: 8px;
                            }
                        }
                    }
                }
            }
        }
        .pagination {
            nav {
                width: 100vw;
            }
            margin: 20px auto;
            .MuiPagination-ul {
                flex-wrap: nowrap;
                transform: scale(0.8);
                margin: auto;
                justify-content: center;
                text-align: center;
            }
            .MuiPaginationItem-previousNext {
                background-color: white;
                filter: drop-shadow(0px 1px 4px rgba(24, 26, 32, 0.07));
            }
            .Mui-selected {
                color: white;
                background-color: $--primary-color;
            }
        }
    }
}
