@import "/src/variables";
@import "/src/index.scss";

.submit-form-main{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 14px;
    width: min(700px, 95vw);
    p{
        margin: 0;
    }
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        p{
            color: #000;
            font-size: 24px;
            font-weight: 600;
        }
        svg{
            height: 30px;
            width: 30px;
            cursor: pointer;
            path{
                fill: #1C1B1F;
            }
        }
    }
    .inputs{
        display: flex;
        flex-direction: column;
        gap: 12px;
        .input-grp {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .bold {
                color: $--Gray-1;
                font-size: 14px;
                font-weight: 600;
            }
            textarea {
                border-radius: 8px;
                border: 1px solid
                    var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                background: white;
                padding: 12px;
                width: 100%;
                height: 120px;
                font-size: 14px;
                font-weight: 500;
            }
            textarea:focus {
                outline: none;
            }
        }
        .file-upload{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;
            span{
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 12px;
                border-radius: 4px;
                border: 1px dashed var(--Basic-600, #8F9BB3);
                width: min(250px, 90%);
                cursor: pointer;
                p{
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                input{
                    display: none;
                }
                img{
                    height: 20px;
                    width: 20px;
                }
            }
            .plus{
                border: 1px solid #D2D3D5;
                padding: 8px;
                cursor: pointer;

                svg{
                    height: 28px;
                    width: 28px;
                    transform: rotate(45deg);
                    path{
                        fill: #1C1B1F;
                    }
                }
            }
        }
    }
    .buttons {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        font-size: 14px;
        font-weight: 500;

        .btn-blue {
            border-radius: 60px;
            background: $--primary-color;
            padding: 8px 20px;
            outline: none;
            border: none;
            cursor: pointer;
            color: white;
        }
        .btn-white-outline {
            padding: 8px 20px;
            width: fit-content;
            border-radius: 60px;
            border: none;
            background-color: transparent;
        }
    }
}