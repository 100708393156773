@import "/src/variables";
@import "/src/index.scss";

.step-7-main {
    .container {
        .desc {
            color: $--Gray-3;
            font-size: 16px;
            font-weight: 400;
        }
        .input-grp {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 20px;

            .text {
                max-width: 50%;
                .bold {
                    color: $--Gray-1;
                    font-size: 14px;
                    font-weight: 700;
                }
                p {
                    color: $--Gray-2;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
            .input-container {
                display: flex;
                align-items: center;
                gap: 12px;
                input {
                    border-radius: 8px;
                    border: 1px solid
                        var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                    background: white;
                    padding: 12px;
                    width: 100%;
                    height: 44px;
                    font-size: 14px;
                    font-weight: 500;
                }
                input:focus {
                    outline: none;
                }
                input:disabled{
                    background-color: rgba(19, 26, 37, 0.05);
                    cursor: not-allowed;
                }
                span {
                    color: $--Gray-1;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .step-7-main {
        .container {
            .input-grp{
                flex-direction: column;
                gap: 4px;
                .text{
                    max-width: 100%;
                }
            }
        }
    }
}
