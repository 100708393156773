@import "/src/variables";
@import "/src/index.scss";

.approve-work-main{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 14px;
    width: min(700px, 95vw);
    min-height: 450px;
    p{
        margin: 0;
    }
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        p{
            color: #000;
            font-size: 24px;
            font-weight: 600;
        }
        svg{
            height: 30px;
            width: 30px;
            cursor: pointer;
            path{
                fill: #1C1B1F;
            }
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        gap: 10px;
        p{
            font-size: 14px;
            font-weight: 400;
            span{
                color: #24262E;
                font-weight: 500;
            }
        }
        .bold{
            color: $--Gray-1;
            font-size: 14px;
            font-weight: 600;
        }
        .description, textarea{
            border-radius: 8px;
            padding: 12px;
            border: 1px solid $--Gray-4;
            min-height: 110px;
            font-size: 14px;
            color: $--Gray-1;
            outline: none;
        }
        .grp {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
            .bold {
                color: $--Gray-1;
                font-weight: 500;
                font-size: 14px;
            }
            .desc {
                color: $--Gray-3;
                font-size: 12px;
                font-weight: 500;
            }
            .attachment {
                border: 1px solid
                    var(--Gray-5, #e0e0e0);
                border-radius: 4px;
                padding: 10px;
                display: flex;
                align-items: center;
                gap: 8px;
                width: fit-content;
                cursor: pointer;

                img {
                    height: 20px;
                }
                p {
                    color: var(
                        --L1-ref-secondary-secondary20,
                        #47484b
                    );
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
        .input-grp {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .bold {
                color: $--Gray-1;
                font-size: 14px;
                font-weight: 600;
            }
            input, select {
                border-radius: 8px;
                border: 1px solid
                    var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                background: white;
                padding: 12px;
                width: min(100%, 200px);
                height: 44px;
                font-size: 14px;
                font-weight: 500;
            }
            input:focus {
                outline: none;
            }
            .inputs{
                display: flex;
                gap: 8px;
            }
        }
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        align-self: flex-end;
        margin-top: auto;

        .btn-blue {
            border-radius: 60px;
            background: $--primary-color;
            padding: 8px 30px;
            font-size: 14px;
            outline: none;
            border: none;
            cursor: pointer;
            color: white;
        }
        .primary-btn-white{
            font-size: 14px;
            font-weight: 500;
            color: $--Gray-1;
        }
    }
}