@import "/src/variables";
@import "/src/index.scss";

.offer-create-main {
    min-height: 100vh;
    width: 100vw;
    background-image: url("../../../Assets/Images/login-bg-design.svg");
    background-color: #f8f8f8;
    padding-top: 90px;
    display: grid;
    place-items: center;
    position: relative;

    .progress {
        position: absolute;
        top: 90px;
        place-self: baseline;
        width: 100vw;
        background: rgba(239, 230, 251, 0.7);
        height: 12px;
        border-radius: 8px;
        .bar {
            display: inline-block;
            height: 100%;
            border-radius: 8px;
            background: var(
                --Gredient,
                linear-gradient(90deg, #5d01db 1.88%, #210942 124.67%)
            );
        }
    }

    .step-main {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 80px;

        .container {
            background: #fff;
            border: 1px solid #eceded;
            padding: 30px 60px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            width: 60vw;
            max-width: unset;

            .text {
                .step {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    p {
                        color: $--Gray-2;
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
                .title {
                    color: #000;
                    font-size: 24px;
                    font-weight: 600;
                    margin-top: 10px;
                }
                .desc {
                    color: $--Gray-3;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
    }
    .buttons {
        position: fixed;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        width: 100vw;
        padding: 20px 40px;
        bottom: 0px;
        background-color: white;
        .btn-blue {
            border-radius: 60px;
            background: $--primary-color;
            padding: 8px 20px;
            outline: none;
            border: none;
            cursor: pointer;
            color: white;
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
        }
        .btn-white-outline {
            width: 151px;
            padding: 8px 20px;
            border-radius: 60px;
            border: 1px solid $--primary-color;
            background: rgba(239, 230, 251, 0.4);
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
        }
    }
    .mobile-button {
        display: none;
    }

    p {
        margin: 0;
    }
}

@media (max-width: 1000px) {
    .offer-create-main {
        .progress {
            top: 80px;
        }
        .step-main {
            .container {
                width: 75vw;
            }
        }
    }
}

@media (max-width: 800px) {
    .offer-create-main {
        .mobile-button {
            display: flex;
        }
        .desktop-button {
            display: none;
        }
    }
}

@media (max-width: 600px) {
    .offer-create-main {
        padding-top: 80px;

        .step-main {
            .container {
                width: 100vw;
                padding: 20px;
            }
        }
        .buttons {
            padding: 20px 20px;
            bottom: 0px;
            background-color: white;
        }
    }
}

@media (max-width: 400px) {
    .offer-create-main {
        padding-top: 62px;
        .progress {
            top: 62px;
        }
    }
}
