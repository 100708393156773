@import "/src/variables";
@import "/src/index.scss";

.bid-submited{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 24px 54px 24px;
    border-radius: 8px;
    background: #FFF;
    width: min(500px, 95vw);
    p{
        color: #000;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }
    .description{
        color: $--Gray-3;
        font-size: 14px;
        font-weight: 500;
    }
}