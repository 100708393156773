@import "/src/variables";
@import "/src/index.scss";

.signup-form-main {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        background: #fff;
        border: 1px solid #eceded;
        padding: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        width: 60vw;
        max-width: 600px;
        // scale: 0.9;
        border-radius: 12px;

        .title {
            font-size: 38px;
            font-weight: 600;
            text-align: center;
        }
        .login-btn {
            padding: 8px 20px;
            border-radius: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            font-size: 16px;
            font-weight: 500;
            width: 320px;
            max-width: 100%;
            border: none;
            outline: none;
        }
        .google-login {
            background: #1976d2;
            color: $--Gray-6;
        }
        .facebook-login {
            border: 1px solid #000;
            background-color: white;
        }
        .hr {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;

            hr {
                width: 100%;
            }
            p {
                color: var(--Neutral-200, #7d8187);
                font-size: 16px;
                font-weight: 500;
            }
        }
        .form-container {
            display: flex;
            gap: 20px;
            width: 100%;

            .input-grp {
                display: flex;
                flex-direction: column;
                // gap: 20px;
                width: 50%;

                .phone {
                    display: flex;
                    height: 100%;
                    border: 1px solid
                        var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                    border-radius: 8px;

                    .ReactFlagsSelect-module_flagsSelect__2pfa2 {
                        width: 95px;
                        height: 44px;
                        border-right: 1px solid
                            var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));

                        .ReactFlagsSelect-module_selectBtn__19wW7 {
                            padding: 12px 0px;
                            height: 44px;
                            border: none;
                            border-radius: 0px;
                            width: unset;

                            .ReactFlagsSelect-module_label__27pw9 {
                                font-size: 14px;
                                font-weight: 700;
                            }
                        }
                        .ReactFlagsSelect-module_selectBtn__19wW7::after {
                            display: none;
                        }
                    }
                    .phone_input {
                        width: calc(100% - 95px);
                        border: none;
                    }
                }
                .password {
                    border: 1px solid
                        var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    padding-right: 12px;

                    input {
                        border: none;
                        width: 100%;
                    }

                    svg {
                        cursor: pointer;
                    }
                }

                input {
                    border-radius: 8px;
                    border: 1px solid
                        var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                    background: white;
                    padding: 12px;
                    height: 44px;
                    font-size: 14px;
                    font-weight: 500;
                }
                input:focus {
                    outline: none;
                }
            }
            .forgot-password {
                align-self: flex-end;
                color: #5d01db;
                cursor: pointer;
            }
        }
        .login-form {
            flex-direction: column;
            .input-grp {
                width: 100%;
            }
        }
        .next-action {
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
                color: #484e56;
                font-size: 14px;
                font-weight: 500;
                margin-top: 8px;

                span {
                    color: #5d01db;
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .signup-form-main {
        .container {
            padding: 30px;
        }
    }
}

@media (max-width: 800px) {
    .signup-form-main {
        .container {
            padding: 20px;
            width: 100%;
            height: 100%;
            gap: 16px;
            .form-container {
                flex-direction: column;
                .input-grp {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .signup-form-main {
        width: 100vw;
        .container {
            scale: 0.9;
            height: fit-content;

            .title {
                font-size: 32px;
            }
        }
    }
}
