@import "/src/variables";
@import "/src/index.scss";

.billing-history-main{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px 0;
    height: 100%;

    p{
        margin: 0;
    }
    .head{
        display: flex;
        border-radius: 8px;
        border: 1px solid $--Gray-5;
        padding: 20px;
        div{
            width: 100%;
            border-right: 1px solid $--Gray-5;
            padding-left: 40px;
            p{
                color: $--Gray-2;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 8px;
            }
            .data{
                font-size: 40px;
                font-weight: 600;
                margin: 0;
            }
        }
        .last{
            border: none;
        }
    }
    .table{
        border-radius: 8px;
        border: 1px solid $--Gray-5;
        overflow: hidden;
        height: 100%;
        .table-head{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            background-color: $--Gray-6;
            padding: 12px 0px;
            span{
                padding: 0px 8px;
                color: #131A25;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 14px;
                font-weight: 500;
                border-right: 1px solid $--Gray-5;
            }
        }
        .table-body{
            overflow-y: scroll;
            height: 100%;
            .table-row{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                padding: 12px 0px;
                span{
                    padding: 0px 8px;
                    color: #131A25;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 14px;
                    font-weight: 500;
                    border-right: 1px solid $--Gray-5;
                }
                span:last-child{
                    border-right: none;
                }
            }
        }
        .table-body::-webkit-scrollbar {
            width: 4px;
        }
        .table-body::-webkit-scrollbar-thumb {
            background-color: $--Gray-4;
            border-radius: 20px;
        }
    }
}
@media (max-width: 1000px){
    .billing-history-main{
        .head{
            padding: 10px;
            div{
                padding-left: 20px;
                p{
                    font-size: 14px;
                }
                .data{
                    font-size: 32px;
                }
            }
        }
    }
}
@media (max-width: 800px){
    .billing-history-main{
        .head{
            padding: 10px 0;
            div{
                padding-left: 12px;
                p{
                    font-size: 12px;
                }
                .data{
                    font-size: 26px;
                }
            }
        }
    }
}
@media (max-width: 600px){
    .billing-history-main{
        .head{
            padding: 10px 0;
            div{
                padding-left: 10px;
                p{
                    font-size: 10px;
                }
                .data{
                    font-size: 20px;
                }
            }
        }
    }
}
@media (max-width: 400px){
    .billing-history-main{
        padding: 10px 0;
        .head{
            padding: 10px 0;
            div{
                padding-left: 10px;
                p{
                    font-size: 8px;
                }
                .data{
                    font-size: 16px;
                }
            }
        }
    }
}