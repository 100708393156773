@import "/src/variables";
@import "/src/index.scss";

.contact-us-main {
  padding: 20px;
  padding-top: 105px;
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  min-height: 100vh;

  p {
    margin: 0;
  }
  h2 {
    font-size: 28px;
    font-weight: 500;
  }
  .contact-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: 24px;

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: var(
        --Gredient,
        linear-gradient(90deg, #5d01db 1.88%, #210942 124.67%)
      );
      padding: 20px;
      width: 100%;

      h3 {
        color: #fff;
        font-size: 24px;
        font-weight: 800;
        line-height: 100%;
      }
      p {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .title {
      color: #393939;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin: 12px;
    }
    form {
      width: min(500px, 90%);
      border-radius: 20px;
      border: 2px solid var(--Gredient, #5d01db);
      background: linear-gradient(
        90deg,
        rgba(93, 1, 219, 0.05) 1.88%,
        rgba(33, 9, 66, 0.05) 124.67%
      );
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .input-grp {
        display: flex;
        flex-direction: column;
        label {
          color: $--Gray-1;
          font-size: 12px;
          font-weight: 700;
          span {
            color: #eb5757;
          }
        }
        input,
        select,
        textarea {
          border-radius: 8px;
          border: 1px solid var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
          background: var(--L1-white, #fff);
          padding: 12px;
          outline: none;
        }
        textarea {
          height: 100px;
          resize: none;
        }
      }
      button {
        width: 100%;
      }
    }
  }
  .contact-detail-wrapper {
    width: min(500px, 100%);
    margin: auto;
    padding: 40px 0px;
  }
}
@media (max-width: 1000px) {
  .contact-us-main {
    padding-top: 95px;
  }
}
@media (max-width: 400px) {
  .contact-us-main {
    padding-top: 75px;
  }
}
