@import "/src/variables";
@import "/src/index.scss";

.profile-container {
    padding: 28px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: #fff;
    border-radius: 8px;

    .profile-image-controler {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .image-name-container {
            display: flex;
            align-items: center;
            gap: 8px;
            .image-container {
                position: relative;
                cursor: pointer;
                input {
                    display: none;
                }
                img {
                    height: 74px;
                    width: 74px;
                    border-radius: 74px;
                    border: 3.469px solid #fff;
                }
                .edit-icon {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    cursor: pointer;
                }
            }
            .personal-details {
                .large {
                    color: #373a3f;
                    font-size: 28px;
                    font-weight: 600;
                    line-height: 100%;
                }
                p {
                    font-size: 14px;
                    color: #373a3f;
                }
            }
        }
        .buttons {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
    .info-container {
        border-radius: 8px;
        border: 1px solid $--Gray-5;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 14px;
        .info-title {
            color: rgba(55, 58, 63, 1);
            font-size: 20px;
            font-weight: 600;
        }
        .info {
            width: 100%;
            color: $--Gray-3;
            font-size: 16px;
            font-weight: 400;
        }
        .bold {
            font-size: 16px;
            font-weight: 500;
            color: $--Gray-2;
        }
        .grp {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
        .skills-container {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            .skill {
                display: flex;
                align-items: center;
                gap: 2px;
                border: 1px solid #5d01db;
                border-radius: 4px;
                background: rgba(239, 230, 251, 0.4);
                padding: 4px 6px 4px 4px;
                color: black;
                font-size: 12px;
                font-weight: 500;
                cursor: pointer;
                svg {
                    transform: rotate(45deg);
                    width: 20px;
                    height: 20px;
                    path {
                        fill: black;
                    }
                }
            }
        }

        .experience-container,
        .education-container {
            display: inline-flex;
            gap: 10px;
            flex-wrap: wrap;

            .experience,
            .education {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 230px;
                min-height: 100px;
                border: 1px solid $--Gray-5;
                border-radius: 10px;
                padding: 8px;

                .details {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    .heading {
                        p {
                            color: #131a25;
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }
                    p {
                        padding: 0px 8px;
                        color: $--Gray-2;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
                .icons {
                    align-self: flex-end;
                    justify-self: flex-end;
                    margin-bottom: 8px;

                    svg {
                        height: 22px;
                        width: 22px;
                        margin-right: 8px;
                        cursor: pointer;
                    }
                }
            }
            .add-experience,
            .add-education {
                display: flex;
                align-items: center;
                padding: 20px;
                gap: 8px;
                border-radius: 10px;
                border: 1px solid $--Gray-5;
                cursor: pointer;

                svg {
                    height: 50px;
                    width: 50px;
                    fill: $--Gray-5;
                    transform: rotate(45deg);
                    path {
                        fill: $--Gray-5;
                    }
                }
            }
        }
        .text-container {
            .bold {
                color: #373a3f;
                font-size: 20px;
                font-weight: 600;
            }
            .desc {
                color: $--Gray-2;
                font-size: 14px;
                font-weight: 400;
                margin-top: 8px;
            }
            .user-review {
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 10px 16px;
                border-radius: 8px;
                border: 1px solid $--Gray-5;
                margin-top: 10px;

                .ratings-container {
                    .name {
                        color: #373a3f;
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .ratings {
                        display: flex;
                        align-items: center;

                        svg {
                            height: 16px;
                            width: 16px;
                        }
                        .filled {
                            path {
                                fill: #f3c831;
                            }
                        }
                    }
                }
                .review {
                    color: $--Gray-3;
                    font-size: 12px;
                    font-weight: 400;
                }
                .client-name {
                    color: $--Gray-3;
                    font-size: 14px;
                    font-weight: 400;
                    span {
                        color: $--Gray-2;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .profile-container {
        padding: 20px;
        .profile-image-controler {
            gap: 8px;
            .image-name-container {
                .image-container {
                    img {
                        height: 60px;
                        width: 60px;
                    }
                    .edit-icon {
                        height: 22px;
                        width: 22px;
                    }
                }
                p {
                    font-size: 24px;
                }
                .online-tag {
                    font-size: 12px;
                }
            }
            .btn-blue {
                align-self: flex-end;
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 500px) {
    .profile-container {
        .profile-image-controler {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

@media (max-width: 600px) {
    .profile-container {
        padding: 10px;
        .profile-image-controler {
            flex-direction: column;
            align-items: flex-start;
            .image-name-container {
                .image-container {
                    margin-right: 8px;
                    img {
                        height: 50px;
                        width: 50px;
                    }
                    .edit-icon {
                        height: 18px;
                        width: 18px;
                    }
                }
                p {
                    font-size: 20px;
                }
            }
            .buttons {
                width: 100%;
                flex-direction: column-reverse;
                button {
                    width: 100%;
                    border-radius: 8px;
                }
            }
        }
        .info-container {
            .info-title {
                font-size: 16px;
            }
            svg {
                height: 22px;
                width: 22px;
            }
            .experience-container,
            .education-container {
                .experience,
                .education {
                    .details {
                        .heading {
                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .details {
                p {
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .profile-container {
        .profile-image-controler {
            .image-name-container {
                .image-container {
                    img {
                        height: 50px;
                        width: 50px;
                    }
                    .edit-icon {
                        height: 18px;
                        width: 18px;
                    }
                }
                p {
                    font-size: 16px;
                }
            }
        }
    }
}
