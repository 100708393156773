@import "/src/variables";
@import "/src/index.scss";

.freelancer-filters-main {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    width: min(300px, 95vw);
    flex-shrink: 0;
    height: 100%;
    overflow: auto;
    p {
        margin: 0;
    }

    .clear {
        color: #1c0067;
        font-size: 14px;
        font-weight: 600;
        text-align: right;
    }
    .filters-body {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .filter-category {
            .title {
                color: #000;
                font-size: 20px;
                font-weight: 600;
            }
        }
        .categories {
            display: flex;
            flex-direction: column;
            gap: 16px;
            .category-item{
                display: flex;
                align-items: center;
                gap: 8px;
                .check-box{
                    height: 18px;
                    width: 18px;
                    display: grid;
                    place-items: center;
                    border: 2px solid #57557D;
                    border-radius: 50%;
                    cursor: pointer;
                }
                .checked{
                    background: #5D01DB;
                }
                p{
                    font-weight: 400;
                }
            }
            .show-more{
                cursor: pointer;
                color: #393939;
                font-size: 16px;
                font-weight: 600;
            }
        }
        .delivery-time, .response-time, .location{
            select{
                padding: 14px;
                border-radius: 8px;
                border: 1px solid var(--Gray, #E0E6ED);
                background: #FFF;
                width: 100%;
                color: $--Dark-blue-2;
                font-size: 14px;
                font-weight: 400;
                margin-top: 12px;
            }
            select:focus{
                outline: none;
            }
        }
        .budget{
            display: flex;
            flex-direction: column;
            gap: 30px;

            .MuiSlider-root{
                color: #32B14F;
                width: 90%;

                .MuiSlider-thumb{
                    border: 2px solid white;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }
                .MuiSlider-thumb:hover{
                    box-shadow: none;
                }
                .MuiSlider-valueLabel{
                    background-color: #D4FFDE;
                    color: $--Dark-blue-1;
                    border: none;
                    outline: none;
                }
            }
            .values{
                display: flex;
                gap: 12px;

                .value{
                    width: 100px;
                    height: 36px;
                    display: grid;
                    place-content: center;
                    border-radius: 10px;
                    background: var(--Gray, #E0E6ED);
                }
            }
        }
        .primary-btn-blue{
            border-radius: 10px;
            width: 100%;
        }
    }
}
