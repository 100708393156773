$--white: #FFFFFF;
$--my-black: #202020;
$--my-red: #E31E26;
$--primary-font-family: 'Plus Jakarta Sans', sans-serif;
$--Gray-1: #333;
$--Gray-2: hsl(0, 0%, 31%);
$--Gray-3: #828282;
$--Gray-4: #BDBDBD;
$--Gray-5: #E0E0E0;
$--Gray-6: #F2F2F2;
$--Dark-Gray: #6E767B;
$--primary-color: #5D01DB;
$--Main-Blue-color: #5A50D3;
$--Main-Green-color: #56D173;
$--Dark-Green-color: rgba(33, 150, 83, 1);
$--Dark-blue-1: #1F1C40;
$--Dark-blue-2: #3A3855;
$--Dark-blue-3: #57557D;