@import "/src/variables";
@import "/src/index.scss";

.emoji-back-drop{
    height: 100%;
    width: 100vw;
    background-color: #000000AA;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}
.chat-window-main {
    width: 70%;
    background-color: #fff;
    border-radius: 0px 8px 8px 0px;
    height: calc(100vh - 140px);
    border: 1px solid $--Gray-5;
    position: relative;

    input[type='file']{
        display: none;
    }

    .back-drop{
        height: 100%;
        width: 100%;
        background-color: #000000AA;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .about {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        border-bottom: 1px solid var(--Gray-5, #e0e0e0);
        height: 70px;
        position: relative;

        .about-user.freelancer-about{
            cursor: pointer;
        }
        .about-user {
            display: flex;
            align-items: center;
            gap: 8px;

            svg{
                height: 30px;
                width: 30px;
                transform: rotate(90deg);
                display: none;
            }
            .image-container {
                position: relative;
                img {
                    height: 34px;
                    width: 34px;
                    border-radius: 50%;
                }
                .online-mark {
                    display: inline-block;
                    height: 8px;
                    width: 8px;
                    background: #219653;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    border-radius: 50%;
                }
            }
            .name-container {
                .name {
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 100%;
                }
                .status {
                    color: $--Gray-3;
                    font-size: 9px;
                    font-weight: 400;
                }
            }
        }
        .user-options{
            background-color: $--Gray-6;
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 20px;
            right: 20px;
            border-radius: 8px;
            z-index: 2;
            span{
                padding: 10px 20px;
                cursor: pointer;
            }
            hr{
                margin: 0;
                opacity: 0.05;
            }
        }
    }
    .message-container {
        display: flex;
        flex-direction: column;
        gap: 18px;
        height: calc(100% - 70px - 72px);
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 20px;

        .message {
            display: flex;
            gap: 8px;
            width: fit-content;
            max-width: 75%;
            .user-image {
                height: 34px;
                width: 34px;
                border-radius: 34px;
            }
            span {
                display: flex;
                flex-direction: column;
                height: fit-content;
                // gap: 5px;
                .name {
                    color: #000;
                    font-size: 14px;
                    font-weight: 600;
                }
                .message-text {
                    padding: 10px 15px;
                    border-radius: 0px 10px 10px 10px;
                    background: #f1f7ff;
                    color: #0d082c;
                    font-size: 14px;
                    font-weight: 400;
                    width: fit-content;
                }
                .message-img{
                    max-height: 300px;
                    max-width: 100%;
                }
                .time {
                    color: rgba(13, 8, 44, 0.4);
                    font-size: 14px;
                    font-weight: 400;
                    text-align: right;
                }
                .attachment {
                    border: 1px solid var(--Gray-5, #e0e0e0);
                    border-radius: 4px;
                    padding: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;
                    width: fit-content;
                    cursor: pointer;
                    
                    img {
                        height: 20px;
                    }
                    p {
                        color: var(--L1-ref-secondary-secondary20, #47484b);
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }
        .message.sent {
            align-self: flex-end;
            span {
                align-items: flex-end;
                .message {
                    border-radius: 10px 0px 10px 10px;
                    background: rgba(165, 166, 246, 0.15);
                    color: $--primary-color;
                }
            }
        }
    }
    .message-container::-webkit-scrollbar {
        width: 4px;
    }
    .message-container::-webkit-scrollbar-thumb {
        background-color: $--Gray-4;
        border-radius: 20px;
    }
    .input-container {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 16px;
        background-color: white;
        border-top: 1px solid $--Gray-5;
        border-bottom: 1px solid $--Gray-5;
        border-radius: 8px;
        position: relative;
        width: 100%;

        .emoji-picker{
            position: absolute;
            bottom: 50px;
            left: 20px;
            max-width: 100%;
            z-index: 2;
            // overflow: hidden;
            
            .epr-main.EmojiPickerReact.epr_mw4zr1.epr_q53mwh.epr_7hyle2.epr_-x928hz.epr_-rvl3je.epr_-khp9xq.epr_-to8mm.epr_-4ueg5a.epr_iddbfm.epr_vy4vae.epr_6daqyu.epr_-6npj90{
                width: 270px !important;
                height: 250px !important;

                h2{
                    display: none;
                }
            }
            .epr-header.epr_iogimd.epr_wtwvf4{
                display: none !important;
            }
            .epr_q53mwh.epr_vl50xg.epr_-3yva2a.epr_c90x4z.epr_-sisw4f.epr_mw4zr1.epr_-kg0voo.epr_iogimd.epr_wtwvf4.epr_-lzq3oa{
                display: none !important;
            }
            .epr-emoji-category.epr_-lphakh.epr-hidden-on-search.epr_iu1na8{
                display: none !important;
            }
        }

        svg {
            cursor: pointer;
            min-height: 20px;
            min-width: 20px;
        }

        input {
            width: 100%;
            border: none;
            outline: none;
        }
        .send-btn {
            display: grid;
            place-items: center;
            border-radius: 50%;
            background: var(
                --Gredient,
                linear-gradient(90deg, #5d01db 1.88%, #210942 124.67%)
            );
            min-height: 40px;
            min-width: 40px;
            transform: rotate(-90deg);

            svg {
                path {
                    stroke: white;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .chat-window-main {
        width: 100%;
        .about{
            .about-user{
                svg{
                    display: block;
                }
            }
        }
    }
}

@media (max-width: 800px){
    .chat-window-main{
        height: calc(100vh - 80px);

    }
}

@media (max-width: 400px){
    .chat-window-main{
        height: calc(100vh - 60px);
        .about{
            padding: 0;
            padding-right: 10px;
        }
        .message-container{
            padding: 10px;
        } 
    }
}