.step-2-main {
    #offer-container {
        width: 80vw;
        margin: 40px 0px;
    border-radius: 8px;


        .desktop-data-table-container{
            width: 100%;
        }
        .mobile-data-table-container {
            display: none;
        }
    }
}

@media (max-width: 1000px) {
    .step-2-main {
        #offer-container {
            width: 90vw;
        }
    }
}

@media (max-width: 800px) {
    .step-2-main {
        #offer-container {
            .desktop-data-table-container {
                display: none;
            }
            .mobile-data-table-container {
                display: block;
                width: 100%;
            }
        }
    }
}

@media (max-width: 600px) {
    .step-2-main {
        #offer-container {
            width: 100vw;
        }
    }
}
