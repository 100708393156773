@import "/src/variables";
@import "/src/index.scss";

.nav-main {
    display: flex;
    justify-content: space-between;
    padding: 18px 30px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

    .back-drop{
        height: 100vh;
        width: 100vw;
        background-color: #000000AA;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
    }
    p{
        margin: 0;
    }

    .nav-items {
        display: flex;
        align-items: center;
        gap: 20px;
        .menu-icon {
            display: none;
        }
        .logo {
            width: 90px;
        }
        .items {
            display: flex;
            align-items: center;
            gap: 20px;

            .item {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 1px;
                cursor: pointer;
                p {
                    color: $--primary-color;
                    font-size: 18px;
                    font-weight: 500;
                    // white-space: nowrap;
                    margin: 0;
                }
                .under-line {
                    display: inline-block;
                    height: 2px;
                    width: 50%;
                    background: $--primary-color;
                    border-radius: 2px;
                }
                .under-line::after {
                    position: absolute;
                    content: "";
                    height: 2px;
                    width: 2px;
                    background-color: black;
                    left: calc(50% + 2px);
                    border-radius: 50%;
                }
            }
        }
    }
    .icons {
        display: flex;
        align-items: center;
        gap: 16px;

        button{
            width: fit-content;
            font-size: 16px;
        }
        svg,
        img {
            height: 28px;
            width: 28px;
            cursor: pointer;
        }
        img {
            border-radius: 50%;
        }
        .options {
            position: absolute;
            display: flex;
            flex-direction: column;
            gap: 8px;
            top: 65px;
            right: 35px;
            border-radius: 8px;
            z-index: 2;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
            background-color: white;
            padding: 8px 4px;
            span {
                padding: 2px 8px;
                cursor: pointer;
                color: #131a25;
                font-size: 14px;
                font-weight: 500;
            }
            span:hover {
                background: linear-gradient(
                            to bottom right,
                            #5d01db 0%,
                            #3d008f 26%,
                            #2e0369 39%,
                            #210942 49%
                        )
                        bottom right / 50% 50% no-repeat,
                    linear-gradient(
                            to bottom left,
                            #5d01db 0%,
                            #3d008f 26%,
                            #2e0369 39%,
                            #210942 49%
                        )
                        bottom left / 50% 50% no-repeat,
                    linear-gradient(
                            to top left,
                            #5d01db 0%,
                            #3d008f 26%,
                            #2e0369 39%,
                            #210942 49%
                        )
                        top left / 50% 50% no-repeat,
                    linear-gradient(
                            to top right,
                            #5d01db 0%,
                            #3d008f 26%,
                            #2e0369 39%,
                            #210942 49%
                        )
                        top right / 50% 50% no-repeat;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        .notifications {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: min(400px, 90vw);
            height: 75vh;
            gap: 8px;
            top: 65px;
            right: 25px;
            border-radius: 8px;
            z-index: 2;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
            background-color: white;
            overflow-y: scroll;
            
            .notification{
                display: flex;
                gap: 16px;
                padding: 16px;
                border-bottom: 1px solid $--Gray-5;
                position: relative;
                .unreaded{
                    display: inline-block;
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    background: rgba(47, 128, 237, 1);
                }
                .noti-details{
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    .text{
                        color: var(--Knock-gray-900, #1A1F36);
                        font-size: 14px;
                        font-weight: 500;
                    }
                    .time{
                        color: var(--Knock-gray-200, #A5ACB8);
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@media (max-width: 1100px){
    .nav-main{
        .nav-items{
            gap: 16px;
            .items{
                gap: 16px;

                .item{

                    p{
                        font-size: 17px;
                    }
                }

            }
        }

    }
}
@media (max-width: 1000px) {
    .nav-main {
        padding: 20px;
        .nav-items {
            .logo {
                height: 40px;
            }
            .items {
                gap: 16px;
                .item {
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
        .icons{
            .options{
                top: 60px;
                right: 25px;
            }
            button{
                padding: 6px 10px;
                font-size: 12px;
            }
            svg{
                height: 20px;
                width: 20px;
            }
            img{
                height: 24px;
                width: 24px;
            }
        }
    }
}

@media (max-width: 800px) {
    .nav-main {
        .nav-items {
            .menu-icon {
                display: block;
                height: 30px;
                width: 30px;
            }
            .items {
                display: none;
            }
        }
        .icons{
            button{
                display: none;
            }
        }
    }
}
@media (max-width: 600px) {
    .nav-main {
        padding: 20px;
        .nav-items {
            .logo {
                height: 40px;
            }
        }
        .icons{
            .notifications{
                top: 55px;
                right: 15px;
            }
        }
    }
}

@media (max-width: 400px) {
    .nav-main {
        padding: 16px;
        .nav-items {
            .logo {
                height: 30px;
            }
        }
        .icons{
            .options{
                top: 50px;
                right: 20px;
            }
        }
    }
}
