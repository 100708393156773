@import "/src/variables";
@import "/src/index.scss";

.skills-form-main{
    width: 100%;
    p{
        margin: 0;
    }
    .input-grp {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .bold {
            color: $--Gray-1;
            font-size: 14px;
            font-weight: 600;
            span {
                color: $--Gray-3;
                font-size: 12px;
                font-weight: 400;
            }
            .astrict {
                color: #eb5757;
                font-size: 14px;
                font-weight: 400;
            }
        }
        .role-input {
            border-radius: 8px;
            border: 1px solid
                var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
            background: white;
            padding: 12px;
            width: 100%;
            height: 44px;
            font-size: 14px;
            font-weight: 500;
        }
        .role-input:focus {
            outline: none;
        }
        .skills-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
            padding: 6px 12px;
            z-index: 1;
            .sc-dcJsrY {
                min-width: 200px;
                flex-grow: 100;
                .wrapper {
                    border: none;
                    box-shadow: none;
                    height: 30px;
                    
                    .sc-gEvEer{
                        background: var(--L1-white, #FFF); 
                        width: 100%;
                    }
                }
                .wrapper:active {
                    box-shadow: none;
                    border: none;
                }

                .jVORrT {
                    display: none;
                }
                .clear-icon {
                    display: none;
                }
                input {
                    padding: 0;
                }
            }
            .selected-skill {
                display: flex;
                align-items: center;
                white-space: nowrap;
                padding: 3px 4px 3px 6px;
                border-radius: 4px;
                background: #1c0042;
                color: #fff;
                font-weight: 500;
                cursor: pointer;
            }
        }
        .max-number {
            color: $--Gray-3;
            font-size: 10px;
            font-weight: 400;
            align-self: flex-end;
        }
        .suggested-skills {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            .suggested-skill {
                display: flex;
                align-items: center;
                gap: 2px;
                border: 1px solid #5d01db;
                border-radius: 4px;
                background: rgba(239, 230, 251, 0.4);
                padding: 4px 6px 4px 4px;
                color: black;
                font-size: 12px;
                font-weight: 500;
                cursor: pointer;
                svg {
                    transform: rotate(45deg);
                    width: 20px;
                    height: 20px;
                    path {
                        fill: black;
                    }
                }
            }
        }
    }
}
.skills-form-main.skills-form-edit-model{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 14px;
    padding: 20px;
    width: min(500px, 95vw);

    .text {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .title {
            color: #000;
            font-size: 24px;
            font-weight: 600;
            margin: 0;
        }
        svg {
            min-height: 30px;
            min-width: 30px;
            path {
                fill: #1c1b1f;
            }
        }
    }
}