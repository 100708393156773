@import "/src/variables";
@import "/src/index.scss";

.review-offer-main {
    display: flex;
    gap: 30px;
    padding: 30px;
    padding-bottom: 100px;
    width: 100%;
    border-radius: 8px;

    p {
        margin: 0;
    }
}

@media (max-width: 1000px) {
    .review-offer-main {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .review-offer-main {
        padding: 0px;
        padding-bottom: 80px;
    }
}
