@import "/src/variables";
@import "/src/index.scss";

.about-us-main {
    padding: 60px;
    padding-top: 105px;
    display: flex;
    flex-direction: column;
    background: #f8f8f8;
    scroll-behavior: auto;
    p {
        margin: 0;
    }
    h2 {
        font-size: 28px;
        font-weight: 500;
    }
    .about-us-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        border-radius: 8px;
        overflow: hidden;
        padding-bottom: 24px;
    }
    .header{
        background: url("../../Assets/Images/about-us.svg"), #210942;
        padding: 50px 0;
        position: relative;
        width: 100%;
        .backface{
            position: absolute;
            height: 100%;
            width: 100%;
            background: #210942;
            opacity: 0.7;
            top: 0;
            left: 0;
        }
        .heading{
            position: relative;
            z-index: 1;
            color: $--Gray-6;
            font-size: 40px;
            text-align: center;
            text-wrap: nowrap;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
    .section-1{
        padding: 60px;
        padding-left: 30px;
        display: flex;
        justify-content: space-between;
        .title{
            .my-hr{
                height: 3px;
                width: 65px;
                border-radius: 1px;
                background: var(--Gredient, linear-gradient(90deg, #5D01DB 1.88%, #210942 124.67%));
                margin-bottom: 6px;
            }
            p{
                text-wrap: nowrap;
                color: $--Gray-1;
                font-size: 24px;
                font-weight: 600;
            }
        }
        .desc{
            width: 70%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            p{
                color: #212121;
                font-size: 18px;
                font-weight: 400;
            }
        }
    }
    .section-2{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 30px;
        padding-left: 0;
        background: #5D01DB20;
        position: relative;
        min-height: 300px;
        img{
            height: 300px;
            position: absolute;
            bottom: 40px;
            left: 0;
        }
        .desc{
            width: 70%;
            .title{
                .my-hr{
                    height: 3px;
                    width: 120px;
                    border-radius: 1px;
                    background: var(--Gredient, linear-gradient(90deg, #5D01DB 1.88%, #210942 124.67%));
                    margin-bottom: 6px;
                }
                p{
                    text-wrap: nowrap;
                    color: var(--Logo-blue, #1C0067);
                    font-size: 24px;
                    font-weight: 600;
                    margin-bottom: 16px;
                }
            }
            .text{
                font-size: 18px;
                font-weight: 400;
            }
        }
    }
    .section-3{
        padding: 60px;
        padding-left: 30px;
        padding-right: 90px;
        display: flex;
        justify-content: space-between;
        gap: 40px;
        .details{
            width: 65%;
            .title{
                .my-hr{
                    height: 3px;
                    width: 65px;
                    border-radius: 1px;
                    background: var(--Gredient, linear-gradient(90deg, #5D01DB 1.88%, #210942 124.67%));
                    margin-bottom: 6px;
                }
                p{
                    text-wrap: nowrap;
                    color: $--Gray-1;
                    font-size: 24px;
                    font-weight: 600;

                    margin-bottom: 16px;
                }
            }
            .desc{
                // width: 50%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                p{
                    color: #212121;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }
        .images{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 40px;
            align-self: flex-end;
            .image{
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                border-radius: 26px;
                background: #FFF;
                box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.10);
                position: relative;
                p{
                    text-wrap: nowrap;
                }
                .text{
                    .bold{
                        color: #39414D;
                        font-size: 38px;
                        font-weight: 700;
                    }
                    .desc{
                        color: #4D5766;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
            .second{
                top: 40px;
            }
            .third{
                right: 20px;
                // bottom: 20px;
            }
            .fourth{
                right: 20px;
                top: 40px;
            }
        }
    }
    .section-4 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 60px;
        padding: 80px 16px;
        background: #f8f8f8;
        width: 100%;

        .side-image {
            width: 350px;
        }
        .section-4-content {
            width: 450px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .text {
                .my-hr{
                    height: 3px;
                    width: 90px;
                    border-radius: 1px;
                    background: var(--Gredient, linear-gradient(90deg, #5D01DB 1.88%, #210942 124.67%));
                    margin-bottom: 6px;
                }
                h3 {
                    color: $--Dark-blue-2;
                    font-size: 22.517px;
                    font-weight: 700;
                    margin: 0;
                }
            }
            .accordion {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 12px;

                .item {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    padding: 12px 20px;
                    border-radius: 15.012px;
                    background: #fff;
                    box-shadow: 1.501px 8.256px 16.513px 0px
                            rgba(140, 132, 232, 0.1),
                        0px 9.758px 21.016px 0px rgba(140, 132, 232, 0.05);
                    cursor: pointer;

                    .accordian-icon{
                        height: 50px;
                    }

                    .details {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        width: 100%;

                        .title {
                            color: $--Dark-blue-2;
                            font-size: 16px;
                            font-weight: 600;
                            margin: 0;
                        }
                        .data {
                            // display: none;
                            // height: fit-content;
                            max-height: 0px;
                            overflow-y: hidden;
                            color: $--Dark-Gray;
                            font-size: 12px;
                            font-weight: 400;
                            margin: 0;
                            transition: all 0.5s ease;
                        }
                        .data.expanded {
                            // display: block;
                            max-height: 150px;
                        }
                    }
                    .arrow.rotate {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .section-5 {
        position: relative;
        background: url("../../Assets/Images/home-women-desktop.svg");
        background-size: cover;
        background-repeat: no-repeat;
        width: 100vw;
        aspect-ratio: 1440/501;
        padding-left: 250px;
        overflow-y: hidden;

        display: flex;
        align-items: center;

        .text {
            margin-top: 10%;
            h1 {
                color: $--white;
                font-size: 36px;
                font-weight: 800;
                text-transform: uppercase;
                line-height: 130%;
                margin: 0;
                span {
                    color: #1cffff;
                }
            }
            p {
                color: #fff;
                font-size: 16px;
                font-weight: 400;
                max-width: 336px;
            }
        }
        .right-circles {
            position: absolute;
            border-radius: 50%;
            align-items: center;
            color: $--white;
            text-align: center;
            h3 {
                font-family: "Nunito Sans", sans-serif;
                font-size: 38px;
                font-weight: 800;
                margin: 0;
            }
            .text {
                margin: 0;
                font-family: "Inter", sans-serif;
                font-size: 16px;
                font-weight: 800;
            }
        }
        .satisfied-customer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 150px;
            height: 150px;
            background-color: #756be866;
            opacity: 1;
            backdrop-filter: blur(5px);
            right: 18%;
            bottom: 10%;
        }
        .client-rate {
            padding-top: 30px;
            width: 170px;
            height: 170px;
            background: $--Main-Green-color;
            right: 9.5%;
            bottom: -8%;
        }
    }
}
@media (max-width: 1350px) {
    .about-us-main {
        padding: 40px;
        padding-top: 105px;
        .section-5 {
            .client-rate {
                right: 8%;
            }
        }
    }
}
@media (max-width: 1250px) {
    .about-us-main {
        .section-3{
            padding: 60px 30px;
            .images{
                gap: 30px;
            }
        }
        .section-5 {
            padding-left: 200px;
            .text {
                h1 {
                    font-size: 32px;
                }
                p {
                    font-size: 14px;
                }
                button {
                    font-size: 12px;
                    height: 36px;
                }
            }
            .right-circles {
                h3 {
                    font-size: 32px;
                }
                .text {
                    font-size: 12px;
                }
            }
            .satisfied-customer {
                height: 110px;
                width: 110px;
            }
            .client-rate {
                right: 9.5%;
                padding-top: 25px;
                height: 135px;
                width: 135px;
            }
        }
    }
}
@media (max-width: 1100px) {
    .about-us-main {
        .section-5 {
            .client-rate {
                right: 8.5%;
                padding-top: 25px;
                height: 135px;
                width: 135px;
            }
        }
    }
}
@media (max-width: 1000px){
    .about-us-main{
        padding-top: 95px;
        .header{
            .heading{
                font-size: 28px;
            }
        }
        .section-3{
            .details{
                .desc{
                    p{

                        font-size: 16px;
                    }
                }
            }
            .images{
                .image{
                    .text{
                        .bold{
                            font-size: 28px;
                        }
                        .desc{
                            font-size: 10px;
                        }
                    }
                }
            }
        }
        .section-5 {
            padding-left: 150px;
            .text {
                h1 {
                    font-size: 28px;
                }
                p {
                    font-size: 12px;
                    max-width: 300px;
                    margin-bottom: 6px;
                }
                button {
                    font-size: 12px;
                    height: 36px;
                }
            }
            .right-circles {
                h3 {
                    font-size: 28px;
                }
                .text {
                    font-size: 12px;
                }
            }
            .satisfied-customer {
                height: 100px;
                width: 100px;
            }
            .client-rate {
                right: 10%;
                padding-top: 20px;
                height: 110px;
                width: 110px;
            }
        }
    }
}
@media (max-width: 900px) {
    .about-us-main {
        .section-2{
            justify-content: flex-start;
            padding: 40px 16px;
            min-height: unset;
            img{
                display: none;
            }
            .desc{
                width: 100%;
            }
        }
        .section-5 {
            .client-rate {
                right: 8.5%;
            }
        }
    }
}
@media (max-width: 800px){
    .about-us-main{
        padding: 20px;
        padding-top: 95px;
        .header{
            .heading{
                font-size: 24px;
            }
        }
        .section-1{
            padding: 16px;
            padding-top: 30px;
            flex-direction: column;
            .title{
                p{
                    font-size: 20px;
                }
            }
            .desc{
                width: 100%;
                p{
                    font-size: 14px;
                }
            }
        }
        .section-3{
            padding: 40px 16px;
            flex-direction: column;
            align-items: center;
            .details{
                width: 100%;
            }
            .images{
                gap: 20px;
                align-self: unset;
                width: min(90%, 400px);
                .image{
                    width: 100%;
                }
                .second{
                    top: 0;
                }
                .third{
                    right: 0;
                }
                .fourth{
                    right: 0;
                    top: 0;
                }
            }
        }
        .section-4 {
            .side-image {
                width: min(100%, 400px);
            }
            .section-4-content {
                width: min(100%, 400px);
            }
        }
        .section-5 {
            padding-left: 100px;
            .text {
                h1 {
                    font-size: 18px;
                }
                p {
                    font-size: 10px;
                    max-width: 250px;
                    margin-bottom: 6px;
                }
                button {
                    font-size: 10px;
                    height: 30px;
                    padding: 0 12px;
                }
            }
            .right-circles {
                h3 {
                    font-size: 24px;
                }
                .text {
                    font-size: 10px;
                }
            }
            .satisfied-customer {
                height: 90px;
                width: 90px;
            }
            .client-rate {
                right: 8.5%;
                padding-top: 20px;
                height: 100px;
                width: 100px;
            }
        }
    }
}
@media (max-width: 700px) {
    .about-us-main {
        .section-5 {
            .client-rate {
                right: 6.1%;
            }
        }
    }
}
@media (max-width: 600px){
    .about-us-main{
        padding: 0px;
        padding-top: 95px;
        background-color: white;
        h2{
            padding: 10px;
        }
        .header{
            padding: 30px 0;
            .heading{
                font-size: 16px;
            }
        }
        .section-1{
            .title{
                p{
                    font-size: 16px;
                }
            }
            .desc{
                p{
                    font-size: 12px;
                }
            }
        }
        .section-3{
            padding: 30px 16px;
            .details{
                .title{
                    p{
                        font-size: 16px;
                    }
                }
                .desc{
                    p{
                        font-size: 12px;
                    }
                }   
            }
            .images{
                .image{
                    svg{
                        height: 40px;
                        width: 40px;
                    }
                    .text{
                        .bold{
                            font-size: 24px;
                        }
                    }
                }
            }
        }
        .section-2{
            padding: 30px 16px;
            .desc{
                .title{
                    p{
                        font-size: 16px;
                    }
                }
                .text{
                    font-size: 12px;
                }
            }
        }
        .section-4 {
            flex-direction: column;
            padding: 24px 16px;
            .side-image {
                max-width: 100%;
            }
            .section-4-content {
                max-width: 100%;
            }
        }
        .section-5 {
            background: url("../../Assets/Images/home-women-mobile.svg");
            background-size: cover;
            background-repeat: no-repeat;
            aspect-ratio: 360/498;
            max-height: 100vh;
            padding-left: 0px;
            align-items: flex-start;

            .text {
                padding: 30px;
                margin-top: 0%;
                h1 {
                    font-size: 36px;
                }
                p {
                    font-size: 16px;
                    max-width: 100vw;
                }
                button {
                    font-size: 14px;
                    height: 36px;
                }
            }
            .right-circles {
                h3 {
                    font-size: 20px;
                }
                .text {
                    font-size: 10px;
                    padding: 0;
                }
            }
            .satisfied-customer {
                width: 90px;
                height: 90px;
                right: 47%;
                bottom: 0%;
            }
            .client-rate {
                padding-top: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 110px;
                height: 110px;
                right: 77%;
                bottom: 15%;
            }
        }
    }
}
@media (max-width: 400px){
    .about-us-main{
        padding-top: 75px;
        .header{
            .heading{
                font-size: 14px;
            }
        }
        .section-5 {
            .text {
                padding: 30px;
                h1 {
                    font-size: 28px;
                }
                p {
                    font-size: 12px;
                }
                button {
                    font-size: 12px;
                    height: 32px;
                }
            }
            .client-rate {
                right: 65%;
            }
        }
    }
}