@import "/src/variables";
@import "/src/index.scss";

.freelancer-details-main {
    margin-top: 90px;
    background: #f8f8f8;
    padding: 50px;

    .details-container {
        background-color: white;
        border-radius: 8px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        border: 1px solid #E0E0E0;
        p {
            margin: 0;
        }
        .about-person {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .person-details {
                display: flex;
                align-items: center;
                gap: 8px;
                .image-container {
                    position: relative;
                    height: fit-content;
                    width: fit-content;
                    img {
                        height: 74px;
                        width: 74px;
                        border-radius: 50%;
                    }
                    .online-mark {
                        display: inline-block;
                        height: 10px;
                        width: 10px;
                        background: #219653;
                        position: absolute;
                        bottom: 6px;
                        right: 6px;
                        border-radius: 50%;
                    }
                }
                .details {
                    .name-container {
                        display: flex;
                        gap: 4px;
                        .name {
                            color: #373a3f;
                            font-size: 28px;
                            font-weight: 600;
                        }
                        .ratings {
                            display: flex;
                            align-items: center;

                            svg {
                                height: 20px;
                                width: 20px;
                            }
                            .filled {
                                path {
                                    fill: #f3c831;
                                }
                            }
                        }
                    }
                    .languages {
                        color: #373a3f;
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }
            .price-container {
                .price {
                    color: #373a3f;
                    font-size: 20px;
                    font-weight: 700;
                    text-align: center;
                    margin-bottom: 10px;
                }
                button {
                    font-size: 16px;
                }
            }
        }
        .mobile-chat-btn {
            display: none;
        }
        .person-info-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 16px;
            border-radius: 8px;
            border: 1px solid $--Gray-5;
            .about-user {
                display: flex;
                flex-direction: column;
                gap: 16px;
                .role {
                    color: #373a3f;
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 0px;
                }
                .about {
                    color: $--Gray-3;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            .item-container {
                .title {
                    color: $--Gray-2;
                    font-size: 14px;
                    font-weight: 500;
                    text-transform: uppercase;
                    margin-bottom: 6px;
                }
                .skills-container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 8px;
                    .skill {
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        border: 1px solid #5d01db;
                        border-radius: 4px;
                        background: rgba(239, 230, 251, 0.4);
                        padding: 4px 6px 4px 4px;
                        color: black;
                        font-size: 12px;
                        font-weight: 500;

                        cursor: pointer;
                        svg {
                            transform: rotate(45deg);
                            width: 20px;
                            height: 20px;
                            path {
                                fill: black;
                            }
                        }
                    }
                }
                video,
                iframe {
                    max-width: 100%;
                }
            }
            .user-experiences,
            .user-education {
                display: inline-flex;
                gap: 10px;
                flex-wrap: wrap;

                .experience,
                .education {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 270px;
                    min-height: 100px;
                    border: 1px solid $--Gray-6;
                    border-radius: 10px;
                    padding: 8px;

                    .details {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        .heading {
                            p {
                                color: #131a25;
                                font-size: 16px;
                                font-weight: 500;
                            }
                        }
                        p {
                            color: $--Gray-2;
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }
                    a{
                        font-size: 12px;
                    }
                }
            }
        }
        .history-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 16px;
            border-radius: 8px;
            border: 1px solid $--Gray-5;

            .user-review {
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 10px 16px;
                border-radius: 8px;
                border: 1px solid $--Gray-5;

                .ratings-container {
                    .name {
                        color: #373a3f;
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .ratings {
                        display: flex;
                        align-items: center;

                        svg {
                            height: 16px;
                            width: 16px;
                        }
                        .filled {
                            path {
                                fill: #f3c831;
                            }
                        }
                    }
                }
                .review {
                    color: $--Gray-3;
                    font-size: 12px;
                    font-weight: 400;
                }
                .client-name {
                    color: $--Gray-3;
                    font-size: 14px;
                    font-weight: 400;
                    span {
                        color: $--Gray-2;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .freelancer-details-main {
        margin-top: 80px;
    }
}

@media (max-width: 800px) {
    .freelancer-details-main {
        padding: 20px;
        .details-container {
            padding: 20px;
        }
    }
}

@media (max-width: 600px) {
    .freelancer-details-main {
        padding: 0px;
        .details-container {
            padding: 20px;
            gap: 12px;
            .about-person {
                .person-details {
                    .image-container {
                        img {
                            height: 50px;
                            width: 50px;
                        }
                        .online-mark {
                            right: 2px;
                            bottom: 2px;
                        }
                    }
                    .details {
                        .name-container {
                            .name {
                                font-size: 16px;
                            }
                            .ratings {
                                svg {
                                    height: 12px;
                                    width: 12px;
                                }
                            }
                        }
                        .languages {
                            font-size: 12px;
                        }
                    }
                }
                .price-container {
                    .price {
                        font-size: 16px;
                        margin-bottom: 6px;
                    }
                    button {
                        display: none;
                    }
                }
            }
            .mobile-chat-btn {
                display: block;
                width: 100%;
                border-radius: 4px;
            }
            .person-info-container {
                border: none;
                padding: 0;
                gap: 12px;

                .about-user {
                    gap: 4px;
                    .role {
                        font-size: 14px;
                    }
                    .about {
                        font-size: 12px;
                    }
                }
                .item-container {
                    .skills-container {
                        .skill {
                            font-size: 10px;
                        }
                    }
                    .user-experiences,
                    .user-education {
                        .experience,
                        .education {
                            min-height: 80px;
                            .details {
                                .heading {
                                    p {
                                        font-size: 12px;
                                    }
                                }
                                p {
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
            }
            .history-container {
                border: none;
                padding: 0;
                .user-review {
                    .ratings-container {
                        display: flex;
                        justify-content: space-between;
                        .name {
                            font-size: 12px;
                        }
                        .review {
                            font-size: 10px;
                        }
                    }
                    .client-name {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .freelancer-details-main {
        margin-top: 62px;
        .details-container{
            padding: 20px;
        }
    }
}
