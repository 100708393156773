@import "/src/variables";
@import "/src/index.scss";

.billing-name {
    padding: 40px;
    padding-top: 120px;
    background: #f8f8f8;
    min-height: 100vh;

    p{
        margin: 0;
    }
    .data-container {
        background: #FFF;
        border-radius: 8px;
        border: 1px solid #E4E9F2;
        padding: 20px;
        height: 100%;
        .heading {
            display: flex;
            border-radius: 8px 8px 0px 0px;
            border: none;
            border-bottom: 1px solid var(--Basic-400, #e4e9f2);
            text-align: center;
            span {
                display: flex;
                flex-direction: column;
                gap: 10px;
                cursor: pointer;
                p {
                    padding: 0px 20px;
                    color: #83848a;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .active {
                p {
                    color: $--Gray-1;
                }
                .underline {
                    height: 5px;
                    width: 100%;
                    border-radius: 8px 8px 0px 0px;
                    background: $--primary-color;
                }
            }
        }
    }
}

@media (max-width: 600px){
    .billing-name{
        padding: 0;
        padding-top: 80px;
        .data-container{
            .heading{
                span{
                    gap: 4px;
                    p{
                        font-size: 12px;
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: 400px){
    .billing-name{
        padding-top: 62px;
        .data-container{
            padding: 10px;
        }
    }
}