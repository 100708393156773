@import "/src/variables";
@import "/src/index.scss";

.brif-proposal-main{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 14px;
    width: min(700px, 95vw);
    p{
        margin: 0;
    }
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        p{
            color: #000;
            font-size: 24px;
            font-weight: 600;
        }
        svg{
            height: 30px;
            width: 30px;
            cursor: pointer;
            path{
                fill: #1C1B1F;
            }
        }
    }
    .content{
        p{
            font-size: 14px;
            font-weight: 400;
            span{
                color: #24262E;
                font-weight: 500;
            }
        }
        .text{
            color: $--Gray-3;
            margin-bottom: 8px;
        }
        .timing{
            color: $--Gray-2;
        }
        .cost{
            font-size: 16px;
        }
    }
    .buttons {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        align-self: flex-end;
        margin-top: 100px;

        .btn-blue {
            border-radius: 60px;
            background: $--primary-color;
            padding: 8px 20px;
            outline: none;
            border: none;
            cursor: pointer;
            color: white;
        }
    }
}