@import "/src/variables";
@import "/src/index.scss";

.job-post-step-2-main {
    .container {
        .input-grp {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 6px;
            .heading{
                h3{
                    color: #000;
                    font-size: 24px;
                    font-weight: 600;
                    margin-bottom: 4px;
                }
            }
            .bold {
                color: $--Gray-1;
                font-size: 16px;
                font-weight: 600;
            }
            .radio-container {
                display: flex;
                align-items: center;
                gap: 30px;
            }
            .radio-grp {
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;

                .my-radio {
                    display: grid;
                    place-items: center;
                    height: 20px;
                    width: 20px;
                    border-radius: 50px;
                    border: 1px solid #7d8187;
                    padding: 3px;

                    .select-mark {
                        height: 100%;
                        width: 100%;
                        display: inline-block;
                        border-radius: 50%;
                    }
                }
                .selected {
                    border-color: $--primary-color;
                    .select-mark {
                        background-color: $--primary-color;
                    }
                }
                p {
                    color: $--Gray-1;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
            .info{
                color: $--Gray-3;
                font-size: 12px;
                font-weight: 400;
            }
            .inputs{
                display: flex;
                flex-wrap: wrap;
                gap: 10px;

                input, select{

                    width: min(100%, 250px);
                    border-radius: 8px;
                    border: 1px solid
                        var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                    background: white;
                    padding: 12px;
                    // width: 100%;
                    height: 44px;
                    font-size: 14px;
                    font-weight: 500;
                }
                input:focus, select:focus {
                    outline: none;
                }
            }
        }
        .budget-input{
            .heading{
                h3{
                    color: #000;
                    font-size: 24px;
                    font-weight: 600;
                    margin-bottom: 4px;
                }
            }
            .input-grp{
                flex-direction: row;
                align-items: center;
                .input-container{
                    height: 40px;
                    display: flex;
                    align-items: center;
                    .symbol{
                        padding: 0px 12px 0px 12px;
                        border-radius: 8px 0px 0px 8px;
                        border: 1px solid var(--neutral-opacity-20, rgba(19, 26, 37, 0.20));
                        border-right: 1px solid var(--neutral-opacity-30, rgba(19, 26, 37, 0.30));
                        background: var(--L1-white, #FFF);
                        height: 100%;
                        display: inline-flex;
                        align-items: center;
                    }
                    input{
                        height: 100%;
                        padding: 0px 8px 0px 8px;
                        border-radius: 0px 8px 8px 0px;
                        border: 1px solid var(--neutral-opacity-20, rgba(19, 26, 37, 0.20));
                        border-left: none;
                        background: var(--L1-white, #FFF);
                        outline: none;
                        max-width: 110px;
                    }
                }
                hr{
                    width: 10px;
                    opacity: 1;
                }
            }
        }
    }
}

@media (max-width: 400px){
    .job-post-step-2-main{
        .container{
            .budget-input{
                .input-grp{
                    .input-container{
                        input{
                            width: 85px;
                            font-size: 14px;
                            
                        }
                    }
                }
            }
        }
    }
}