@import "/src/variables";
@import "/src/index.scss";

.step-4-main {
    .container {
        .input-grp {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .title, .bold {
                color: black;
                font-size: 24px;
                font-weight: 600;
            }
            .bold{
                font-size: 14px;
            }
            select {
                border-radius: 8px;
                border: 1px solid
                    var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                background: white;
                padding: 12px;
                width: 100%;
                height: 44px;
                font-size: 14px;
                font-weight: 500;
            }
            select:focus {
                outline: none;
            }
            .service-container {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 8px;
                border-radius: 8px;
                border: 1px solid var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                padding: 6px 12px;
                z-index: 100;
                .sc-dcJsrY {
                    min-width: 200px;
                    flex-grow: 100;
                    .wrapper {
                        border: none;
                        box-shadow: none;
                        height: 30px;
                        
                        .sc-gEvEer{
                            background: var(--L1-white, #FFF); 
                            width: 100%;
                        }
                    }
                    .wrapper:active {
                        box-shadow: none;
                        border: none;
                    }

                    .jVORrT {
                        display: none;
                    }
                    .clear-icon {
                        display: none;
                    }
                    input {
                        padding: 0;
                    }
                }
                .selected-service {
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    padding: 3px 4px 3px 6px;
                    border-radius: 4px;
                    background: #1c0042;
                    color: #fff;
                    font-weight: 500;
                    cursor: pointer;
                }
            }
            .suggested-services {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
                .suggested-service {
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    border: 1px solid rgba(158, 103, 233, 0.80);                    ;
                    border-radius: 24px;
                    background: rgba(239, 230, 251, 0.4);
                    padding: 4px 10px 4px 4px;
                    color: black;
                    font-size: 12px;
                    font-weight: 500;
                    cursor: pointer;
                    svg {
                        transform: rotate(45deg);
                        width: 20px;
                        height: 20px;
                        path {
                            fill: black;
                        }
                    }
                }
            }
        }
    }
}