@import "/src/variables";
@import "/src/index.scss";

.billing-details-form-main{
    display: flex;
    flex-direction: column;
    padding: 14px;
    width: min(500px, 95vw);
    p {
        margin: 0;
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            color: #000;
            font-size: 24px;
            font-weight: 600;
        }
        svg {
            height: 30px;
            width: 30px;
            cursor: pointer;
            path {
                fill: #1c1b1f;
            }
        }
    }
    .billing-info{
        margin-top: 20px;
        border-radius: 8px;
        border: 1px solid $--Gray-4;
        max-width: 600px;

        p{
            color: #131A25;
            font-size: 16px;
            font-weight: 500;
        }
        .billing-head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;
            border-bottom: 1px solid $--Gray-4;
            .input-grp{
                display: flex;
                align-items: center;
                gap: 10px;
                .my-radio{
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    padding: 4px;
                    border-radius: 50%;
                    border: 1px solid #7D8187;
                }
                .my-radio.checked{
                    border-color: $--primary-color;
                    display: grid;
                    align-items: center;
                    .selected{
                        display: inline-block;
                        height: 100%;
                        width: 100%;
                        background-color: $--primary-color;
                        border-radius: 50%;
                    }
                }
            }
            .icons{
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    height: 20px;
                }
            }
        }
        .billing-body{
            padding: 12px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            p{
                font-size: 12px;
            }

            .data-grp{
                display: flex;
                gap: 12px;
                .billing-data-container{
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    width: 100%;

                    p{
                        color: var(--Shades-Black, #131A25);
                        font-size: 12px;
                        font-weight: 700;
                    }

                    div{
                        display: flex;
                        justify-content: space-between;

                        align-items: center;
                        border: 1px solid var(--neutral-opacity-20, rgba(19, 26, 37, 0.20));
                        border-radius: 8px;
                        height: 48px;
                        padding: 0 12px;
                        input{
                            color: $--Gray-2;
                            font-size: 14px;
                            width: 100%;
                            outline: none;
                            border: none;
                        }
                    }
                }
            }
            .razorpay-info{
                color: #000;
                font-size: 16px;
                font-weight: 500;
            }
            .primary-btn-blue{
                width: 112px;
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 400px) {
    .billing-details-form-main{
        .billing-info{
            .billing-body{
                .data-grp{
                    .billing-data-container{
                        .date{
                            max-width: 150px;
                        }
                    }
                }
            }
        }
    }
}