@import "/src/variables";
@import "/src/index.scss";

.find-freelancer-main {
    display: flex;
    gap: 50px;
    padding: 20px;
    background-color: #f8f8f8;
    padding-top: 120px;

    height: 100vh;
    .back-drop{
        height: 100vh;
        width: 100vw;
        background-color: #000000AA;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
    }
    p {
        margin: 0;
    }

    .find-freelancer-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        // padding-right: 50px;
        max-width: 1000px;

        .heading-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            .heading {
                color: #000;
                font-size: 24px;
                font-weight: 600;
            }
            .sort-by{
                cursor: pointer;
                background: #fff;
                padding: 5px 10px;
                border-radius: 8px;
                border: 1px solid #E0E0E0;
            }
            span {
                span {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
            .filter-icon {
                display: none;
            }
            .options {
                position: absolute;
                display: flex;
                flex-direction: column;
                gap: 4px;
                top: 35px;
                right: 0px;
                border-radius: 8px;
                z-index: 2;
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
                background-color: white;
                padding: 8px 0;
                span {
                    padding: 2px 8px;
                    cursor: pointer;
                    color: #131a25;
                    font-size: 14px;
                    font-weight: 500;
                }
                span:hover {
                    background: linear-gradient(
                                to bottom right,
                                #5d01db 0%,
                                #3d008f 26%,
                                #2e0369 39%,
                                #210942 49%
                            )
                            bottom right / 50% 50% no-repeat,
                        linear-gradient(
                                to bottom left,
                                #5d01db 0%,
                                #3d008f 26%,
                                #2e0369 39%,
                                #210942 49%
                            )
                            bottom left / 50% 50% no-repeat,
                        linear-gradient(
                                to top left,
                                #5d01db 0%,
                                #3d008f 26%,
                                #2e0369 39%,
                                #210942 49%
                            )
                            top left / 50% 50% no-repeat,
                        linear-gradient(
                                to top right,
                                #5d01db 0%,
                                #3d008f 26%,
                                #2e0369 39%,
                                #210942 49%
                            )
                            top right / 50% 50% no-repeat;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
        .freelancer-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            overflow: auto;
            // padding: 20px;pa
            padding-right: 10px;
            .freelancer {
                border-radius: 10px;
                background: #fff;
                padding: 20px;
                border: 1px solid #E0E0E0;
                .about-freelancer {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 12px;
                    border-bottom: 1px solid $--Gray-5;
                    .about-container {
                        display: flex;
                        gap: 10px;
                        .image-container {
                            position: relative;
                            height: fit-content;
                            img {
                                height: 50px;
                                width: 50px;
                                border-radius: 50%;
                            }
                            .online-mark {
                                display: inline-block;
                                height: 10px;
                                width: 10px;
                                background: #219653;
                                position: absolute;
                                bottom: 2px;
                                right: 2px;
                                border-radius: 50%;
                            }
                        }
                        .ratings-container {
                            .name {
                                color: #373a3f;
                                font-size: 18px;
                                font-weight: 600;
                                display: flex;
                                align-items: center;
                                gap: 10px;
                                .top-rated, .account-verified {
                                    padding: 2px 4px;
                                    display: inline-flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-wrap: nowrap;
                                    gap: 2px;
                                    background: #1c0067;
                                    border-radius: 4px;

                                    span {
                                        color: #fff;
                                        font-size: 9px;
                                        font-weight: 500;
                                        white-space: nowrap;
                                    }
                                }
                                .account-verified{
                                    background: $--Dark-Green-color;
                                }
                            }
                            .ratings {
                                display: flex;
                                align-items: center;

                                svg {
                                    height: 16px;
                                    width: 16px;
                                }
                                .filled {
                                    path {
                                        fill: #f3c831;
                                    }
                                }
                            }
                        }
                    }
                    .fav-icon {
                        // position: absolute;
                        // top: 10px;
                        // right: 30px;
                        cursor: pointer;
                        z-index: 1;
                    }
                    .fav-icon.filled {
                        path {
                            fill: rgb(255, 52, 52);
                        }
                    }
                }
                .freelancer-skills-container {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding-top: 12px;
                    .skills-container {
                        .category {
                            color: $--Gray-2;
                            font-size: 12px;
                            font-weight: 400;
                            margin-bottom: 6px;
                        }
                        .skills {
                            color: #24262e;
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                    .button-container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        button {
                            width: 110px;
                            font-size: 12px;
                            font-weight: 500;
                        }
                        .price {
                            color: #373a3f;
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        .pagination {
            margin: 0px auto;
            .MuiPaginationItem-previousNext {
                background-color: white;
                filter: drop-shadow(0px 1px 4px rgba(24, 26, 32, 0.07));
            }
            .Mui-selected {
                color: white;
                background-color: $--primary-color;
            }
        }
    }
}
@media (max-width: 1000px) {
    .find-freelancer-main {
        width: 100%;
        .filters {
            display: none;
        }
        .find-freelancer-container {
            padding-right: unset;
            .heading-container {
                span {
                    display: none;
                }
                .filter-icon {
                    display: block;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .find-freelancer-main {
        .find-freelancer-container {
            padding: 20px;
            .freelancer-container {
                grid-template-columns: 1fr;
            }
        }
    }
}
@media (max-width: 600px) {
    .find-freelancer-main {
        padding: 0;
        padding-top: 100px;
        .find-freelancer-container {
            padding: 20px;
            gap: 10px;
            .heading-container {
                .heading {
                    font-size: 20px;
                }
            }
            .freelancer-container {
                gap: 10px;
                .freelancer {
                    padding: 10px;
                    .about-freelancer {
                        padding-bottom: 6px;
                        .about-container {
                            .image-container {
                                img {
                                    height: 32px;
                                    width: 32px;
                                }
                                .online-mark {
                                    height: 8px;
                                    width: 8px;
                                }
                            }
                            .ratings-container {
                                .name {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    .freelancer-skills-container {
                        padding-top: 6px;
                        gap: 12px;
                        .skills-container {
                            .category {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .find-freelancer-main {
        padding-top: 75px;

        .find-freelancer-container{
            .pagination {
                // nav{
                //     // width: 100vw;
                // }
                margin: 20px auto;
                .MuiPagination-ul{
                    flex-wrap: nowrap;
                    transform: scale(0.8);
                    margin: auto;
                    justify-content: center;
                    text-align: center;
                }
                .MuiPaginationItem-previousNext {
                    background-color: white;
                    filter: drop-shadow(0px 1px 4px rgba(24, 26, 32, 0.07));
                }
                .Mui-selected {
                    color: white;
                    background-color: $--primary-color;
                }
            }
        }
    }
}
