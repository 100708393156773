@import "/src/variables";
@import "/src/index.scss";

.introduction-main {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        background: #fff;
        border: 1px solid #eceded;
        padding: 60px;
        display: flex;
        flex-direction: column;
        width: fit-content;
        border-radius: 12px;

        .title {
            color: #000;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        .text-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .points{
                .point{
                    display: flex;
                    gap: 8px;
                }
            }
        }
        .next-action{
            display: flex;
            align-items: center;
            gap: 16px;
            .primary-btn-blue{
                width: 150px;
                font-size: 14px;
                font-weight: 500;
            }
            p{
                width: 253px;
                color: $--Gray-3;
                font-size: 12px;
                font-weight: 400;
                
            }
        }
    }
}

@media (max-width: 1000px) {
    .introduction-main {
        .container {
            padding: 30px;
        }
    }
}

@media (max-width: 800px) {
    .introduction-main {
        .container {
            padding: 20px;
            width: 100%;
            height: 100%;
            .text-container{
                flex-direction: column;
                align-items: flex-start;

                img{
                    max-width: 350px;
                    align-self: center;
                }
                margin-bottom: 24px;
            }
            .next-action{
                flex-direction: column-reverse;
                align-items: flex-start;
                justify-content: space-between;
                height: 100%;
                p{
                    width: 100%;
                }
                .primary-btn-blue{
                    align-self: flex-end;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .introduction-main {
        width: 100vw;
    }
}
