@import "/src/variables";
@import "/src/index.scss";

.chat-main{
    padding: 60px;
    padding-top: 120px;
    background: #F8F8F8;
    border-radius: 8px;
    border: 1px solid $--Gray-5;;
    display: flex;
    height: 100vh;

    p{
        margin: 0;
    }
}
.mobile-chat-main{
    display: none;
}

@media (max-width: 1300px){
    .chat-main{
        padding: 60px 30px;
        padding-top: 120px;
    }
}

@media (max-width: 1000px){
    .desktop-chat-main{
        display: none;
    }
    .mobile-chat-main{
        display: block;
    }
}

@media (max-width: 800px){
    .chat-main{
        padding: 0;
        padding-top: 80px;
        height: calc(100vh - 80px );

    }
}
@media (max-width: 400px){
    .chat-main{
        padding: 0;
        padding-top: 60px;
        // height: calc(100vh - 60px );
    }
}