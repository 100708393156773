@import "/src/variables";
@import "/src/index.scss";

.my-jobs-main {
    padding: 30px;
    padding-top: 100px;
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;

    p {
        margin: 0;
    }

    .content {
        h3 {
            color: #000;
            font-size: 28px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        .main-container {
            background-color: white;
            border-radius: 8px;
            border: none;
            .heading {
                display: flex;
                flex-wrap: wrap;
                border-radius: 8px 8px 0px 0px;
                border: none;
                border-bottom: 1px solid var(--Basic-400, #e4e9f2);
                text-align: center;
                span {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    cursor: pointer;
                    p {
                        padding: 0px 20px;
                        margin-top: 15px;
                        color: $--Gray-1;
                        font-size: 16px;
                        font-weight: 700;
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        span {
                            color: #212121;
                            font-size: 11px;
                            font-weight: 500;
                            padding: 3px 3px 2px 3px;
                            border-radius: 4px;
                            background: #eef0f2;
                        }
                    }
                }
                .active {
                    p {
                        span {
                            background-color: #f1f7fd;
                            color: #1c43b9;
                        }
                    }
                    .underline {
                        height: 5px;
                        width: 100%;
                        border-radius: 8px 8px 0px 0px;
                        background: $--primary-color;
                    }
                }
            }
            .job-container-parent{
                min-height: 400px;
                position: inherit;
            //     display: grid;
            //     place-content: center;
            //     width: 100%;

                .empty-message{
                    position: absolute;
                    top: 50%;
                }
            }
            .job-container {
                padding: 30px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                height: 100%;
                width: 100%;
                .job {
                    display: flex;
                    gap: 16px;
                    border-radius: 12px;
                    border: 1px solid var(--Gray-5, #e0e0e0);
                    padding: 20px;
                    position: relative;

                    .job-tag {
                        position: absolute;
                        top: 0;
                        right: 0;
                        border-radius: 0px 12px 0px 12px;
                        background: $--Gray-5;
                        padding: 4px 16px;
                        color: $--Gray-2;
                        font-size: 11px;
                        font-weight: 500;
                    }
                    .id {
                        color: #000;
                        font-size: 16px;
                        font-weight: 500;
                    }
                    .details-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .details {
                            .name {
                                color: $--Gray-1;
                                font-size: 16px;
                                font-weight: 500;
                                span {
                                    color: $--Gray-4;
                                    font-size: 16px;
                                    font-weight: 500;
                                    margin: 0 6px;
                                }
                            }
                            .other-details {
                                color: $--Gray-3;
                                font-size: 14px;
                                font-weight: 400;
                                span {
                                    color: $--Gray-1;
                                }
                            }
                        }
                        .buttons{
                            display: flex;
                            gap: 8px;
                        }
                        .btn-white-outline {
                            border-radius: 4px;
                            color: $--primary-color;
                            width: fit-content;
                            margin-top: 14px;
                            padding: 6px 16px;
                        }
                    }
                }
                .job.danger {
                    border-color: #eb5757;
                    .job-tag {
                        background: var(--Red, #eb5757);
                        color: #fff;
                    }
                }
                .job.normal {
                    .job-tag {
                        background: $--primary-color;
                        color: #fff;
                    }
                }
            }
        }
    }
    .pagination {
        margin-top: 30px;
        margin-bottom: 30px;
        align-self: flex-end;
        .MuiPaginationItem-previousNext {
            background-color: white;
            filter: drop-shadow(0px 1px 4px rgba(24, 26, 32, 0.07));
        }
        .Mui-selected {
            color: white;
            background-color: $--primary-color;
        }
    }
}
@media (max-width: 1000px) {
    .my-jobs-main {
        .main-container {
            .heading {
                span {
                    p {
                        padding: 0 10px;
                    }
                }
            }
            .job-container {
                grid-template-columns: 1fr;
            }
        }
    }
}
@media (max-width: 800px) {
    .my-jobs-main {
        .content {
            .main-container {
                .heading {
                    padding-left: 20px;
                    flex-wrap: nowrap;
                    overflow: auto;
                    span {
                        p {
                            padding: 0 10px;
                            font-size: 12px;

                            span {
                                font-size: 8px;
                            }
                        }
                    }
                }
                .job-container {
                    padding: 20px;
                }
            }
        }
    }
}
@media (max-width: 600px) {
    .my-jobs-main {
        padding: 0;
        padding-top: 80px;
        background-color: white;
        min-height: calc(100vh - 80px);

        .content {
            h3 {
                display: none;
            }
            .main-container {
                .heading {
                    padding-left: 20px;
                    // flex-wrap: wrap;
                    span {
                        p {
                            padding: 0 10px;
                            font-size: 12px;

                            span {
                                font-size: 8px;
                            }
                        }
                    }
                }
                .id {
                    font-size: 12px;
                }
                .job-container {
                    padding: 20px;
                    grid-template-columns: 1fr;
                    .job {
                        padding: 10px;
                        .details-container {
                            .details {
                                .name {
                                    font-size: 12px;
                                }
                                .other-details {
                                    font-size: 10px;
                                }
                            }
                            .btn-white-outline {
                                font-size: 10px;
                            }
                        }
                        .job-tag {
                            bottom: 0;
                            top: unset;
                            border-radius: 12px 0px 12px 0px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 400px) {
    .my-jobs-main {
        padding: 0;
        padding-top: 60px;
        background-color: white;
        min-height: calc(100vh - 60px);
        .content {
            h3 {
                display: none;
            }
            .main-container {
                .heading {
                    padding-left: 20px;
                    // flex-wrap: wrap;
                    span {
                        p {
                            padding: 0 10px;
                            font-size: 12px;

                            span {
                                font-size: 8px;
                            }
                        }
                    }
                }
                .job-container {
                    padding: 20px;
                    .job {
                        padding: 10px;
                    }
                }
            }
        }
        .pagination {
            nav {
                width: 100vw;
            }
            margin: 20px auto;
            .MuiPagination-ul {
                flex-wrap: nowrap;
                transform: scale(0.8);
                margin: auto;
                justify-content: center;
                text-align: center;
            }
            .MuiPaginationItem-previousNext {
                background-color: white;
                filter: drop-shadow(0px 1px 4px rgba(24, 26, 32, 0.07));
            }
            .Mui-selected {
                color: white;
                background-color: $--primary-color;
            }
        }
    }
}
