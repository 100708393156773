.empty-page-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffff;
    flex-direction: column;
    padding: 40px;

    img{
        width: fit-content;
        height: 100%;
        max-height: 500px;
    }
    span{
        font-size: 18px;
        font-weight: 600;
    }
}