@import "/src/variables";
@import "/src/index.scss";

.job-post-step-4-main {
    p{
        margin: 0;
    }
    .container {
        gap: 20px !important;
        .content{
            border: 1px solid $--Gray-5;
            border-radius: 8px;
            width: 100%;
            .head{
                padding: 14px 16px;
                border-bottom: 1px solid $--Gray-5;
                p{
                    font-size: 18px;
                    font-weight: 500;
                    color: $--Gray-1;
                }
            }
            .body{
                padding: 14px 16px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                .grp{
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    .bold{
                        font-size: 14px;
                        font-weight: 600;
                        color: $--Gray-1;
                    }
                    p.text-content{
                        font-size: 12px;
                        font-weight: 500;
                        color: $--Gray-3;
                    }
                    p.capitalize{
                        text-transform: capitalize;
                    }
                }
            }
        }
        .attachment{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            padding: 8px 12px;
            border-radius: 4px;
            border: 1px solid var(--Basic-600, $--Gray-5);
            margin-bottom: 4px;
            width: fit-content;
            min-width: 250px;
            input{
                display: none;
            }
            img{
                height: 20px;
                width: 20px;
            }
            span{
                display: flex;
                gap: 8px;
                align-items: center;
                font-size: 12px;
            }
            .delete-icon{
                height: 16px;
                width: 16px;
            }
        }
    }
}