@import "/src/variables";
@import "/src/index.scss";

.step-2-main {
    .experience-container{
        display: inline-flex;
        gap: 22px;
        flex-wrap: wrap;

        .experience{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 230px;
            min-height: 130px;
            border: 1px solid rgba(93, 1, 219, 0.50);
            border-radius: 10px;
            
            .details{
                display: flex;
                flex-direction: column;
                gap: 12px;
                .heading{
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    padding: 6px 14px 6px 5px;
                    border-radius: 4px 0px 12px 0px;
                    background: rgba(223, 204, 248, 0.40);
                    width: fit-content;
                }
                p{
                    padding: 0px 8px;
                    color: $--Gray-2;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
            .icons{
                align-self: flex-end;
                justify-self: flex-end;
                gap: 8px;
                margin-bottom: 8px;
                
                svg{
                    height: 22px;
                    width: 22px;
                    margin-right: 8px;
                    cursor: pointer;
                }
            }
        }
        .add-experience{
            display: flex;
            align-items: center;
            padding: 34px 30px;
            gap: 8px;
            border-radius: 10px;
            border: 1px dashed rgba(93, 1, 219, 0.50);
            cursor: pointer;

            p{
                color: var(--Neutral-400, #131A25);
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
}
