@import "/src/variables";
@import "/src/index.scss";

.select-role-main{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .container{
        background: #FFF;
        border: 1px solid #ECEDED;
        padding: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        width: fit-content;
        border-radius: 12px;
        scale: 0.9;
        height: fit-content;


        .text{
            text-align: center;
            .title{
                color: #000;
                font-size: 38px;
                font-weight: 600;
            }
            .desc{
                color: var(--Neutral-300, #484E56);
                font-size: 20px;
                font-weight: 500;
                
            }
        }

        .options{
            display: flex;
            gap: 30px;
            .option{
                padding: 30px;
                display: flex;
                align-items: center;
                gap: 24px;
                max-width: 360px;
                border-radius: 8px;
                border: 1px solid rgba(93, 1, 219, 0.50);
                cursor: pointer;

                .icon-container{
                    background: #EFE6FB;
                    min-height: 80px;
                    min-width: 80px;
                    border-radius: 20px;
                    display: grid;
                    place-items: center;

                    svg{
                        width: 52px;
                        height: 52px;
                    }

                }
                p{
                    color: var(--Neutral-400, #131A25);
                    font-size: 24px;
                    font-weight: 500;
                }
            }
            .option.active{
                border: 1px solid var(--Gradient, #5D01DB);
                background: rgba(93, 1, 219, 0.05);
                
                .icon-container{
                    background: #5D01DB26;
                }
                p{
                    background: var(--Gradient, linear-gradient(90deg, #5D01DB 1.88%, #210942 124.67%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        .next-action{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 15px;
        
            p{
                color: #484E56;
                font-size: 14px;
                font-weight: 500;
                margin-top: 8px;

                span{
                    color: #5D01DB;
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-width: 1000px){
    .select-role-main{
        .container{
            padding: 30px;

            .options{
                .option{
                    padding: 20px;
                    gap: 16px;

                    p{
                        font-size: 22px;
                    }
                }
            }
        }
    }
}

@media (max-width: 800px){
    .select-role-main{
        .container{
            padding: 20px;
            width: 100%;
            // height: 100%;
        // height: fit-content;

            .options{
                flex-direction: column;
                .optoin{
                    padding: 30px;
                    gap: 20px;
                    p{
                        font-size: 24px;
                    }
                }
            }
        }
    }
}


@media (max-width: 600px){
    .select-role-main{
        width: 100vw;
        .container{
            .text{
                .title{
                    font-size: 32px;
                }
            }

            .options{
                .option{
                    padding: 10px;
                    gap: 16px;
                    width: 100%;

                    .icon-container{
                        transform: scale(0.9);
                    }

                    p{
                        font-size: 20px;
                    }
                }
            }
        }
    }
}