@import "/src/variables";
@import "/src/index.scss";

.profile-main {
    margin-top: 90px;
    padding: 60px;
    background: #f8f8f8;

    p {
        margin: 0;
    }

    .edit-icon {
        cursor: pointer;
    }

    .profile-container {
        padding: 28px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        background: #fff;
        border-radius: 8px;

        .profile-image-controler {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .image-name-container {
                display: flex;
                align-items: center;
                gap: 8px;
                .image-container {
                    position: relative;
                    cursor: pointer;
                    input {
                        display: none;
                    }
                    img {
                        height: 74px;
                        width: 74px;
                        border-radius: 74px;
                        border: 3.469px solid #fff;
                    }
                    .edit-icon {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        cursor: pointer;
                    }
                }
                .personal-details{
                    .large {
                        color: #373a3f;
                        font-size: 28px;
                        font-weight: 600;
                        line-height: 100%;
                    }
                    p{
                        font-size: 14px;
                        color: #373a3f;
                    }
                }
                .online-tag {
                    padding: 4px 16px;
                    border-radius: 20px;
                    background: #e9f1e7;
                    color: #219653;
                    font-size: 14px;
                    font-weight: 600;
                    // margin-left: 10px;
                    align-self: flex-start;
                }
            }
            .btn-blue {
                height: fit-content;
                border-radius: 60px;
                background: $--primary-color;
                padding: 8px 20px;
                outline: none;
                border: none;
                cursor: pointer;
                color: white;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
    .info-container {
        border-radius: 8px;
        border: 1px solid $--Gray-5;
        .info-head {
            display: flex;
            align-items: center;
            gap: 10px;
            border-bottom: 1px solid $--Gray-5;
            padding: 16px;
            .info-title {
                color: #373a3f;
                font-size: 20px;
                font-weight: 600;
            }
        }
        .info{
            padding: 16px;
            width: 100%;
        }
    }
    .education-history, .work-history{
        .info-head{
            cursor: pointer;
            justify-content: space-between;
            svg{
                cursor: pointer;
                transform: rotate(0deg);
            }
            .expanded{
                transform: rotate(180deg);
            }
        }
    }
    .role,.rates{
        .info-head{
            border-bottom: none;
        }
    }
    .skills{
        .skills-container {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            .skill {
                display: flex;
                align-items: center;
                gap: 2px;
                border: 1px solid #5d01db;
                border-radius: 4px;
                background: rgba(239, 230, 251, 0.4);
                padding: 4px 6px 4px 4px;
                color: black;
                font-size: 12px;
                font-weight: 500;
                cursor: pointer;
                svg {
                    transform: rotate(45deg);
                    width: 20px;
                    height: 20px;
                    path {
                        fill: black;
                    }
                }
            }
        }
    }
    .bio{
        p{
            color: $--Gray-3;
            font-size: 16px;
            font-weight: 400;
        }
    }
    .experience-container, .education-container{
        display: inline-flex;
        gap: 10px;
        flex-wrap: wrap;

        .experience, .education{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 230px;
            min-height: 100px;
            border: 1px solid $--Gray-5;
            border-radius: 10px;
            padding: 8px;
            
            .details{
                display: flex;
                flex-direction: column;
                gap: 4px;
                .heading{
                    p{
                        color: #131A25;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
                p{
                    padding: 0px 8px;
                    color: $--Gray-2;
                    font-size: 12px;
                    font-weight: 500;
                }
                a{
                    padding: 0px 8px;
                    font-size: 12px;
                }
            }
            .icons{
                align-self: flex-end;
                justify-self: flex-end;
                margin-bottom: 8px;
                
                svg{
                    height: 22px;
                    width: 22px;
                    margin-right: 8px;
                    cursor: pointer;
                }
            }
        }
        .add-experience, .add-education{
            display: flex;
            align-items: center;
            padding: 20px;
            gap: 8px;
            border-radius: 10px;
            border: 1px solid $--Gray-5;
            cursor: pointer;

            svg{
                height: 50px;
                width: 50px;
                fill: $--Gray-5;
                transform: rotate(45deg);
                path{
                    fill: $--Gray-5;
                }
            }
        }
    }
    .details{
        p{
            color: $--Gray-3;
            font-size: 16px;
            color: $--Gray-3;
        }
        span{
            color: #373a3f;
        }
    }
    .portfolio{
        iframe, video, .portfolio-img{
            max-width: 100%;
        }
        .attachment {
            border: 1px solid
                var(--Gray-5, #e0e0e0);
            border-radius: 4px;
            padding: 10px;
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            cursor: pointer;

            img {
                height: 20px;
            }
            p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                color: var(
                    --L1-ref-secondary-secondary20,
                    #47484b
                );
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

@media (max-width: 800px) {
    .profile-main {
        padding: 30px;
        .profile-container {
            padding: 20px;
            .profile-image-controler {
                gap: 8px;
                .image-name-container {
                    .image-container {
                        img {
                            height: 60px;
                            width: 60px;
                        }
                        .edit-icon {
                            height: 22px;
                            width: 22px;
                        }
                    }
                    p {
                        font-size: 24px;
                    }
                    .online-tag {
                        font-size: 12px;
                    }
                }
                .btn-blue {
                    align-self: flex-end;
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .profile-main {
        .profile-container {
            .profile-image-controler {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}

@media (max-width: 600px) {
    .profile-main {
        margin-top: 80px;
        padding: 20px;
        .profile-container {
            .profile-image-controler {
                .image-name-container {
                    .image-container {
                        margin-right: 8px;
                        img {
                            height: 50px;
                            width: 50px;
                        }
                        .edit-icon {
                            height: 18px;
                            width: 18px;
                        }
                    }
                    p {
                        font-size: 20px;
                    }
                }
            }
        }
        .info-container {
            .info-head {
                padding: 12px;
                .info-title {
                    font-size: 16px;
                }
                svg {
                    height: 22px;
                    width: 22px;
                }
            }
            .info{
                padding: 12px;
            }
            .experience-container, .education-container{
                .experience, .education{
                    .details{
                        .heading{
                            p{
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .details{
                p{
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .profile-main {
        margin-top: 62px;
        padding: 0;
        .profile-container {
            .profile-image-controler {
                .image-name-container {
                    .image-container {
                        img {
                            height: 50px;
                            width: 50px;
                        }
                        .edit-icon {
                            height: 18px;
                            width: 18px;
                        }
                    }
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
