@import "/src/variables";
@import "/src/index.scss";

.client-profile-main {
    padding: 60px;
    padding-top: 150px;
    background: #f8f8f8;
    min-height: 100vh;
    height: 100vh;

    p {
        margin: 0;
    }

    .edit-icon {
        cursor: pointer;
    }

    .profile-container {
        padding: 28px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        background: #fff;
        border-radius: 8px;
        height: 100%;

        .profile-image-controler {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .image-name-container {
                display: flex;
                align-items: center;
                gap: 8px;
                .image-container {
                    position: relative;
                    cursor: pointer;
                    input {
                        display: none;
                    }
                    img {
                        height: 74px;
                        width: 74px;
                        border-radius: 74px;
                        border: 3.469px solid #fff;
                    }
                    .edit-icon {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        cursor: pointer;
                    }
                }
                .personal-details {
                    .large {
                        color: #373a3f;
                        font-size: 28px;
                        font-weight: 600;
                        line-height: 100%;
                    }
                }
                .online-tag {
                    padding: 4px 16px;
                    border-radius: 20px;
                    background: #e9f1e7;
                    color: #219653;
                    font-size: 14px;
                    font-weight: 600;
                    // margin-left: 10px;
                }
            }
            .btn-blue {
                height: fit-content;
                border-radius: 60px;
                background: $--primary-color;
                padding: 8px 20px;
                outline: none;
                border: none;
                cursor: pointer;
                color: white;
                font-size: 16px;
                font-weight: 500;
            }
        }
        .personal-info-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            border-radius: 8px;
            padding: 16px;
            border: 1px solid $--Gray-5;
            .title {
                color: #373a3f;
                font-size: 20px;
                font-weight: 600;
            }
            .input-grp {
                display: flex;
                flex-direction: column;
                gap: 4px;
                width: 50%;

                .phone {
                    display: flex;
                    height: 100%;
                    border: 1px solid
                        var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                    border-radius: 8px;

                    .ReactFlagsSelect-module_flagsSelect__2pfa2 {
                        width: 130px;
                        height: 44px;
                        border-right: 1px solid
                            var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));

                        .ReactFlagsSelect-module_selectBtn__19wW7 {
                            padding: 12px 0px;
                            height: 44px;
                            border: none;
                            border-radius: 0px;
                            width: 100%;

                            .ReactFlagsSelect-module_label__27pw9 {
                                font-size: 14px;
                                font-weight: 700;
                            }
                        }
                        .ReactFlagsSelect-module_selectBtn__19wW7::after {
                            display: none;
                        }
                    }
                    .phone_input {
                        width: calc(100% - 95px);
                        border: none;
                    }
                }
                .bold {
                    color: $--Gray-1;
                    font-size: 12px;
                    font-weight: 600;
                }
                .note {
                    font-size: 11px;
                    text-align: right;
                }
                .radio-container {
                    display: flex;
                    align-items: center;
                    gap: 30px;
                    .radio-grp {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        cursor: pointer;

                        .my-radio {
                            display: grid;
                            place-items: center;
                            height: 20px;
                            width: 20px;
                            border-radius: 50px;
                            border: 1px solid #7d8187;
                            padding: 3px;

                            .select-mark {
                                height: 100%;
                                width: 100%;
                                display: inline-block;
                                border-radius: 50%;
                            }
                        }
                        .selected {
                            border-color: $--primary-color;
                            .select-mark {
                                background-color: $--primary-color;
                            }
                        }
                        p {
                            color: $--Gray-1;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }

                input {
                    border-radius: 8px;
                    border: 1px solid
                        var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                    background: white;
                    padding: 12px;
                    height: 44px;
                    font-size: 14px;
                    font-weight: 500;
                }
                input:focus {
                    outline: none;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .client-profile-main {
        padding: 30px;
        padding-top: 120px;
        .profile-container {
            padding: 20px;
            .profile-image-controler {
                gap: 8px;
                .image-name-container {
                    .image-container {
                        img {
                            height: 60px;
                            width: 60px;
                        }
                        .edit-icon {
                            height: 22px;
                            width: 22px;
                        }
                    }
                    p {
                        font-size: 24px;
                    }
                    .online-tag {
                        font-size: 12px;
                    }
                }
                .btn-blue {
                    align-self: flex-end;
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .client-profile-main {
        .profile-container {
            .profile-image-controler {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}

@media (max-width: 600px) {
    .client-profile-main {
        padding: 20px;
        padding-top: 100px;
        .profile-container {
            .profile-image-controler {
                .image-name-container {
                    .image-container {
                        margin-right: 8px;
                        img {
                            height: 50px;
                            width: 50px;
                        }
                        .edit-icon {
                            height: 18px;
                            width: 18px;
                        }
                    }
                    p {
                        font-size: 20px;
                    }
                }
            }
            .personal-info-container{
                .input-grp{
                            width: 100%;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .client-profile-main {
        padding: 0;
        padding-top: 62px;
        .profile-container {
            .profile-image-controler {
                .image-name-container {
                    .image-container {
                        img {
                            height: 50px;
                            width: 50px;
                        }
                        .edit-icon {
                            height: 18px;
                            width: 18px;
                        }
                    }
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
