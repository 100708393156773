@import "/src/variables";
@import "/src/index.scss";

.membership-plans-main {
    padding: 30px;
    padding-top: 120px;
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-x: hidden;
    p {
        margin: 0;
    }

    .note {
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid var(--Yellow, #f2c94c);
        background: #fdf1cb;
        padding: 16px 0px 16px 16px;
    }
    .desktop-plans-container {
        display: flex;
        justify-content: center;
        gap: 30px;
        padding: 45px 60px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid var(--Basic-400, #e4e9f2);
    }
    .plans-container {
        .plan {
            display: flex;
            flex-direction: column;
            gap: 16px;
            border-radius: 10px;
            border: 1px solid #e0e6ed;
            padding: 24px;

            .price {
                display: flex;
                align-items: center;
                justify-content: center;
                h3 {
                    color: $--Dark-blue-2;
                    font-size: 50px;
                    font-weight: 800;
                }
                p {
                    font-size: 20px;
                    font-weight: 600;
                }
            }
            .basic-details {
                .title {
                    color: $--Gray-1;
                    font-size: 18px;
                    font-weight: 600;
                }
                .desc {
                    color: $--Gray-2;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
            .features-container {
                .bold {
                    color: $--Gray-1;
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }
                .features {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    .feature {
                        display: flex;
                        gap: 8px;
                        align-items: center;
                        color: $--Gray-2;
                        font-size: 14px;
                        font-weight: 400;
                        svg {
                            height: 16px;
                            width: 16px;
                            path {
                                fill: #219653;
                            }
                        }
                    }
                }
            }
            .btn-white-outline {
                width: 100%;
                border-radius: 8px;
                padding: 18px 0;
                font-size: 16px;
                font-weight: 600;
                background: var(
                    --Gredient,
                    linear-gradient(90deg, #5d01db 1.88%, #210942 124.67%)
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-top: auto;
            }
        }
        .plan:nth-child(2) {
            background: #ebe7f8;
            .btn-white-outline {
                background-clip: unset;
                -webkit-background-clip: unset;
                -webkit-text-fill-color: unset;
                color: white;
            }
        }
        .plan:last-child {
            background-color: white;
            .btn-white-outline {
                background: $--primary-color;
                background-clip: unset;
                -webkit-background-clip: unset;
                -webkit-text-fill-color: unset;
                color: white;
            }
        }
    }
    .plans-mobile-container {
        display: none;
        .swiper{
            padding-bottom: 10px;;
            .swiper-pagination{
                position: relative;
                top: 10px;
                display: flex;
                gap: 4px;
                justify-content: center;
                .swiper-pagination-bullet {
                    display: inline-block;
                    height: 16px;
                    width: 16px;
                    border: 2px solid $--Gray-5;
                    border-radius: 50%;
                }
                .swiper-pagination-bullet-active {
                    border: 2px solid #a5a6f6;
                    background-color: #a5a6f6;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .membership-plans-main {
        .desktop-plans-container {
            padding: 30px;
        }
        .plans-container {
            .plan {
                padding: 16px;
                .price {
                    h3 {
                        font-size: 42px;
                    }
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .membership-plans-main {
        padding-top: 100px;
        background-color: white;
        .desktop-plans-container {
            display: none;
        }
        .plans-container {
            .plan {
                padding: 16px;
                .price {
                    h3 {
                        font-size: 42px;
                    }
                }
            }
        }
        .plans-mobile-container {
            display: block;
        }
    }
}

@media (max-width: 600px) {
    .membership-plans-main {
        .plans-container {
            .plan {
                padding: 16px;
                .price {
                    h3 {
                        font-size: 42px;
                    }
                }
            }
        }
        .plans-mobile-container {
            .plans {
                .plan {
                    min-width: 80vw !important;
                }
            }
            .plans.current-0 {
                transform: translate(0%);
            }
            .plans.current-1 {
                transform: translate(-80%);
            }
            .plans.current-2 {
                transform: translate(-80%);
            }
        }
    }
}
@media (max-width: 400px) {
    .membership-plans-main {
        padding-top: 80px;
    }
}
