@import "/src/variables";
@import "/src/index.scss";

.packages-main {
    display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    gap: 20px;
    padding: 20px;
    background-color: #f8f8f8;
    padding-top: 120px;
    min-height: 100vh;
    p {
        margin: 0;
    }
}
@media (max-width: 1000px){
    .packages-main{
        padding-top: 100px;
        .filters{
            display: none;
        }
    }
}
@media (max-width: 400px){
    .packages-main{
        padding-top: 80px;
    }
}
