@import "/src/variables";
@import "/src/index.scss";

.education-form-main{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 14px;
    width: min(500px, 95vw);

    p{
        margin: 0;
    }

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        p{
            color: #000;
            font-size: 18px;
            font-weight: 600;
        }
        svg{
            height: 24px;
            width: 24px;
            path{
                fill: #1C1B1F;
            }
        }
    }

    .input-grp {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .bold {
            color: $--Gray-1;
            font-size: 14px;
            font-weight: 600;
        }
        input, select {
            border-radius: 8px;
            border: 1px solid
                var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
            background: white;
            padding: 12px;
            width: 100%;
            height: 44px;
            font-size: 14px;
            font-weight: 500;
        }
        input:focus {
            outline: none;
        }
        .inputs{
            display: flex;
            gap: 8px;
        }
        .certificate-input {
            display: none;
        }
        .file-input {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            // place-items: center;
            padding: 10px;
            border-radius: 10px;
            border: 1px dashed rgba(93, 1, 219, 0.5);
            
            span.container{
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 8px;
            }
            p {
                color: var(--Neutral-400, #131a25);
                font-size: 16px;
                font-weight: 500;
                // text-align: center;
                span {
                    color: $--primary-color;
                }
            }
        }
    }

    .buttons {
        display: flex;
        gap: 100px;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;

        .btn-blue {
            border-radius: 60px;
            background: $--primary-color;
            padding: 8px 20px;
            outline: none;
            border: none;
            cursor: pointer;
            color: white;
        }
        .btn-white-outline {
            padding: 8px 20px;
            border-radius: 60px;
            border: 1px solid $--primary-color;
            background: rgba(239, 230, 251, 0.4);
        }
    }
}