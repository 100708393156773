@import "/src/variables";
@import "/src/index.scss";

.step-5-main {
    .container {
        .input-grp {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .bold {
                color: $--Gray-1;
                font-size: 14px;
                font-weight: 600;
            }
            textarea {
                border-radius: 8px;
                border: 1px solid
                    var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                background: white;
                padding: 12px;
                width: min(400px, 100%);
                height: 120px;
                font-size: 14px;
                font-weight: 500;
            }
            textarea:focus {
                outline: none;
            }
            textarea:disabled{
                background-color: rgba(19, 26, 37, 0.05);
                cursor: not-allowed;
            }
        }
    }
}