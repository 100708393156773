@import "/src/variables";
@import "/src/index.scss";

.hire-form-main{
    display: flex;
    flex-direction: column;
    gap: 18px;
    background-color: white;
    padding: 20px;
    width: 100%;

    .bold {
        color: $--Gray-1;
        font-size: 14px;
        font-weight: 700;
    }
    .input-grp {
        width: 100%;
        display: flex;
        // justify-content: space-between;
        gap: 4px;

        .text {
            width: 450px;
            .desc {
                color: $--Gray-2;
                font-size: 14px;
                font-weight: 400;
            }
        }
        .input-container {
            display: flex;
            align-items: center;
            gap: 12px;
            input, select, textarea {
                border-radius: 8px;
                border: 1px solid
                var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                background: white;
                padding: 12px;
                width: 450px;
                height: 44px;
                font-size: 14px;
                font-weight: 500;
            }
            textarea{
                height: 150px;
                width: 100%;
            }
            input:focus, textarea:focus, select:focus {
                outline: none;
            }
            input:disabled{
                background-color: rgba(19, 26, 37, 0.05);
                cursor: not-allowed;
            }
        }
    }
    .input-grp-2{
        flex-direction: column;
        select{
            width: unset;
        }
    }
    .dates-container{
        display: flex;
        gap: 40px;
        .input-grp{
            width: unset;
            .text{
                width: unset;
            }
            select{

                width: unset;
            }
        }
    }
    hr{
        opacity: 0.05;
        margin: 0;
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        align-self: flex-end;
        margin-top: auto;

        .btn-blue {
            border-radius: 60px;
            background: $--primary-color;
            padding: 8px 30px;
            font-size: 14px;
            outline: none;
            border: none;
            cursor: pointer;
            color: white;
        }
        .primary-btn-white {
            font-size: 14px;
            font-weight: 500;
            color: $--Gray-1;
        }
    }
}

@media (max-width: 800px) {
    .hire-form-main {
        margin-bottom: 62px;
        .button-container {
            position: fixed;
            bottom: 0;
            left: 0;
            background-color: white;
            width: 100%;
            padding: 12px;
            border-top: 1px solid var(--Gray-5, #e0e0e0);
        }
        .dates-container{
            flex-direction: column;
        }
    }
}

@media (max-width: 600px) {
    .hire-form-main {
        margin-bottom: 62px;
        padding: 10px;
        .input-grp{
            flex-direction: column;
            gap: 4px;
            .text{
                width: 100%;
            }
            .input-container{
                width: 100%;
            }
        }
    }
}