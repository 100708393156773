@import "/src/variables";
@import "/src/index.scss";

.table-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    table {
        font-size: 12px;
        width: 100%;

        tr {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            td {
                width: 100%;
                display: flex;
                flex-direction: column;
                border: 1px solid $--Gray-5;

                .input-container {
                    position: relative;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    input,
                    textarea {
                        padding: 12px;
                        width: 100%;
                        border: none;
                        border-top: 0.25px solid $--Gray-5;
                        outline: none;
                    }
                }
                .input-container::before {
                    content: url("../../../../../Assets/icons/edit.svg");
                    position: absolute;
                    background-color: transparent;
                    right: 6px;
                    top: 6px;
                }
                .input-container:has(
                        > textarea:focus,
                        input:focus,
                        select:focus
                    )::before {
                    content: url("../../../../../Assets/icons/save.svg");
                }

                .head {
                    padding: 12px;
                    background: $--Gray-6;
                    color: $--Gray-2;
                    font-size: 14px;
                    font-weight: 600;
                }
                textarea {
                    resize: none;
                    height: 120px;
                    // margin: 12px 0;
                }
                select {
                    box-sizing: border-box;
                    padding: 12px;
                    width: calc(100% - 40px);
                    border: 0.25px solid $--Gray-5;
                    border-radius: 4px;
                    outline: none;
                    margin: 12px;
                    background-color: transparent;
                }
            }
            td.title {
                color: $--Gray-2;
                font-weight: 700;
                padding: 12px;
            }
        }
        tr:last-child {
            td {
                padding-left: 12px;
                input {
                    border-top: none;
                }
            }
        }
    }
    .bold {
        font-size: 18px;
        color: $--Gray-2;
        font-weight: 600;
    }
    .check-box-container {
        display: flex;
        align-items: center;
        gap: 8px;
        .check-box {
            height: 18px;
            width: 18px;
            display: grid;
            place-items: center;
            border: 2px solid #57557d;
            border-radius: 4px;
        }
        .checked {
            background: #5d01db;
        }
        p {
            font-weight: 400;
        }
    }
    .fast-delivery {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid $--Gray-4;

        .plan-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .plan-name {
                color: $--Gray-2;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
            }
            .details {
                display: flex;
                align-items: center;
                gap: 16px;
                .input-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 8px;
                    width: 100%;
                    span {
                        color: rgba(19, 26, 37, 0.3);
                        font-size: 14px;
                        font-weight: 500;
                        white-space: nowrap;
                    }
                    input,
                    span .price-box {
                        border: 1px solid rgba(19, 26, 37, 0.3);
                        border-radius: 4px;
                        background-color: white;
                        width: 90px;
                        padding-left: 10px;
                        height: 36px;
                        color: $--Gray-3;
                        font-size: 14px;
                        outline: none;
                        display: grid;
                        place-items: center;
                    }
                    .price-container{
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        font-size: 16px;    
                    }
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .table-main {
        table {
            tr {
                grid-template-columns: 1fr 1fr;
            }
        }
        .fast-delivery {
            .plan-details {
                flex-direction: column;
                align-items: flex-start;
                .details {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    width: 100%;
                }
            }
        }
    }
}
