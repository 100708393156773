@import "/src/variables";
@import "/src/index.scss";

.find-work-main {
  display: flex;
  gap: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  padding-top: 120px;
  height: 100vh;
  p {
    margin: 0;
  }
  .find-work-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: white;
    padding-top: 20px;
    border: 1px solid var(--Basic-400, #e4e9f2);
    border-radius: 8px;
    .search-box-container {
      display: flex;
      margin: 0px 20px;
      border: 1px solid #e4e9f2;
      border-radius: 8px;

      .sc-dcJsrY {
        width: 100%;
        .wrapper {
          border-radius: 8px 0px 0px 8px;
          border: none;
          box-shadow: none;
          background-color: white;
          z-index: 1;
          .sc-gEvEer {
            background: var(--L1-white, #fff);
            width: 100%;
            z-index: 1;

            .jVORrT {
              display: none;
            }
          }
        }
        .wrapper:active {
          box-shadow: none;
          border: none;
        }
        .clear-icon {
          display: none;
        }
      }
      .btn-blue {
        border-radius: 0px 8px 8px 0px;
        opacity: 0.2;
      }
      .btn-blue:hover {
        opacity: 1;
      }
    }
    .jobs-container {
      border-radius: 8px;
      // border: 1px solid var(--Basic-400, #e4e9f2);
      background: #fff;
      height: 100%;
      overflow: auto;

      .filter-icon-container {
        display: none;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid var(--Basic-400, #e4e9f2);
        p {
          font-size: 20px;
          font-weight: 500;
        }
        svg {
          height: 24px;
          width: 24px;
        }
      }
      .heading {
        display: flex;
        border-radius: 8px 8px 0px 0px;
        border: none;
        border-bottom: 1px solid var(--Basic-400, #e4e9f2);
        text-align: center;
        span {
          display: flex;
          flex-direction: column;
          gap: 10px;
          cursor: pointer;
          p {
            padding: 0px 20px;
            margin-top: 15px;
            color: $--Gray-1;
            font-size: 16px;
            font-weight: 700;
          }
        }
        .active {
          .underline {
            height: 5px;
            width: 100%;
            border-radius: 8px 8px 0px 0px;
            background: $--primary-color;
          }
        }
      }

      .job-container {
        position: relative;
        .fav-icon {
          position: absolute;
          top: 10px;
          right: 30px;
          cursor: pointer;
          z-index: 1;
        }
        .fav-icon.filled {
          path {
            fill: rgb(255, 52, 52);
          }
        }
        .job {
          margin: 20px;
          padding: 20px;
          border-radius: 8px;
          border: 1px solid $--Gray-5;
          display: flex;
          flex-direction: column;
          gap: 12px;
          position: relative;
          
          .job-tag {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 0px 0px 0px 8px;
            background: $--Gray-5;
            padding: 4px 16px;
            color: $--Gray-2;
            font-size: 11px;
            font-weight: 500;
          }
          
          .job-head {
            cursor: pointer;
            span {
              display: flex;
              justify-content: space-between;
              .title {
                color: $--Dark-blue-2;
                font-size: 20px;
                font-weight: 600;
              }
            }
            .desc {
              color: $--Gray-2;
              font-size: 12px;
              font-weight: 400;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          hr {
            opacity: 0.1;
            margin: 0;
          }
          .price {
            color: $--Dark-blue-2;
            font-size: 20px;
            font-weight: 600;
            span {
              color: $--Dark-Gray;
              font-size: 14px;
              font-weight: 400;
            }
          }
          .skills-container {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            .skill {
              display: flex;
              align-items: center;
              gap: 2px;
              border: 1px solid #5d01db;
              border-radius: 4px;
              background: rgba(239, 230, 251, 0.4);
              padding: 4px 6px 4px 4px;
              color: black;
              font-size: 12px;
              font-weight: 500;
            }
          }
          .ratings {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            align-items: center;

            .item {
              display: flex;
              gap: 4px;
              align-items: center;
            }
            .hr {
              width: 1px;
              height: 16px;
              background: #bdbdbd;
            }
            .payment-verified, .account-verified {
              padding: 2px 8px;
              border-radius: 4px;
              background: var(--Blue-1, #2f80ed);
              color: white;
              font-size: 12px;
              font-weight: 500;

              svg {
                height: 16px;
                width: 16px;
              }
            }
            .account-verified{
              background: $--Dark-Green-color;
            }
            .rating {
              color: $--Gray-1;
              font-size: 14px;
              font-weight: 500;

              svg {
                height: 20px;
                width: 20px;
                path {
                  fill: #f3c831;
                }
              }
            }
            .location {
              svg {
                height: 20px;
                width: 20px;
              }
            }
          }
        }
      
        .edit-bids {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
          z-index: 1;
          svg{
            cursor: pointer;
          }
        }
      }
    }
    .pagination {
      margin: 03px auto;
      .MuiPaginationItem-previousNext {
        background-color: white;
        filter: drop-shadow(0px 1px 4px rgba(24, 26, 32, 0.07));
      }
      .Mui-selected {
        color: white;
        background-color: $--primary-color;
      }
    }
  }
}

@media (max-width: 1000px) {
  .find-work-main {
    .filters {
      display: none;
    }
    .find-work-container {
      
      .jobs-container {
        border: none;
        .filter-icon-container {
          display: flex;
        }
        .job-container{
            .job{

                border-radius: 8px;
            }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .find-work-main {
    .find-work-container {
      .jobs-container {
        .filter-icon-container {
          p {
            font-size: 16px;
          }
        }
        .heading {
          span {
            p {
              font-size: 14px;
              padding: 0 14px;
              color: #83848a;
            }
          }
          .active {
            p {
              color: $--Gray-1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .find-work-main {
    padding: 0;
    padding-top: 100px;
    min-height: calc(100vh - 100px);

    .find-work-container {
      .search-box-container {
        .sc-dcJsrY {
          .wrapper {
            border-radius: 8px 0px 0px 8px;
            height: 100%;
          }
        }
        .btn-blue {
          border-radius: 0px 8px 8px 0px;
          height: 100%;
        }
      }
      .jobs-container {
        border-radius: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .filter-icon-container {
          padding: 10px;
          p {
            font-size: 14px;
          }
        }
        .heading {
          span {
            gap: 4px;
            p {
              font-size: 12px;
              padding: 0 10px;
            }
          }
        }
        .job-container {
          .fav-icon{
            right: 10px;
          }
          .job {
            margin: 0;
            // border-radius: 0;
            padding: 10px;
            hr {
              opacity: 0.05;
            }
            .job-head {
              span {
                .title {
                  font-size: 16px;
                }
              }
            }
            .price {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .find-work-main {
    padding-top: 75px;
    min-height: calc(100vh - 75px);

    .find-work-container {
      .jobs-container {
        border-radius: 0;
        padding: 0px 20px 20px;

        .heading {
          span {
            gap: 4px;
            p {
              font-size: 14px;
              padding: 0 6px;
            }
          }
        }
        .job-container {
          .job {
            margin: 0;
            // border-radius: 0;
            padding: 10px;
            hr {
              opacity: 0.05;
            }
            .job-head {
              span {
                .title {
                  font-size: 16px;
                }
              }
            }
            .price {
              font-size: 16px;
              span {
                font-size: 12px;
              }
            }
          }
        }
      }
      .pagination {
        nav {
          width: 100vw;
        }
        margin: 0px auto 20px;

        .MuiPagination-ul {
          flex-wrap: nowrap;
          transform: scale(0.8);
          margin: auto;
          justify-content: center;
          text-align: center;
        }
        .MuiPaginationItem-previousNext {
          background-color: white;
          filter: drop-shadow(0px 1px 4px rgba(24, 26, 32, 0.07));
        }
        .Mui-selected {
          color: white;
          background-color: $--primary-color;
        }
      }
    }
  }
}
