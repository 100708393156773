@import "/src/variables";
@import "/src/index.scss";

.deposit-work-main {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 14px;
    width: min(450px, 90vw);
    min-height: 250px;
    p {
        margin: 0;
    }
    .description {
        font-size: 14px;
        color: $--Gray-1;
        outline: none;
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        align-self: flex-end;
        margin-top: auto;

        .btn-blue {
            border-radius: 60px;
            background: #219653;
            padding: 8px 14px;
            font-size: 14px;
            outline: none;
            border: none;
            cursor: pointer;
            color: white;
        }
        .primary-btn-white {
            font-size: 14px;
            font-weight: 500;
            color: $--Gray-1;
        }
    }
}
