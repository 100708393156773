.delete-view-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 24px 54px 24px;
    border-radius: 8px;
    background: #FFF;
    width: min(500px, 95vw);
    p{
        color: #000;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
    }
    .buttons{
        display: flex;
        gap: 12px;
        button{
            width: 150px;
            margin-top: 20px;
            font-size: 14px;
        }
    }
}