@import "/src/variables";
@import "/src/index.scss";

.about-main {
    display: flex;
    gap: 30px;
    padding: 30px;
    padding-bottom: 100px;
    width: 100%;
    background-color: #f8f8f8;

    p {
        margin: 0;
    }
    .about-container {
        background: #fff;
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;

        .offer-name {
            padding: 0 16px;
            .type {
                color: $--Dark-Gray;
                font-size: 16px;
                font-weight: 400;
            }
            .title {
                color: #24262e;
                font-size: 28px;
                font-weight: 600;
            }
        }
        img.review-img {
            padding: 0 16px;
            width: 100%;
        }

        .text-container {
            padding: 0 16px;
            .bold {
                color: #373a3f;
                font-size: 20px;
                font-weight: 600;
            }
            .desc {
                color: $--Gray-2;
                font-size: 14px;
                font-weight: 400;
                margin-top: 8px;
            }
            .user-review {
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 10px 16px;
                border-radius: 8px;
                border: 1px solid $--Gray-5;
                margin-top: 16px;

                .ratings-container {
                    .name {
                        color: #373a3f;
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .ratings {
                        display: flex;
                        align-items: center;

                        svg {
                            height: 16px;
                            width: 16px;
                        }
                        .filled {
                            path {
                                fill: #f3c831;
                            }
                        }
                    }
                }
                .review {
                    color: $--Gray-3;
                    font-size: 12px;
                    font-weight: 400;
                }
                .client-name {
                    color: $--Gray-3;
                    font-size: 14px;
                    font-weight: 400;
                    span {
                        color: $--Gray-2;
                        font-weight: 500;
                    }
                }
            }
        }
        .mobile-offer-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            background: #fff;
            height: fit-content;
            display: none;
            .heading {
                display: flex;
                border-radius: 8px 8px 0px 0px;
                border: none;
                border-bottom: 1px solid var(--Basic-400, #e4e9f2);
                text-align: center;
                justify-content: space-evenly;
                padding: 0 16px;
                width: 100%;

                span {
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    cursor: pointer;
                    p {
                        padding: 0px 20px;
                        margin-top: 12px;
                        color: #83848a;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
                .active {
                    p {
                        color: $--Gray-1;
                    }
                    .underline {
                        height: 5px;
                        width: 100%;
                        border-radius: 8px 8px 0px 0px;
                        background: $--primary-color;
                    }
                }
            }
            .details {
                display: flex;
                justify-content: center;
                padding: 20px;
                margin: 0 20px;
                display: flex;
                flex-direction: column;
                gap: 12px;
                border: 1px solid #e0e6ed;
                border-radius: 10px;
                width: min(550px, 100% - 40px);
                .price {
                    color: $--Dark-blue-2;
                    font-size: 24px;
                    font-weight: 800;
                }
                .desc {
                    color: $--Gray-2;
                    font-size: 14px;
                    font-weight: 400;
                }
                .points {
                    span {
                        display: flex;
                        gap: 8px;
                        p {
                            color: $--Gray-2;
                            font-size: 14px;
                            font-weight: 400;
                        }
                        .bold {
                            font-weight: 600;
                        }
                    }
                }
                .btn-white-outline {
                    width: 100%;
                    padding: 18px 68px;
                    border: 1px solid var(--Gredient, #5d01db);
                    border-radius: 8px;
                    font-size: 16px;
                    font-weight: 600;
                    background: linear-gradient(
                        90deg,
                        #5d01db 1.88%,
                        #210942 124.67%
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-top: 20px;
                }
            }
            .details-container {
                padding: 16px;
                display: flex;
                flex-direction: column;
                gap: 8px;
                border-radius: 10px;
                border: 1px solid #e0e6ed;
                background: white;
                height: fit-content;
                width: calc(100% - 40px);
                .about-person {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    img {
                        height: 34px;
                        width: 34px;
                        min-width: 34px;
                        border-radius: 50%;
                    }
                    .name {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        .rating-container {
                            p {
                                color: #373a3f;
                                font-size: 16px;
                                font-weight: 600;
                            }
                            .ratings {
                                display: flex;
                                align-items: center;

                                svg {
                                    height: 12px;
                                    width: 12px;
                                }
                                .filled {
                                    path {
                                        fill: #f3c831;
                                    }
                                }
                            }
                        }
                        .tag {
                            font-size: 14px;
                            color: $--primary-color;
                            font-weight: 600;
                            cursor: pointer;
                        }
                    }
                }
                hr {
                    margin: 8px 0;
                }
                .grp {
                    .category {
                        color: $--Gray-2;
                        font-size: 12px;
                    }
                    .bold {
                        color: #373a3f;
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .skills-container {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 8px;
                        .skill {
                            display: flex;
                            align-items: center;
                            gap: 2px;
                            border: 1px solid #5d01db;
                            border-radius: 4px;
                            background: rgba(239, 230, 251, 0.4);
                            padding: 4px 6px 4px 4px;
                            color: black;
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
    .side-div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .offer-details {
            border-radius: 10px;
            border: 1px solid #e0e6ed;
            background: #fff;
            height: fit-content;

            .heading {
                display: flex;
                border-radius: 8px 8px 0px 0px;
                border: none;
                border-bottom: 1px solid var(--Basic-400, #e4e9f2);
                text-align: center;
                span {
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    cursor: pointer;
                    p {
                        padding: 0px 20px;
                        margin-top: 12px;
                        color: #83848a;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
                .active {
                    p {
                        color: $--Gray-1;
                    }
                    .underline {
                        height: 5px;
                        width: 100%;
                        border-radius: 8px 8px 0px 0px;
                        background: $--primary-color;
                    }
                }
            }
            .details {
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 12px;
                .price {
                    color: $--Dark-blue-2;
                    font-size: 24px;
                    font-weight: 800;
                }
                .desc {
                    color: $--Gray-2;
                    font-size: 14px;
                    font-weight: 400;
                }
                .points {
                    span {
                        display: flex;
                        gap: 8px;
                        p {
                            color: $--Gray-2;
                            font-size: 14px;
                            font-weight: 400;
                        }
                        .bold {
                            font-weight: 600;
                        }
                    }
                }
                .btn-white-outline {
                    width: 100%;
                    padding: 18px 68px;
                    border: 1px solid var(--Gredient, #5d01db);
                    border-radius: 8px;
                    font-size: 16px;
                    font-weight: 600;
                    background: linear-gradient(
                        90deg,
                        #5d01db 1.88%,
                        #210942 124.67%
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-top: 20px;
                }
            }
        }
        .details-container {
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-radius: 10px;
            border: 1px solid #e0e6ed;
            background: #fff;
            height: fit-content;
            .about-person {
                display: flex;
                gap: 8px;
                align-items: center;
                img {
                    height: 34px;
                    width: 34px;
                    min-width: 34px;
                    border-radius: 50%;
                }
                .name {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    .rating-container {
                        p {
                            color: #373a3f;
                            font-size: 16px;
                            font-weight: 600;
                        }
                        .ratings {
                            display: flex;
                            align-items: center;

                            svg {
                                height: 12px;
                                width: 12px;
                            }
                            .filled {
                                path {
                                    fill: #f3c831;
                                }
                            }
                        }
                    }
                    .tag {
                        font-size: 14px;
                        color: $--primary-color;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }
            }
            hr {
                margin: 8px 0;
            }
            .grp {
                .category {
                    color: $--Gray-2;
                    font-size: 12px;
                }
                .bold {
                    color: #373a3f;
                    font-size: 16px;
                    font-weight: 600;
                }
                .skills-container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 8px;
                    .skill {
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        border: 1px solid #5d01db;
                        border-radius: 4px;
                        background: rgba(239, 230, 251, 0.4);
                        padding: 4px 6px 4px 4px;
                        color: black;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .about-main {
        flex-direction: column;
        .about-container {
            .mobile-offer-details {
                display: flex;
            }
        }
        .side-div {
            display: none;
        }
    }
}

@media (max-width: 600px) {
    .about-main {
        padding: 0px;
        .about-container{
            .mobile-offer-details{
                .details-container{
                    background-color: #f8f8f8;
                    width: 100%;
                    border: none;
                    border-radius: 0px;
                }
            }
        }
    }
}
