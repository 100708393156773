@import "/src/variables";
@import "/src/index.scss";

.package-list-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #f8f8f8;
    width: 100%;
    p {
        margin: 0;
    }
    .heading-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .heading {
            color: #000;
            font-size: 24px;
            font-weight: 600;
        }
        span {
            span {
                font-size: 14px;
                font-weight: 400;
            }
        }
        .filter-icon {
            display: none;
        }
    }
    .package-list {
        display: grid;
        gap: 24px;
        grid-template-columns: 1fr 1fr 1fr;
        margin: 0 auto;
        .package {
            background: #fff;
            height: fit-content;
            max-width: 400px;
            .image-container {
                width: 100%;
                img {
                    width: 100%;
                    height: 216px;
                    cursor: pointer;
                }
            }
            .details-container {
                padding: 8px;
                .about-person {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    img {
                        height: 34px;
                        width: 34px;
                        min-width: 34px;
                        border-radius: 50%;
                    }
                    .name {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        .rating-container {
                            p {
                                color: #373a3f;
                                font-size: 16px;
                                font-weight: 600;
                            }
                            .ratings {
                                display: flex;
                                align-items: center;

                                svg {
                                    height: 10px;
                                    width: 10px;
                                }
                                .filled {
                                    path {
                                        fill: #f3c831;
                                    }
                                }
                            }
                        }
                        .tag-grp{
                            display: flex;
                            gap: 4px;
                            .tag, .account-verified {
                                display: flex;
                                gap: 2px;
                                background: #1c0067;
                                border-radius: 4px;
                                padding: 2px 4px;
                                align-self: flex-start;
                                p {
                                    color: #fff;
                                    font-size: 9px;
                                    font-weight: 500;
                                }
                            }
                            .account-verified{
                                background: $--Dark-Green-color;
                            }
                        }
                    }
                }
                hr {
                    margin: 8px 0;
                }
                .category {
                    color: $--Gray-2;
                    font-size: 12px;
                }
                .desc {
                    color: #24262e;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 16px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                button {
                    width: 102px;
                    margin-right: 20px;
                }
                .delete-btn {
                    border: 1px solid var(--Gray-1, #333);
                    background: rgba(93, 1, 219, 0);
                    color: $--Gray-1;
                }
            }
        }
    }
    .pagination {
        margin: 30px auto;
        margin-top: auto;
        .MuiPaginationItem-previousNext {
            background-color: white;
            filter: drop-shadow(0px 1px 4px rgba(24, 26, 32, 0.07));
        }
        .Mui-selected {
            color: white;
            background-color: $--primary-color;
        }
    }
}

@media (max-width: 1300px) {
    .package-list-main {
        .package-list {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media (max-width: 1000px) {
    .package-list-main {
        .heading-container {
            span {
                display: none;
            }
            .filter-icon {
                display: block;
            }
        }
    }
}

@media (max-width: 600px) {
    .package-list-main {
        .package-list {
            grid-template-columns: 1fr;
        }
    }
}
