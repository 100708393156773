@import "/src/variables";
@import "/src/index.scss";

.home-nav {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    position: absolute;
    width: 100vw;
    z-index: 1;

    svg{
        width: fit-content;
    }
}

@media (max-width: 600px) {
    .home-nav {
        padding: 20px;
        svg,
        button {
            height: 40px;
        }
    }
}

@media (max-width: 400px) {
    .home-nav {
        padding: 16px;
        svg,
        button {
            height: 30px;
        }
        button{
            padding: 0 16px;
            font-size: 16px;
        }
    }
}
