@import "/src/variables";
@import "/src/index.scss";

.job-post-step-3-main {
    .container {
        .input-grp {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .bold {
                color: var(--Shades-Black, #131a25);
                font-size: 14px;
                font-weight: 700;
            }
            textarea {
                border-radius: 8px;
                border: 1px solid
                    var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                background: white;
                padding: 12px;
                width: min(400px, 100%);
                height: 120px;
                font-size: 14px;
                font-weight: 500;
            }
            textarea:focus {
                outline: none;
            }
            textarea:disabled {
                background-color: rgba(19, 26, 37, 0.05);
                cursor: not-allowed;
            }
            textarea::-webkit-scrollbar {
                width: 4px;
            }
            textarea::-webkit-scrollbar-thumb {
                background-color: $--Gray-4;
                border-radius: 20px;
            }
        }
        .attachment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            padding: 12px;
            border-radius: 4px;
            border: 1px dashed var(--Basic-600, #8f9bb3);
            width: 100%;
            input {
                display: none;
            }
            img {
                height: 24px;
                width: 24px;
            }
            span {
                display: flex;
                gap: 8px;
                align-items: center;
            }
            .delete-icon {
                height: 16px;
                width: 16px;
            }
        }
        .file-upload {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 2px;
            width: min(400px, 100%);
            .instructions {
                display: flex;
                justify-content: space-between;
                color: $--Gray-4;
                font-size: 10px;
                font-weight: 500;
            }
        }
        .files-container {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: min(400px, 100%);

            .my-file {
                display: flex;
                align-items: center;
                gap: 12px;
                .attachment {
                    span {
                        max-width: calc(100% - 30px);
                        p {
                            width: 100%;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .plus {
                    border: 1px solid #d2d3d5;
                    padding: 8px;
                    cursor: pointer;

                    svg {
                        height: 28px;
                        width: 28px;
                        transform: rotate(45deg);
                        path {
                            fill: #1c1b1f;
                        }
                    }
                }
            }
        }
    }
}
