@import "/src/variables";
@import "/src/index.scss";

.purchase-details-main {
    .details {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 580px;
        padding: 20px;

        .description {
            display: flex;
            justify-content: space-between;
            gap: 30px;
            .text{
                width: 100%;
            }
        }
        .includes {
            display: flex;
            flex-direction: column;
            gap: 6px;
            .item {
                display: flex;
                align-items: center;
                gap: 4px;
                p {
                    color: $--Gray-2;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
        .count-manage {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .item-container {
                display: flex;
                flex-direction: column;
                gap: 6px;
                border-radius: 8px;
                border: 1px solid $--Gray-2;
                padding: 16px;
                .single-price {
                    display: flex;
                    justify-content: space-between;
                    .price {
                        color: $--Gray-3;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
                .quantity {
                    display: flex;
                    justify-content: space-between;
                    p {
                        color: $--Gray-3;
                        font-size: 14px;
                        font-weight: 400;
                    }
                    .handle-input {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        color: var(--Neutral-200, #7d8187);
                        font-size: 14px;
                        font-weight: 500;
                        img {
                            cursor: pointer;
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
                .source-file {
                    display: flex;
                    gap: 8px;
                    .check-box {
                        margin-top: 4px;
                        display: grid;
                        place-content: center;
                        height: 20px;
                        min-width: 20px;
                        border-radius: 4px;
                        border: 1px solid
                            var(--primary-50, rgba(33, 70, 152, 0.5));
                        cursor: pointer;
                        img {
                            width: 16px;
                        }
                    }
                }
                .coupon-code-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .icon-container {
                        border: 1px solid #5d01db;
                        border-radius: 4px;
                        background: rgba(93, 1, 219, 0.05);
                        cursor: pointer;
                    }
                    .expand-icon-container {
                        display: grid;
                        place-content: center;
                        height: 36px;
                        width: 36px;
                        img {
                            transform: rotate(-90deg);
                        }
                    }
                    .remove-coupon {
                        display: flex;
                        gap: 8px;
                        padding: 8px;
                        color: $--primary-color;
                        font-size: 14px;
                        font-weight: 500;
                        svg {
                            path {
                                fill: $--primary-color;
                            }
                        }
                    }
                }
            }
            .payment {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .calculation {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                }
                span {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .title {
                        color: $--Gray-2;
                        font-size: 14px;
                        font-weight: 600;
                    }
                    .value {
                        color: $--Gray-3;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px){
    .purchase-details-main{
        .details{
            width: 100%;
        }
    }
}