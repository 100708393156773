@import "/src/variables";
@import "/src/index.scss";

.coupon-apply-main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 580px;
    .title {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 20px 20px 0 20px;
        img {
            transform: rotate(90deg);
            cursor: pointer;
        }
    }

    input {
        border-radius: 8px;
        border: 1px solid $--Gray-2;
        background: white;
        padding: 12px;
        height: 44px;
        font-size: 14px;
        font-weight: 500;
        margin: 0 20px;
    }
    input:focus {
        outline: none;
    }
    .coupon-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .coupon {
            background: #f8f8f8;
            padding: 20px;
            display: flex;
            gap: 16px;
            .bold {
                font-size: 16px;
            }
            .check-box {
                margin-top: 4px;
                display: grid;
                place-content: center;
                height: 20px;
                min-width: 20px;
                border-radius: 4px;
                border: 1px solid var(--primary-50, rgba(33, 70, 152, 0.5));
                background: white;
                cursor: pointer;
                img {
                    width: 10px;
                }
            }
            .check-box.selected {
                background: #5d01db;
                box-shadow: 4px 4px 12px 0px rgba(33, 70, 152, 0.12);
            }
            .details {
                display: flex;
                flex-direction: column;
                gap: 14px;
                .coupon-code {
                    padding: 8px 14px;
                    border: 1px dashed #5d01db;
                    border-radius: 4px;
                    background: rgba(93, 1, 219, 0.05);
                    width: fit-content;
                    span {
                        background-clip: text;
                        background: var(
                            --gr,
                            linear-gradient(
                                    to bottom right,
                                    #5d01db 0%,
                                    #3d008f 26%,
                                    #2e0369 39%,
                                    #210942 49%
                                )
                                bottom right / 50% 50% no-repeat,
                            linear-gradient(
                                    to bottom left,
                                    #5d01db 0%,
                                    #3d008f 26%,
                                    #2e0369 39%,
                                    #210942 49%
                                )
                                bottom left / 50% 50% no-repeat,
                            linear-gradient(
                                    to top left,
                                    #5d01db 0%,
                                    #3d008f 26%,
                                    #2e0369 39%,
                                    #210942 49%
                                )
                                top left / 50% 50% no-repeat,
                            linear-gradient(
                                    to top right,
                                    #5d01db 0%,
                                    #3d008f 26%,
                                    #2e0369 39%,
                                    #210942 49%
                                )
                                top right / 50% 50% no-repeat
                        );
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
