@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Bakbak+One&family=Inter&family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900;6..12,1000&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $--primary-font-family;
}

body {
  overflow-x: hidden;
}
p {
  margin: 0;
}

.primary-btn-white {
  padding: 0 24px;
  height: 48px;
  border-radius: 8px;
  background: $--white;
  color: $--primary-color;
  font-size: 20px;
  font-weight: 700;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in;
}
.primary-btn-white:hover {
  opacity: 0.85;
}
.primary-btn-blue {
  border-radius: 60px;
  background: $--primary-color;
  padding: 8px 20px;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  width: 300px;
  font-size: 20px;
  font-weight: 500;
  transition: all 0.2s ease-in;

}
.primary-btn-blue:hover {
  opacity: 0.85;
}
.btn-blue {
  border-radius: 60px;
  background: $--primary-color;
  padding: 8px 20px;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.1s ease;

}
.btn-white-outline {
  width: 151px;
  padding: 8px 20px;
  border-radius: 60px;
  border: 1px solid $--primary-color;
  background: rgba(239, 230, 251, 0.4);
  font-size: 14px;
  font-weight: 500;
  color: #1c0042;
  transition: all 0.1s ease;

}

.offcanvas {
  width: fit-content !important;
  .offcanvas-body {
    padding: 0;
  }
}
p.form-error {
  margin: 0;
  color: red !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}
p.empty-message {
  font-size: 24px;
  color: $--Dark-Gray;
  text-align: center;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}


// for Firefox add this class as well
.thin_scroll {
  scrollbar-width: thin; // auto | thin | none | <length>;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(93, 1, 219,0.7); 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #5D01DB; 
}
