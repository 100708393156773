@import "/src/variables";
@import "/src/index.scss";

.step-8-main {
    .container {
        .desc {
            color: $--Gray-3;
            font-size: 16px;
            font-weight: 400;
            margin-top: 14px;
        }
        .video-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 12px;
            width: 100%;
            .input-container {
                display: flex;
                gap: 30px;
    
                .file-input {
                    display: grid;
                    place-items: center;
                    padding: 40px 30px;
                    border-radius: 10px;
                    border: 1px dashed rgba(93, 1, 219, 0.5);
                    cursor: pointer;
    
                    input {
                        display: none;
                    }
                    p {
                        color: var(--Neutral-400, #131a25);
                        font-size: 16px;
                        font-weight: 500;
                        text-align: center;
                        span {
                            color: $--primary-color;
                        }
                    }
                }
                .hr {
                    width: 1px;
                    height: auto;
                    background: #e0e0e0;
                    display: inline-block;
                }
                .yt-link {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    text-align: center;
    
                    .btn-white-outline {
                        padding: 8px 20px;
                        border-radius: 60px;
                        border: 1px solid $--primary-color;
                        background: rgba(239, 230, 251, 0.4);
                    }
                }
            }
            .arrow{
                height: 40px;
                width: 40px;
                background-color: $--Gray-6;
                display: grid;
                place-items: center;
                border-radius: 10px;
                transform: rotate(90deg);
                cursor: pointer;
            }
            .right-arrow{
                transform: rotate(270deg);
            }
            iframe, video, .portfolio-img{
                max-width: 70%;
            }
            .attachment {
                border: 1px solid
                    var(--Gray-5, #e0e0e0);
                border-radius: 4px;
                padding: 10px;
                display: flex;
                align-items: center;
                gap: 8px;
                width: 100%;
                cursor: pointer;
    
                img {
                    height: 20px;
                }
                p {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: var(
                        --L1-ref-secondary-secondary20,
                        #47484b
                    );
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
        .points {
            padding: 10px 40px;
            border-radius: 8px;
            background: #f7f6f6;
            color: $--Gray-2;
            font-size: 16px;
            font-weight: 400;
        }
    }
}

@media (max-width: 800px) {
    .step-8-main {
        .container {
            .input-container{
                flex-direction: column;

                .hr{
                    height: 1px;
                    width: 100%;
                }
            }
        }
    }
}
