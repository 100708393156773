@import "/src/variables";
@import "/src/index.scss";

.name-phone-input-main {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        background: #fff;
        border: 1px solid #eceded;
        padding: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        max-width: 700px;
        border-radius: 12px;

        .title {
            font-size: 38px;
            font-weight: 600;
            text-align: center;
        }
        .form-container{
            display: flex;
            gap: 20px;
            width: 100%;
                
            .input-grp{
                display: flex;
                flex-direction: column;
                gap: 4px;
                width: 50%;
                
                .phone{
                    display: flex;
                    height: 100%;
                    border: 1px solid var(--neutral-opacity-20, rgba(19, 26, 37, 0.20));
                    border-radius: 8px;
                    
                    .ReactFlagsSelect-module_flagsSelect__2pfa2{
                        width: 150px;
                        height: 44px;
                        border-right: 1px solid var(--neutral-opacity-20, rgba(19, 26, 37, 0.20));
                        
                        .ReactFlagsSelect-module_selectBtn__19wW7{
                            padding: 12px 0px;
                            height: 44px;
                            border: none;
                            border-radius: 0px;
                            width: 100%;
                            
                            .ReactFlagsSelect-module_label__27pw9{
                                font-size: 14px;
                                font-weight: 700;
                                
                            }
                        }
                        .ReactFlagsSelect-module_selectBtn__19wW7::after{
                            display: none;
                        }
                    }
                    .phone_input{
                        width: calc(100% - 95px);
                        border: none;
                    }
                }
                .bold {
                    color: $--Gray-1;
                    font-size: 16px;
                    font-weight: 600;
                }
                .note{
                    font-size: 11px;
                    text-align: right;
                }
                .radio-container {
                    display: flex;
                    align-items: center;
                    gap: 30px;
                    .radio-grp {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        cursor: pointer;
        
                        .my-radio {
                            display: grid;
                            place-items: center;
                            height: 20px;
                            width: 20px;
                            border-radius: 50px;
                            border: 1px solid #7d8187;
                            padding: 3px;
        
                            .select-mark {
                                height: 100%;
                                width: 100%;
                                display: inline-block;
                                border-radius: 50%;
                            }
                        }
                        .selected {
                            border-color: $--primary-color;
                            .select-mark {
                                background-color: $--primary-color;
                            }
                        }
                        p {
                            color: $--Gray-1;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
                
                input{
                    border-radius: 8px;
                    border: 1px solid var(--neutral-opacity-20, rgba(19, 26, 37, 0.20));
                    background: white;
                    padding: 12px;
                    height: 44px;
                    font-size: 14px;   
                    font-weight: 500;
                }
                input:focus{
                    outline: none;
                }
                
            }
        }
        .login-form{
            flex-direction: column;
            .input-grp{
                width: 100%;
            }
        }
        .next-action{
            width: 100%;
            
            button{
                width: 100%;
                border-radius: 8px;
            }
        }
    }
}

@media (max-width: 1000px) {
    .name-phone-input-main {
        .container {
            padding: 30px;
        }
    }
}

@media (max-width: 800px) {
    .name-phone-input-main {
        .container {            
            padding: 20px;
            width: 100%;
            height: 100%;
            gap: 16px;

            .form-container{
                flex-direction: column;
                .input-grp{
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .name-phone-input-main {
        width: 100vw;
        .container {
            .title {
                font-size: 32px;
            }
        }
    }
}