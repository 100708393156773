@import "/src/variables";
@import "/src/index.scss";

.job-details-main {
    display: flex;
    padding: 20px;
    background-color: #f8f8f8;
    padding-top: 120px;

    p {
        margin: 0;
    }

    .job-container {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
        padding: 20px;
        background: #fff;
        height: calc(100vh - 120px - 20px);
        overflow-y: scroll;
        border: 1px solid $--Gray-5;
        border-right: none;

        .heading {
            display: flex;
            border-radius: 8px 8px 0px 0px;
            border: none;
            border-bottom: 1px solid var(--Basic-400, #e4e9f2);
            text-align: center;
            span {
                display: flex;
                flex-direction: column;
                gap: 10px;
                cursor: pointer;
                p {
                    padding: 0px 20px;
                    color: $--Gray-1;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .active {
                .underline {
                    height: 5px;
                    width: 100%;
                    border-radius: 8px 8px 0px 0px;
                    background: $--primary-color;
                }
            }
        }
    }
    .job-container::-webkit-scrollbar {
        width: 4px;
    }
    .job-container::-webkit-scrollbar-thumb {
        background-color: $--Gray-4;
        border-radius: 20px;
    }
    .about-job-main {
        background-color: white;
        border: 1px solid $--Gray-5;
        border-left: 1px solid var(--Gray-5, #e0e0e0);
        width: 400px;
        .about-job-head {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            padding: 20px 40px;
            border-bottom: 1px solid $--Gray-5;

            .budget {
                h2 {
                    color: $--Dark-blue-2;
                    font-size: 18px;
                    font-weight: 600;
                    margin: 0;
                }
                p {
                    color: $--Dark-Gray;
                    font-size: 12px;
                    font-weight: 400;
                    text-align: center;
                    margin-top: 2px;
                }
            }
            button {
                width: 100%;
            }
        }
        .about {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 20px;

            .title {
                color: $--Gray-1;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
            }
            hr{
                margin: 0;
            }
            .budget {
                h2 {
                    color: $--Dark-blue-2;
                    font-size: 18px;
                    font-weight: 600;
                    margin: 0;
                }
                p {
                    color: $--Dark-Gray;
                    font-size: 12px;
                    font-weight: 400;
                    margin-top: 2px;
                }
            }
            .about-container {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .item {
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    color: $--Gray-1;
                    font-size: 12px;
                    font-weight: 400;

                    svg {
                        height: 20px;
                        width: 20px;
                        path {
                            fill: $--Gray-4;
                        }
                    }
                }

                .payment-verified {
                    padding: 4px 8px;
                    border-radius: 4px;
                    background: var(--Blue-1, #2f80ed);
                    color: white;
                    font-weight: 500;
                    width: fit-content;

                    svg {
                        height: 16px;
                        width: 16px;
                        path {
                            fill: white;
                        }
                    }
                }
                .rating {
                    svg {
                        path {
                            fill: #f3c831;
                        }
                    }
                }
                .fill-none {
                    svg {
                        path {
                            fill: unset;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .job-details-main {
        flex-direction: column;
        padding: 0;
        padding-top: 80px;
        .job-container {
            height: unset;
            .heading {
                span {
                    p {
                        font-size: 14px;
                        padding: 0 14px;
                        color: #83848a;
                    }
                }
                .active {
                    p {
                        color: $--Gray-1;
                    }
                }
            }
        }
        .about-job-main {
            width: 100%;
            margin-bottom: 62px;
            border-top: 1px solid var(--Gray-5, #e0e0e0);
            .about-job-head {
                display: none;
            }
        }
        .bid-about-main{
            display: none;
        }
    }
}

@media (max-width: 400px) {
    .job-details-main {
        padding-top: 62px;
        .job-container {
            gap: 20px;
            padding: 20px;
            .heading {
                span {
                    gap: 4px;
                    p {
                        font-size: 14px;
                        padding: 0 6px;
                    }
                }
            }
        }
        .about-job-main {
            .about{
                gap: 12px;
                padding: 10px;
            }
        }
    }
}
