@import "/src/variables";
@import "/src/index.scss";

.step-6-main {
    .container {
        .image-input-container{
            input{
                display: none;
            }
            display: flex;
            align-items: center;
            gap: 12px;
            
            .pick-image-button{
                padding: 12px;
                background-color: rgba(242, 242, 242, 0.80);
                border-radius: 50%;
                border: 1px solid $--Gray-5;
                cursor: pointer;
            }
            .user-image{
                height: 65px;
                width: 65px;
                border-radius: 50%;
                cursor: pointer;
            }

            .text-container{
                .title{
                    color: $--primary-color;
                    font-size: 14px;
                    font-weight: 600;
                    cursor: pointer;
                }
                p{
                    color: $--Gray-3;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
        .input-grp {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .bold {
                color: $--Gray-1;
                font-size: 14px;
                font-weight: 600;

                span{
                    color: $--Gray-3;
                }
            }
            input,select {
                border-radius: 8px;
                border: 1px solid
                    var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                background: white;
                padding: 12px;
                width: 100%;
                height: 44px;
                font-size: 14px;
                font-weight: 500;
            }
            input:focus, select:focus {
                outline: none;
            }
        }
    }
}