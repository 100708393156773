@import "/src/variables";
@import "/src/index.scss";

.purchase-packages-main {
    padding: 30px;
    padding-top: 100px;
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;

    p {
        margin: 0;
    }

    .content {
        h3 {
            color: #000;
            font-size: 28px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        .main-container {
            background-color: white;
            border-radius: 8px;
            border: 1px solid var(--Basic-400, #e4e9f2);
            .heading {
                display: flex;
                border-radius: 8px 8px 0px 0px;
                border: none;
                border-bottom: 1px solid var(--Basic-400, #e4e9f2);
                text-align: center;
                padding: 0 20px;
                span {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    cursor: pointer;
                    p {
                        padding: 0px 20px;
                        margin-top: 15px;
                        color: $--Gray-1;
                        font-size: 16px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        span {
                            color: #212121;
                            font-size: 11px;
                            font-weight: 500;
                            padding: 3px 3px 2px 3px;
                            border-radius: 4px;
                            background: #eef0f2;
                        }
                    }
                }
                .active {
                    p {
                        span {
                            background-color: #f1f7fd;
                            color: #1c43b9;
                        }
                    }
                    .underline {
                        height: 5px;
                        width: 100%;
                        border-radius: 8px 8px 0px 0px;
                        background: $--primary-color;
                    }
                }
            }
            .my-packages {
                padding: 20px;
                .packages-container {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: 20px;
                    min-height: 350px;
                    position: relative;

                    .empty-message {
                        position: absolute;
                    }

                    .package {
                        border-radius: 10px;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: 216px;
                        }
                        .text-container {
                            display: flex;
                            flex-direction: column;
                            gap: 12px;
                            padding: 8px;
                            .desc {
                                color: #24262e;
                                font-size: 16px;
                                font-weight: 500;
                            }
                            .icons {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 4px;
                                span {
                                    display: flex;
                                    gap: 4px;
                                    align-items: center;
                                }
                            }
                            hr {
                                margin: 0;
                            }
                            .times {
                                .time {
                                    color: $--Gray-2;
                                    font-size: 14px;
                                    font-weight: 400;

                                    span {
                                        font-weight: 600;
                                    }
                                }
                            }
                            .tracker {
                                .track {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                    height: 60px;

                                    .circle-container {
                                        display: flex;
                                        flex-direction: column;
                                        // justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        .my-vr{
                                            display: inline-block;
                                            width: 2px;
                                            height: 100%;
                                            min-height: 10px;
                                            border: 0.71px solid var(--Neutral-100, #B2B5B8);
                                            
                                        }
                                        .my-vr.unvisible{
                                            border-color: transparent;
                                        }
                                        .circle {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border-radius: 50%;
                                            border: 1.42px solid
                                                var(--Neutral-100, #b2b5b8);
                                            min-height: 30px;
                                            width: 30px;

                                            color: var(--Neutral-200, #7d8187);
                                            font-size: 12px;
                                            font-weight: 500;
                                        }
                                    }
                                    .circle-container.completed{
                                        .circle{
                                            background: var(--Green-1, #219653);
                                            color: var(--Shades-white, #FFF);
                                        }
                                        .my-vr{
                                            border-color: var(--Green-1, #219653);;
                                        }
                                        .my-vr.unvisible{
                                            border-color: transparent;
                                        }
                                    }
                                    .details {
                                        .bold {
                                            color: var(--Shades-Black, #131a25);
                                            font-size: 14px;
                                            font-weight: 700;
                                        }
                                        .note{
                                            color: var(--Neutral-200, #7D8187);
                                            font-size: 12px;
                                            font-weight: 500;
                                        }
                                    }
                                    .details.clicable{
                                        cursor: pointer;
                                        .note{
                                            color: rgba(93, 1, 219, 1);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .pagination {
        margin-top: 30px;
        margin-bottom: 30px;
        align-self: center;
        .MuiPaginationItem-previousNext {
            background-color: white;
            filter: drop-shadow(0px 1px 4px rgba(24, 26, 32, 0.07));
        }
        .Mui-selected {
            color: white;
            background-color: $--primary-color;
        }
    }
}
@media (max-width: 1000px) {
    .purchase-packages-main {
        padding: 10px;
        padding-top: 100px;
        .content {
            .main-container {
                .heading {
                    span {
                        p {
                            padding: 0 10px;
                        }
                    }
                }
                .my-packages {
                    padding: 14px;
                    .packages-container{
                        grid-template-columns: 1fr 1fr;
                    }
                }
            }
        }
    }
}
@media (max-width: 800px) {
    .purchase-packages-main {
        .content {
            h3 {
                display: none;
            }
            .main-container {
                border: none;
                .heading {
                    padding-left: 20px;
                    flex-wrap: wrap;
                    span {
                        p {
                            padding: 0 10px;
                            font-size: 12px;

                            span {
                                font-size: 8px;
                            }
                        }
                    }
                }
                .my-packages {
                    .packages-container{
                        grid-template-columns: 1fr;
                        .package{
                            img{
                                height: unset;
                            }
                        }
                    }
                }
            }
        }
        .pagination {
            display: none;
        }
    }
}
@media (max-width: 600px) {
    .purchase-packages-main {
        padding: 0;
        padding-top: 80px;
        background-color: white;
        .content {
            .main-container {
                .heading {
                    padding-left: 20px;
                    flex-wrap: wrap;
                    span {
                        p {
                            padding: 0 10px;
                            font-size: 12px;

                            span {
                                font-size: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 400px) {
    .purchase-packages-main {
        padding: 0;
        padding-top: 60px;
        background-color: white;
        .content {
            h3 {
                display: none;
            }
            .main-container {
                .heading {
                    padding-left: 20px;
                    flex-wrap: wrap;
                    span {
                        p {
                            padding: 0 10px;
                            font-size: 12px;

                            span {
                                font-size: 8px;
                            }
                        }
                    }
                }
            }
        }
        .pagination {
            nav {
                width: 100vw;
            }
            margin: 20px auto;
            .MuiPagination-ul {
                flex-wrap: nowrap;
                transform: scale(0.8);
                margin: auto;
                justify-content: center;
                text-align: center;
            }
            .MuiPaginationItem-previousNext {
                background-color: white;
                filter: drop-shadow(0px 1px 4px rgba(24, 26, 32, 0.07));
            }
            .Mui-selected {
                color: white;
                background-color: $--primary-color;
            }
        }
    }
}
