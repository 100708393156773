@import "/src/variables";
@import "/src/index.scss";

.user-list-view-main {
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: calc(100vh - 140px);
  width: 30%;
  border-radius: 8px 0px 0px 8px;
  border: 1px solid $--Gray-5;

  h4 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: #000;
  }
  .sc-dcJsrY {
    width: 100%;
    *,
    *:focus {
      box-shadow: none !important;
    }
    .wrapper {
      border-radius: 8px 0px 0px 8px;
      border: 1px solid #e4e9f2;
      box-shadow: none;
      z-index: 1;
      .sc-gEvEer {
        background: var(--L1-white, #fff);
        width: 100%;

        .jVORrT {
          display: none;
        }
      }
      .sc-fqkvVR:focus {
        box-shadow: none;
      }
    }
    .wrapper:active {
      box-shadow: none;
    }
    .wrapper:focus {
      box-shadow: none;
    }
    .clear-icon {
      display: none;
    }
  }
  .user-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    overflow-y: scroll;
    padding-right: 4px;
    .empty-list {
        height: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: clamp(12px,4vw,20px);
        font-weight: 500;
    }
    .user-chat {
      padding: 12px;
      border-radius: 8px;
      border: 1px solid $--Gray-5;
      cursor: pointer;
      position: relative;
      .about {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .about-user {
          display: flex;
          align-items: center;
          gap: 8px;

          .image-container {
            position: relative;
            img {
              height: 32px;
              width: 32px;
              border-radius: 50%;
            }
            .online-mark {
              display: inline-block;
              height: 8px;
              width: 8px;
              background: #219653;
              position: absolute;
              bottom: 0;
              right: 0;
              border-radius: 50%;
            }
          }
          span {
            color: #000;
            font-size: 16px;
            font-weight: 500;
          }
        }
        .time {
          color: rgba(60, 60, 67, 0.6);
          font-size: 14px;
          font-weight: 400;
        }
      }
      .last-message {
        color: $--Gray-1;
        font-size: 12px;
        font-weight: 400;
        margin-top: 8px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .unread_message {
        display: inline-block;
        height: 10px;
        width: 10px;
        background: rgba(47, 128, 237, 1);
        position: absolute;
        bottom: 10px;
        right: 10px;
        border-radius: 50%;
      }
    }
    .active-chat {
      border: 1px solid var(--Iris-100, #5d5fef);
      background: rgba(165, 166, 246, 0.15);
    }
  }
  .user-list::-webkit-scrollbar {
    width: 4px;
  }
  .user-list::-webkit-scrollbar-thumb {
    background-color: $--Gray-4;
    border-radius: 20px;
  }
}

@media (max-width: 1000px) {
  .user-list-view-main {
    width: 100%;
    .user-list {
      .active-chat {
        border: 1px solid $--Gray-5;
        background: #fff;
      }
    }
  }
}

@media (max-width: 800px) {
  .user-list-view-main {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 400px) {
  .user-list-view-main {
    height: calc(100vh - 60px);
    padding: 20px;
  }
}
