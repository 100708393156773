@import "/src/variables";
@import "/src/index.scss";

.home-main {
    .banner-container {
        position: relative;
        background: url("../../Assets/Images/bg-desktop.svg");
        background-size: cover;
        background-position: center;
        height: 100vh;
        width: 100vw;
        overflow-x: hidden;

        .banner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: fit-content;
            h1 {
                position: relative;
                color: #fff;
                text-align: center;
                font-family: "Bakbak One", sans-serif;
                font-size: 100px;
                font-weight: 400;
                line-height: 106%; /* 127.2px */
                // text-transform: lowercase;
                text-wrap: nowrap;

                span {
                    font-family: "Bakbak One", sans-serif;
                    background: #1c004266;
                    padding: 0 8px;
                }
            }
            .line-1 {
                animation: animate-line-1 0.3s linear 1s 1 forwards;
                opacity: 0;
            }
            @keyframes animate-line-1 {
                0% {
                    top: 100px;
                    opacity: 0;
                }
                100% {
                    top: 0px;
                    opacity: 1;
                }
            }
            .line-2 {
                background: linear-gradient(
                    90deg,
                    #fff 64.81%,
                    rgba(255, 255, 255, 0.5) 102.39%
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                animation: animate-line-1 0.3s linear 2s 1 forwards;
                opacity: 0;
            }
            @keyframes animate-line-2 {
                0% {
                    top: 100px;
                    opacity: 0;
                }
                100% {
                    top: 0px;
                    opacity: 1;
                }
            }
            .line-2::before {
                position: absolute;
                content: "";
                height: 100px;
                width: 100px;
                background: #d4b440b2;
                z-index: -1;
                border-radius: 50%;
                top: 30px;
                left: 0;
                animation: animate-line-2-before 0.3s linear 3s 1 forwards;
                opacity: 0;
            }
            @keyframes animate-line-2-before {
                0% {
                    top: 130px;
                    left: -70px;
                    opacity: 0;
                }
                100% {
                    top: 30px;
                    left: 0px;
                    opacity: 1;
                }
            }
            p.goal {
                color: #4ba79b;
                font-family: "Inter", sans-serif;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: -0.135px;
                text-transform: uppercase;
                margin-top: 8px;
                animation: animate-goal 0.3s linear 1s 1 forwards;
                opacity: 0;

                span {
                    color: #f4c931;
                }
            }
            @keyframes animate-goal {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }
        .banner::before {
            content: url("../../Assets/Images/home-banner-person.svg");
            position: absolute;
            top: -60px;
            left: -60px;
            animation: banner-before 0.3s linear 3s 1 forwards;
            opacity: 0;
        }
        @keyframes banner-before {
            0% {
                top: -160px;
                left: -130px;
                opacity: 0;
            }
            100% {
                top: -60px;
                left: -60px;
                opacity: 1;
            }
        }
        .banner::after {
            content: url("../../Assets/Images/review-bar.svg");
            position: absolute;
            bottom: -20px;
            right: -150px;
            animation: banner-after 0.3s linear 3s 1 forwards;
            opacity: 0;
        }
        @keyframes banner-after {
            0% {
                bottom: -120px;
                right: -220px;
                opacity: 0;
            }
            100% {
                bottom: -20px;
                right: -150px;
                opacity: 1;
            }
        }
        p.line {
            width: 216px;
            color: #d9d9d9;
            text-align: right;
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            position: absolute;
            right: 30px;
            bottom: 30px;
        }
    }
    .section-1 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        padding: 80px 16px;
        background: #f8f8f8;

        .side-image {
            width: 350px;
        }
        .section-1-content {
            width: 450px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .text {
                p {
                    color: $--Main-Blue-color;
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0;
                }
                h3 {
                    color: $--Dark-blue-2;
                    font-size: 22.517px;
                    font-weight: 700;
                    margin: 0;
                }
            }
            .accordion {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 12px;

                .item {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    padding: 12px 20px;
                    border-radius: 15.012px;
                    background: #fff;
                    box-shadow: 1.501px 8.256px 16.513px 0px
                            rgba(140, 132, 232, 0.1),
                        0px 9.758px 21.016px 0px rgba(140, 132, 232, 0.05);
                    cursor: pointer;

                    .accordian-icon{
                        height: 50px;
                    }

                    .details {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        width: 100%;

                        .title {
                            color: $--Dark-blue-2;
                            font-size: 16px;
                            font-weight: 600;
                            margin: 0;
                        }
                        .data {
                            // display: none;
                            // height: fit-content;
                            max-height: 0px;
                            overflow-y: hidden;
                            color: $--Dark-Gray;
                            font-size: 12px;
                            font-weight: 400;
                            margin: 0;
                            transition: all 0.5s ease;
                        }
                        .data.expanded {
                            // display: block;
                            max-height: 150px;
                        }
                    }
                    .arrow.rotate {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .section-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 50px;
        padding: 60px 24px;
        border: 1px solid var(--Gray-5, #e0e0e0);

        p {
            margin: 0;
        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 4px;
            text-align: center;

            .title {
                color: $--Dark-blue-2;
                font-size: 24px;
                font-weight: 700;
            }
            .details {
                color: #7e7d92;
                font-size: 16px;
                font-weight: 400;
            }
        }
        .tiles {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 24px;

            .tile {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 250px;
                height: 250px;
                padding: 16px;
                border-radius: 20px;

                svg {
                    justify-self: flex-start;
                }
                .tile-text {
                    .bold {
                        color: $--Dark-blue-2;
                        font-size: 18px;
                        font-weight: 600;
                    }
                    p {
                        color: $--Dark-blue-3;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
            .job {
                background: #edf8f8;
            }
            .user {
                background: #fef8f8;
            }
            .safety {
                background: #edecf6;
            }
            .help-circle {
                background: #fcf6e3;
            }
        }
    }
    .section-3 {
        display: flex;
        flex-direction: column;
        gap: 60px;
        padding: 60px 0px 60px 48px;
        border: 1px solid var(--Gray-5, #e0e0e0);
        p {
            margin: 0;
        }

        .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .text {
                .title {
                    color: $--Dark-blue-2;
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 4px;
                }
                .details {
                    color: #7e7d92;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            .arrows {
                display: flex;
                gap: 20px;
                margin-right: 48px;

                svg {
                    cursor: pointer;
                }
                .rotate {
                    transform: rotate(180deg);
                }
            }
        }
        .service-container {
            position: relative;
            .arrow {
                position: absolute;
                z-index: 1;
                background-color: white;
                padding: 6px;
                left: 10px;
                border-radius: 50%;
                top: 50%;
                transform: rotate(180deg) translateY(50%);
                cursor: pointer;
                display: none;
            }
            .rotate {
                left: unset;
                right: 10px;
                transform: rotate(0deg) translateY(-50%);
            }
            .services {
                display: flex;
                gap: 20px;
                width: 100%;
                overflow-x: scroll;
                scroll-behavior: smooth;

                .service {
                    position: relative;
                    min-width: 220px;
                    height: 280px;
                    border-radius: 10px;
                    overflow: hidden;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                    span {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        background: linear-gradient(
                            180deg,
                            rgba(0, 0, 0, 0.81) 2.95%,
                            rgba(53, 53, 53, 0) 56.3%
                        );
                        top: 0;
                        left: 0;
                        padding: 16px;
                        color: $--white;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
            .services::-webkit-scrollbar {
                display: none;
            }

            /* Hide scrollbar for IE, Edge add Firefox */
            .services {
                -ms-overflow-style: none;
                scrollbar-width: none; /* Firefox */
            }
        }
    }
    .section-4 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 60px;
        padding: 50px 16px;
        background: #f7fafc;

        p {
            margin: 0;
        }
        .text {
            text-align: center;
            .title {
                color: $--Dark-blue-2;
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 4px;
            }
            .details {
                color: #7e7d92;
                font-size: 16px;
                font-weight: 400;
            }
        }
        .achivements {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 40px;

            .achivement {
                text-align: center;
                .title {
                    color: #39414d;
                    font-size: 38px;
                    font-weight: 700;
                }
                .text {
                    color: #4d5766;
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
    }
    .section-5 {
        position: relative;
        background: url("../../Assets/Images/home-women-desktop.svg");
        background-size: cover;
        background-repeat: no-repeat;
        width: 100vw;
        aspect-ratio: 1440/501;
        padding-left: 250px;
        overflow-y: hidden;

        display: flex;
        align-items: center;

        .text {
            margin-top: 10%;
            h1 {
                color: $--white;
                font-size: 36px;
                font-weight: 800;
                text-transform: uppercase;
                line-height: 130%;
                margin: 0;
                span {
                    color: #1cffff;
                }
            }
            p {
                color: #fff;
                font-size: 16px;
                font-weight: 400;
                max-width: 336px;
            }
        }
        .right-circles {
            position: absolute;
            border-radius: 50%;
            align-items: center;
            color: $--white;
            text-align: center;
            h3 {
                font-family: "Nunito Sans", sans-serif;
                font-size: 38px;
                font-weight: 800;
                margin: 0;
            }
            .text {
                margin: 0;
                font-family: "Inter", sans-serif;
                font-size: 16px;
                font-weight: 800;
            }
        }
        .satisfied-customer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 150px;
            height: 150px;
            background-color: #756be866;
            opacity: 1;
            backdrop-filter: blur(5px);
            right: 18%;
            bottom: 10%;
        }
        .client-rate {
            padding-top: 30px;
            width: 170px;
            height: 170px;
            background: $--Main-Green-color;
            right: 9.5%;
            bottom: -8%;
        }
    }
    .section-6 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 28px;
        padding: 60px 200px;

        p {
            margin: 0;
        }

        .text {
            text-align: center;
            .title {
                color: $--Dark-blue-2;
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 4px;
            }
            .details {
                color: #7e7d92;
                font-size: 14px;
                font-weight: 400;
                max-width: 650px;
            }
        }
        .user-cards-container {
            width: calc(100vw - 400px);
            display: flex;
            flex-wrap: nowrap;
            overflow-x: hidden;
            scroll-behavior: smooth;
            border-radius: var(--Section-Space-24px, 24px);
            border: 1px solid #5d01db;

            .user-card {
                padding: 14px 60px;
                width: 100%;
                flex-shrink: 0;
                .user {
                    display: flex;
                    gap: 20px;

                    .image-container {
                        .inverted-coma {
                            margin-bottom: 20px;
                            height: 30px;
                            align-self: flex-start;
                        }

                        display: flex;
                        flex-direction: column;
                        .user-image {
                            position: relative;
                            height: 225px;
                            width: 200px;

                            img {
                                height: 200px;
                                width: 200px;
                                border-radius: 50%;
                            }
                        }
                    }
                    .user-image::before {
                        content: url("../../Assets/icons/doted-texture.svg");
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    .user-image::after {
                        content: url("../../Assets/icons/background-curved-line.svg");
                        position: absolute;
                        left: 75px;
                        bottom: 0;
                    }
                    .review-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        gap: 20px;
                        width: 100%;

                        .review-details {
                            display: flex;
                            flex-direction: column;
                            gap: 30px;

                            .review {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;
                                .head {
                                    display: flex;
                                    justify-content: space-between;
                                    .title {
                                        color: #5c52d5;
                                        font-family: "Inter", sans-serif;
                                        font-size: 20px;
                                        font-weight: 500;
                                    }
                                    .ratings {
                                        display: flex;
                                        align-items: center;

                                        svg {
                                            height: 10px;
                                            width: 10px;
                                        }
                                        .filled {
                                            path {
                                                fill: #f3c831;
                                            }
                                        }
                                        p {
                                            color: #4f6273;
                                            font-family: "Inter", sans-serif;
                                            font-size: 10px;
                                            font-weight: 500;
                                            margin-left: 4px;
                                        }
                                    }
                                }
                                .review-desc {
                                    color: $--Gray-1;
                                    font-size: 12px;
                                    font-weight: 400;
                                }
                            }
                            .user-info {
                                .user-name {
                                    color: $--Gray-1;
                                    font-family: "Inter", sans-serif;
                                    font-size: 16px;
                                    font-weight: 600;
                                }
                                .user-role {
                                    color: $--Gray-2;
                                    font-size: 12px;
                                    font-weight: 400;
                                }
                            }
                        }
                        .navigation {
                            display: flex;
                            justify-content: space-between;
                            .arrows {
                                display: flex;
                                gap: 12px;

                                svg {
                                    cursor: pointer;
                                }
                                .rotate {
                                    transform: rotate(180deg);
                                }
                            }
                            .next-users {
                                position: relative;
                                display: flex;
                                justify-content: flex-end;
                                height: 50px;
                                img {
                                    position: absolute;
                                    width: 50px;
                                    border-radius: 50%;
                                    border: 2.252px solid #fff;
                                    box-shadow: -6.005px 3.002px 15.762px 0px
                                        rgba(0, 0, 0, 0.1);
                                }
                                .number {
                                    position: absolute;
                                    color: #fff;
                                    font-size: 14px;
                                    font-weight: 600;
                                    right: 18px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                    }
                }
            }
        }
        .user-cards-container::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge add Firefox */
        .user-cards-container {
            -ms-overflow-style: none;
            scrollbar-width: none; /* Firefox */
        }
    }
}

@media (max-width: 1350px) {
    .home-main {
        .banner-container {
            .banner {
                h1 {
                    font-size: 66px;
                }
                .line-2::before {
                    height: 60px;
                    width: 60px;
                    top: 24px;
                    left: 10px;
                }
                @keyframes animate-line-2-before {
                    0% {
                        top: 124px;
                        left: -60px;
                        opacity: 0;
                    }
                    100% {
                        top: 24px;
                        left: 10px;
                        opacity: 1;
                    }
                }
            }
        }
        .section-5 {
            .client-rate {
                right: 8%;
            }
        }
    }
}
@media (max-width: 1250px) {
    .home-main {
        .section-5 {
            padding-left: 200px;
            .text {
                h1 {
                    font-size: 32px;
                }
                p {
                    font-size: 14px;
                }
                button {
                    font-size: 12px;
                    height: 36px;
                }
            }
            .right-circles {
                h3 {
                    font-size: 32px;
                }
                .text {
                    font-size: 12px;
                }
            }
            .satisfied-customer {
                height: 110px;
                width: 110px;
            }
            .client-rate {
                right: 9.5%;
                padding-top: 25px;
                height: 135px;
                width: 135px;
            }
        }
        .section-6 {
            padding: 60px 100px;
            .user-cards-container{
                width: calc(100vw - 200px);
            }
        }
    }
}
@media (max-width: 1100px) {
    .home-main {
        .section-5 {
            .client-rate {
                right: 8.5%;
                padding-top: 25px;
                height: 135px;
                width: 135px;
            }
        }
    }
}
@media (max-width: 1000px) {
    .home-main {
        .banner-container {
            .banner {
                h1 {
                    font-size: 50px;
                }
                .line-2::before {
                    height: 50px;
                    width: 50px;
                    top: 16px;
                    left: 6px;
                }
                @keyframes animate-line-2-before {
                    0% {
                        top: 116px;
                        left: -64px;
                        opacity: 0;
                    }
                    100% {
                        top: 16px;
                        left: 6px;
                        opacity: 1;
                    }
                }
            }
            .banner::after,
            .banner::before {
                transform: scale(0.8);
            }
        }
        .section-5 {
            padding-left: 150px;
            .text {
                h1 {
                    font-size: 28px;
                }
                p {
                    font-size: 12px;
                    max-width: 300px;
                    margin-bottom: 6px;
                }
                button {
                    font-size: 12px;
                    height: 36px;
                }
            }
            .right-circles {
                h3 {
                    font-size: 28px;
                }
                .text {
                    font-size: 12px;
                }
            }
            .satisfied-customer {
                height: 100px;
                width: 100px;
            }
            .client-rate {
                right: 10%;
                padding-top: 20px;
                height: 110px;
                width: 110px;
            }
        }
        .section-6 {
            padding: 60px 20px;
            .user-cards-container{
                width: calc(100vw - 40px);
            }
        }
    }
}
@media (max-width: 900px) {
    .home-main {
        .section-5 {
            .client-rate {
                right: 8.5%;
            }
        }
    }
}
@media (max-width: 800px) {
    .home-main {
        .banner-container {
            .banner {
                h1 {
                    font-size: 36px;
                }
                .line-2::before {
                    height: 32px;
                    width: 32px;
                    top: 12px;
                    left: 8px;
                }
                @keyframes animate-line-2-before {
                    0% {
                        top: 112px;
                        left: -66px;
                        opacity: 0;
                    }
                    100% {
                        top: 12px;
                        left: 8px;
                        opacity: 1;
                    }
                }
                p.goal {
                    font-size: 12px;
                    margin-top: 4px;
                }
            }
            .banner::after,
            .banner::before {
                transform: scale(0.6);
            }
        }
        .section-1 {
            .side-image {
                width: min(100%, 400px);
            }
            .section-1-content {
                width: min(100%, 400px);
            }
        }
        .section-5 {
            padding-left: 100px;
            .text {
                h1 {
                    font-size: 18px;
                }
                p {
                    font-size: 10px;
                    max-width: 250px;
                    margin-bottom: 6px;
                }
                button {
                    font-size: 10px;
                    height: 30px;
                    padding: 0 12px;
                }
            }
            .right-circles {
                h3 {
                    font-size: 24px;
                }
                .text {
                    font-size: 10px;
                }
            }
            .satisfied-customer {
                height: 90px;
                width: 90px;
            }
            .client-rate {
                right: 8.5%;
                padding-top: 20px;
                height: 100px;
                width: 100px;
            }
        }
        .section-6 {
            .user-cards-container {
                .user-card {
                    padding: 14px 20px;
                }
            }
        }
    }
}
@media (max-width: 700px) {
    .home-main {
        .section-5 {
            .client-rate {
                right: 6.1%;
            }
        }
    }
}
@media (max-width: 600px) {
    .home-main {
        .banner-container {
            background: url("../../Assets/Images/bg-mobile.svg");
            background-position: center;
            background-size: cover;

            .banner {
                h1{
                    font-size: 24px;
                }
                .line-2::before {
                    height: 32px;
                    width: 32px;
                    top: 12px;
                    left: 8px;
                }
                @keyframes animate-line-2-before {
                    0% {
                        top: 112px;
                        left: -66px;
                        opacity: 0;
                    }
                    100% {
                        top: 12px;
                        left: 8px;
                        opacity: 1;
                    }
                }
                p.goal {
                    font-size: 12px;
                    margin-top: 40px;
                }
            }
            .banner::after,
            .banner::before {
                transform: scale(0.5);
            }
            @keyframes banner-after {
                0% {
                    bottom: -160px;
                    right: -150px;
                    opacity: 0;
                }
                100% {
                    bottom: -60px;
                    right: -80px;
                    opacity: 1;
                }
            }
            @keyframes banner-before {
                0% {
                    top: -170px;
                    left: -115px;
                    opacity: 0;
                }
                100% {
                    top: -70px;
                    left: -45px;
                    opacity: 1;
                }
            }
            p.line {
                font-size: 12px;
                right: 20px;
                bottom: 20px;
            }
        }
        .section-1 {
            flex-direction: column;
            padding: 24px 16px;
            .side-image {
                max-width: 100%;
            }
            .section-1-content {
                max-width: 100%;
            }
        }
        .section-2 {
            padding: 48px 24px;
            .tiles {
                .tile {
                    width: 150px;
                    height: 170px;

                    .tile-text {
                        .bold {
                            font-size: 12px;
                        }
                        p {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
        .section-3 {
            padding: 24px 0px 24px 16px;
            gap: 10px;
            .heading {
                .arrows {
                    display: none;
                }
            }
            .service-container {
                .arrow {
                    display: block;
                }
                .services {
                    gap: 12px;
                    .service {
                        min-width: 160px;
                        height: 160px;

                        span {
                            padding: 10px;
                        }
                    }
                }
            }
        }
        .section-4 {
            padding: 24px 16px;
        }
        .section-5 {
            background: url("../../Assets/Images/home-women-mobile.svg");
            background-size: cover;
            background-repeat: no-repeat;
            aspect-ratio: 360/498;
            max-height: 100vh;
            padding-left: 0px;
            align-items: flex-start;

            .text {
                padding: 24px;
                margin-top: 0%;
                h1 {
                    font-size: 36px;
                }
                p {
                    font-size: 16px;
                    max-width: 100vw;
                }
                button {
                    font-size: 14px;
                    height: 36px;
                }
            }
            .right-circles {
                h3 {
                    font-size: 20px;
                }
                .text {
                    font-size: 10px;
                    padding: 0;
                }
            }
            .satisfied-customer {
                width: 90px;
                height: 90px;
                right: 47%;
                bottom: 0%;
            }
            .client-rate {
                padding-top: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 110px;
                height: 110px;
                right: 77%;
                bottom: 15%;
            }
        }
        .section-6 {
            padding: 30px 20px;
            gap: 0px;
            .text {
                text-align: left;
            }
            .user-cards-container {
                border: none;
                .user-card {
                    display: flex;
                    flex-direction: column;
                    padding: 0;
                    .user {
                        align-items: center;
                        flex-direction: column;
                        gap: 0;
                        .image-container {
                            transform: scale(0.9);
                        }
                        .review-container {
                            .review-details {
                                flex-direction: column-reverse;
                                .user-info {
                                    text-align: center;
                                }
                            }
                            .navigation {
                                flex-direction: column-reverse;
                                align-items: center;
                                gap: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 400px) {
    .home-main {
        .banner-container {
            .banner {
                h1 {
                    font-size: 20px;
                }
                .line-2::before {
                    height: 24px;
                    width: 24px;
                    top: 8px;
                    left: 8px;
                }
                @keyframes animate-line-2-before {
                    0% {
                        top: 108px;
                        left: -66px;
                        opacity: 0;
                    }
                    100% {
                        top: 8px;
                        left: 8px;
                        opacity: 1;
                    }
                }
            }
            .banner::after,
            .banner::before {
                transform: scale(0.5);
            }
        }
        .section-5 {
            .text {
                padding: 16px;
                h1 {
                    font-size: 28px;
                }
                p {
                    font-size: 12px;
                }
                button {
                    font-size: 12px;
                    height: 32px;
                }
            }
            .client-rate {
                right: 70%;
            }
        }
    }
}
