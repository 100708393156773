@import "/src/variables";
@import "/src/index.scss";

.forgot-password-main {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 14px;
    width: min(500px, 95vw);

    p {
        margin: 0;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            color: #000;
            font-size: 18px;
            font-weight: 600;
        }
        svg {
            height: 24px;
            width: 24px;
            path {
                fill: #1c1b1f;
            }
        }
    }
    .input-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .input-grp {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .bold {
                color: $--Gray-1;
                font-size: 14px;
                font-weight: 600;
            }
            input,
            select {
                border-radius: 8px;
                border: 1px solid
                    var(--neutral-opacity-20, rgba(19, 26, 37, 0.2));
                background: white;
                padding: 12px;
                width: 100%;
                height: 44px;
                font-size: 14px;
                font-weight: 500;
            }
            input:focus {
                outline: none;
            }
            .inputs {
                display: flex;
                gap: 8px;
            }
        }
        .btn-blue {
            border-radius: 6px;
            // width: fit-content;
            // align-self: flex-end;
            background: $--primary-color;
            padding: 8px 20px;
            outline: none;
            border: none;
            cursor: pointer;
            color: white;
        }
    }
    hr{
        margin: 0;
    }
}
