@import "/src/variables";
@import "/src/index.scss";

.freelancer-profile-main {
    margin-top: 90px;
    padding: 60px;
    background: #f8f8f8;

    p {
        margin: 0;
    }

    .edit-icon {
        cursor: pointer;
    }

}

@media (max-width: 800px) {
    .freelancer-profile-main {
        padding: 30px;
        .profile-container {
            padding: 20px;
            .profile-image-controler {
                gap: 8px;
                .image-name-container {
                    .image-container {
                        img {
                            height: 60px;
                            width: 60px;
                        }
                        .edit-icon {
                            height: 22px;
                            width: 22px;
                        }
                    }
                    p {
                        font-size: 24px;
                    }
                    .online-tag {
                        font-size: 12px;
                    }
                }
                .btn-blue {
                    align-self: flex-end;
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .freelancer-profile-main {
        .profile-container {
            .profile-image-controler {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}

@media (max-width: 600px) {
    .freelancer-profile-main {
        margin-top: 80px;
        padding: 0px;
        .profile-container {
            padding: 10px;
            .profile-image-controler {
                flex-direction: column;
                align-items: flex-start;
                .image-name-container {
                    .image-container {
                        margin-right: 8px;
                        img {
                            height: 50px;
                            width: 50px;
                        }
                        .edit-icon {
                            height: 18px;
                            width: 18px;
                        }
                    }
                    p {
                        font-size: 20px;
                    }
                }
                .buttons{
                    width: 100%;
                    flex-direction: column-reverse;
                    button{
                        width: 100%;
                        border-radius: 8px;
                    }
                }
            }
        }
        .info-container {
            .info-title {
                font-size: 16px;
            }
            svg {
                height: 22px;
                width: 22px;
            }
            .experience-container,
            .education-container {
                .experience,
                .education {
                    .details {
                        .heading {
                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .details {
                p {
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .freelancer-profile-main {
        margin-top: 62px;
        padding: 0;
        .profile-container {
            .profile-image-controler {
                .image-name-container {
                    .image-container {
                        img {
                            height: 50px;
                            width: 50px;
                        }
                        .edit-icon {
                            height: 18px;
                            width: 18px;
                        }
                    }
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
